import React from "react"
import useStyles from "./styles"
import { useHistory } from "react-router-dom"
import { Box, IconButton, Grid } from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

const SimpleHeader = ({ backUrl, title }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleBack = () => {
    history.push(backUrl)
  }

  return (
    <Grid container justify="center">
      <Grid item xs={4}>
        <IconButton
          className={classes.backButton}
          aria-label="back"
          onClick={handleBack}
        >
          <ArrowBackIcon className={classes.backIcon} />
        </IconButton>
      </Grid>
      <Grid item xs={4}>
        <Box
          className={classes.title}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {title}
        </Box>
      </Grid>
      <Grid item xs={4} />
    </Grid>
  )
}

export default SimpleHeader
