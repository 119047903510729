import React from "react"
import useStyles from "./styles"
import { Button } from "@material-ui/core"
import { useFormikContext } from "formik"
import { useDispatch } from "react-redux"
import { closeQuickEdit } from "actions/quick-edit"
import { saveFormData } from "actions/forms"
import SimpleForm2 from "components/QuickEdit/FormsSection/SimpleForm2"
import SimpleForm3 from "components/QuickEdit/FormsSection/SimpleForm3"
import SimpleForm4 from "components/QuickEdit/FormsSection/SimpleForm4"
import SimpleForm5 from "components/QuickEdit/FormsSection/SimpleForm5"
import SimpleForm6 from "components/QuickEdit/FormsSection/SimpleForm6"
import getFragrances from "utils/getFragrances"

const FormsSection = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.formsSection}>
      <div className={classes.formContainer}>
        <div className={classes.slider}>
          <SimpleForm2 />
        </div>
        <div className={classes.slider2}>
          <SimpleForm3 />
        </div>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.slider}>
          <SimpleForm5 />
        </div>
        <div className={classes.slider2}>
          <SimpleForm4 />
        </div>
      </div>
      <div className={classes.formContainer2}>
        <div className={classes.scentSelector}>
          <SimpleForm6 />
        </div>
      </div>
    </div>
  )
}

export default FormsSection
