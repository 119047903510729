import React from "react"
import useStyles from "./styles"
import classnames from "clsx"
import { Typography, Button } from "@material-ui/core"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { saveFormData } from "actions/forms"
import getFragrances from "utils/getFragrances"

const Form6 = ({ children, handleNewActiveForm }) => {
  const classes = useStyles()
  const history = useHistory()
  const initialValues = useSelector((state) => state.forms["form6"])

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      dispatch(saveFormData("completedSix", true))
      dispatch(saveFormData("form6", values))
      getFragrances()
      window.scrollTo(0, 0)
      history.push("/discover/your-fragrance")
    },
  })

  const onPreviousClicked = () => {
    dispatch(saveFormData("completedSix", true))
    dispatch(saveFormData("form6", formik.values))
    window.scrollTo(0, 0)
    handleNewActiveForm(5)
  }

  return (
    <div className={classes.formContainer}>
      <Typography variant="h1" className={classes.discoverHeading}>
        LAST QUESTION, WHAT SCENTS INTEREST YOU?
      </Typography>
      <Typography variant="body1" className={classes.discoverDescription}>
        select all that apply
      </Typography>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <div className={classes.checkBtnContainer}>
          <div className={classes.checkBtns}>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.spicy === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("spicy", !formik.values.spicy)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Spicy</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.fresh === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("fresh", !formik.values.fresh)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Fresh</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.cozy === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("cozy", !formik.values.cozy)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Cozy</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.earthy === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("earthy", !formik.values.earthy)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Earthy</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.relaxing === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("relaxing", !formik.values.relaxing)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Relaxing</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.vibrant === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("vibrant", !formik.values.vibrant)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Vibrant</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.juicy === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("juicy", !formik.values.juicy)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Juicy</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.smoky === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("smoky", !formik.values.smoky)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Smoky</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.herbal === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("herbal", !formik.values.herbal)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Herbal</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.floral === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("floral", !formik.values.floral)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Floral</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.citrusy === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("citrusy", !formik.values.citrusy)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Citrusy</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.yummy === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("yummy", !formik.values.yummy)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Yummy</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.festive === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("festive", !formik.values.festive)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Festive</Typography>
            </Button>
          </div>
        </div>
        <div className={classes.btnContainer}>
          <Button
            variant="outlined"
            onClick={onPreviousClicked}
            className={classes.formButton}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            type="submit"
            className={classes.formButton}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Form6
