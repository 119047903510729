import React from "react"
import useStyles from "./styles"
import { Typography, Box } from "@material-ui/core"
import padNumber from "utils/padNumber"
import classnames from "clsx"

const FragranceBubble = ({ number, onClick, selected, className = {} }) => {
  const classes = useStyles()

  return (
    <Box
      className={classnames(
        classes.bubble,
        { [classes.selected]: selected },
        className,
      )}
      onClick={onClick}
    >
      <Typography variant="h3" className={classes.candleNumberText}>
        N<sup>o</sup>
      </Typography>
      <Typography variant="h3" className={classes.candleNumber}>
        {padNumber(number)}
      </Typography>
    </Box>
  )
}

export default FragranceBubble
