import React from "react"
import useStyles from "./styles"
import { Box, Typography } from "@material-ui/core"

const Note = ({ note }) => {
  const classes = useStyles()

  return (
    <Box className={classes.note}>
      <div className={classes.noteDescription}>
        <img src={note.image} />
        <Typography className={classes.paragraph}>{note.name}</Typography>
      </div>
    </Box>
  )
}

export default Note
