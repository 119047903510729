import React from "react"
import { useDispatch, useSelector } from "react-redux"
import useStyles from "./styles"
import { Dialog, DialogActions, IconButton } from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import Category from "components/Category"
import { closeDialog } from "actions/dialogs"

const FamilyModal = () => {
  const classes = useStyles()

  const open = useSelector((state) => state.dialogs.familyModal?.open || false)
  const family = useSelector((state) => state.candles.selectedFamily)

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(closeDialog("familyModal"))
  }

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      classes={{
        paper: classes.saveProfile,
      }}
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Category category={family} modal />
      <DialogActions>
        <IconButton onClick={handleClose} className={classes.close}>
          <ClearIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  )
}

export default FamilyModal
