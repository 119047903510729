import { LINKS_SET_DATA } from "types/actions/links"

export const linksInitialState = {
  links: [],
}

export default (state = linksInitialState, action) => {
  switch (action.type) {
    case LINKS_SET_DATA:
      return {
        ...state,
        [action.dataName]: action.data,
      }
    default:
      return state
  }
}

/*
const newState = Object.assign({}, state)
      newState[action.dataName] = action.data
      console.log({ state, newState, action })
      return newState
      */
