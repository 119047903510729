import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  kiosk: {
    maxheight: "100vh",
    paddingTop: "190px",
  },
  pinFormContainer: {
    paddingTop: "100px",
  },
  logo: {
    position: "absolute",
    bottom: 20,
  },
  logo2: {
    height: "120px",
    width: "120px",
  },
}))

export default styles
