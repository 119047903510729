import React, { useMemo, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import useStyles from "./styles"
import { Box } from "@material-ui/core"
import { saveCandleData } from "actions/candles"
import { chunk } from "lodash"
import Row from "./Row"
import FamilyRow from "./FamilyRow"
import { userCandles } from "actions/users_candles"
import { connect } from "react-redux"
import { getFragranceCollection } from "actions/candles"

const items = [...Array(100).keys()]

const itemsDesktop = chunk(items, 10)

const itemsMobile = chunk(items, 5)

const myItems = []

const LibraryCard = ({ children, currentFamily, userId }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.authentication.authenticatedUser.id)
  useEffect(() => {
    dispatch(saveCandleData("selectedCandle", -1))
    if (user != null) {
      dispatch(userCandles(user))
    }
  }, [])

  const classes = useStyles()
  const candles = useSelector((state) => state.candles["candles"])
  const familyCandles = useMemo(
    () => candles.filter((x) => x.family.name === currentFamily),
    [currentFamily],
  )
  const selectedItem = useSelector((state) => state.candles["selectedCandle"])

  const loadFragranceVariants = async () => {
    let shopifyId = -1
    let selectedCandle = -1
    if (selectedItem > -1) {
      selectedCandle = candles.filter((x) => {
        return x.id === selectedItem + 1
      })
      if (typeof selectedCandle[0] !== "undefined") {
        shopifyId = selectedCandle[0].collection_id
      }
      const response = await dispatch(getFragranceCollection(shopifyId))
      return response
    } else return -1
  }

  useEffect(() => {
    loadFragranceVariants().then((res) => {
      if (res !== -1 && res) {
        let image = new Image()
        image.src = res.image.src
      }
    })
  }, [selectedItem])

  const setSelectedItem = (number) => {
    dispatch(saveCandleData("selectedCandle", number))
  }

  const getItemInteractions = (row) => {
    return myItems.filter((item) => row.includes(item.number - 1))
  }
  const showDesktop = window.innerWidth > 735
  const rowProps = {
    children,
    selectedItem,
    setSelectedItem,
  }

  return (
    <Box className={classes.libraryCardContainer}>
      {currentFamily ? (
        <FamilyRow
          items={familyCandles.map((x) => x.id - 1)}
          interactedItems={getItemInteractions(
            familyCandles.map((x) => x.id - 1),
          )}
          id={"index0"}
          currentFamily={currentFamily}
          {...rowProps}
        />
      ) : (
        <>
          {showDesktop ? (
            <div className={classes.itemsDesktop}>
              {itemsDesktop.map((row, index) => (
                <Row
                  key={index}
                  items={row}
                  interactedItems={getItemInteractions(row)}
                  id={"index" + index}
                  {...rowProps}
                />
              ))}
            </div>
          ) : (
            <div className={classes.itemsMobile}>
              {itemsMobile.map((row, index) => (
                <Row
                  key={index}
                  items={row}
                  interactedItems={getItemInteractions(row)}
                  id={"index" + index}
                  {...rowProps}
                />
              ))}
            </div>
          )}
        </>
      )}
    </Box>
  )
}
export default LibraryCard
