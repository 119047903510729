import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  dialogPaper: {
    maxWidth: [770, "!important"],
  },
  candlefishLogo: {
    width: 124,
    marginBottom: 52,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 60,
    paddingTop: 54,
    "@media (max-width: 768px)": {
      padding: theme.spacing(3),
    },
  },
  shopItems: {
    justifyContent: "center",
    width: "100%",
    flexWrap: "wrap",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "initial",
    },
  },
}))

export default styles
