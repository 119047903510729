import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"
import useStyles from "./styles"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Slide from "@material-ui/core/Slide"
import Modal from "@material-ui/core/Modal"
import { useDispatch } from "react-redux"
import { setKioskData } from "actions/admins"
import ManageUsersDialog from "components/ManageUsersDialog"
import PinForm from "../PinForm"
import { logout } from "actions/authentication"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AdminFooter = ({ manageUserProp, isAdmin }) => {
  const FooterLogo = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/cf-logo-footer.svg`

  const classes = useStyles()

  const dispatch = useDispatch()

  const history = useHistory()

  useEffect(() => {
    // setTimeout(() => setEmployees(employees => fakeData.employees), 3000)
    // setTimeout(() => setEmployees(employees => fakeData.employees), 3000)
  }, [manageUserProp])
  //  *** DIALOG STATE & HANDLERS ***
  const [openDialog, setOpenDialog] = React.useState(false)

  const handleOpenDialog = () => {
    setOpenDialog((openDialog) => !openDialog)
  }

  const handleCloseDialog = () => {
    setOpenDialog((openDialog) => !openDialog)
  }
  //  *** DIALOG STATE & HANDLERS ***

  //  *** PIN MODAL STATE & HANDLERS ***
  const [openModal, setOpenModal] = React.useState(false)

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const renderButtons =
    manageUserProp && manageUserProp.admin && manageUserProp.admin.superAdmin

  // *** PIN MODAL STATE & HANDLERS ***
  return (
    <Grid className={classes.footer} container justify="center">
      {renderButtons ? (
        <Grid
          item
          xs={4}
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Button
            className={classes.button}
            color="secondary"
            onClick={(event) => {
              handleOpenDialog()
            }}
          >
            MANAGE USERS
          </Button>
          <Button
            className={classes.button}
            color="secondary"
            onClick={() => {
              history.push("/kiosk/analytics")
            }}
          >
            ANALYTICS
          </Button>
        </Grid>
      ) : null}
      <Grid item xs={4} className={classes.logoContainer}>
        <img src={FooterLogo} alt="footer logo" />
      </Grid>
      <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
        {manageUserProp && manageUserProp.admin && manageUserProp.admin ? (
          <Button
            className={classes.button}
            color="secondary"
            onClick={() => {
              localStorage.removeItem("employee")
              localStorage.removeItem("store")
              localStorage.removeItem("employeePin")
              dispatch(setKioskData(false))
              dispatch(logout())
              history.push("/")
            }}
          >
            LOGOUT
          </Button>
        ) : (
          <Button
            className={classes.button}
            color="secondary"
            onClick={() => {
              handleOpenModal()
            }}
          >
            LOGIN
          </Button>
        )}
      </Grid>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <ManageUsersDialog handleCloseDialog={handleCloseDialog} />
      </Dialog>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Grid className={classes.paper} container direction="column">
          <PinForm page="/kiosk/queue" endpoint="employee" />
        </Grid>
      </Modal>
    </Grid>
  )
}
const mapStateToProps = (state) => {
  return {
    isAdmin: state.authentication.authenticatedAdmin,
  }
}
export default connect(mapStateToProps)(AdminFooter)
