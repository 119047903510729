import { FORMS_SET } from "types/actions/forms"

const formsInitialState = {
  form1: {
    phone: "",
  },
  form2: {
    uniqueness: 0,
    completed: false,
  },
  form3: {
    strength: 0,
    completed: false,
  },
  form4: {
    office: false,
    bathroom: false,
    living_room: false,
    bedroom: false,
    porch: false,
    kitchen: false,
    not_sure: true,
    completed: false,
  },
  form5: {
    simple: 0,
    dry: 0,
    feminine: 0,
    completed: false,
  },
  form6: {
    spicy: false,
    fresh: false,
    cozy: false,
    earthy: false,
    relaxing: false,
    vibrant: false,
    juicy: false,
    smoky: false,
    herbal: false,
    floral: false,
    citrusy: false,
    yummy: false,
    festive: false,
    completed: false,
  },
}

export default (state = formsInitialState, action) => {
  switch (action.type) {
    case FORMS_SET:
      return {
        ...state,
        [action.formName]: action.data,
      }
    default:
      return state
  }
}
