import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  logo: {
    maxWidth: 254,
    margin: "0 auto",
    display: "block",
    verticalAlign: "bottom",
  },
}))

export default styles
