import React, { useEffect, useState, useRef } from "react"
import useStyles from "./styles"
import { useDispatch, useSelector } from "react-redux"
import { openSideNav } from "actions/side-nav"
import { Box, IconButton, Typography } from "@material-ui/core"
import classnames from "clsx"
import Menu from "@material-ui/icons/Menu"
import { useHistory } from "react-router-dom"
import { Badge } from "@material-ui/core"
import { updateBannerHeight } from "actions/settings"
import SearchIcon from "@material-ui/icons/Search"
import API from "store/api"

const Header = ({ children, hideSpacing, white }) => {
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const banner = useSelector((state) => state.settings.banner)
  const bannerRef = useRef()
  useEffect(() => {
    const bannerHeight = bannerRef?.current?.offsetHeight
    if (bannerHeight) {
      dispatch(updateBannerHeight(bannerHeight))
    }
  }, [banner.showBanner])

  let spacing = <div className={classes.spacer} style={{ minHeight: 108 }} />

  const cartCount = useSelector((state) => state.candles["cartCount"])
  if (hideSpacing === true) {
    spacing = null
  }

  const handleOpenSideNav = () => {
    dispatch(openSideNav())
  }

  const handleShopClick = () => {
    window.open((window.location.href = "/cart"), "_blank")
  }

  const handleSearchClick = () => {
    window.open("https://www.candlefish.com/search", "_blank")
  }

  let stroke = "#fd6a4f"
  let badgeColor = "secondary"

  if (white === true) {
    stroke = "white"
    badgeColor = "primary"
  }

  let cartIcon =
    white === true ? (
      <svg
        className={classes.cartIcon}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="23"
        viewBox="0 0 24 23"
      >
        <g
          id="Icon_feather-shopping-cart"
          data-name="Icon feather-shopping-cart"
          transform="translate(-0.5 -0.5)"
        >
          <path
            id="Path_752"
            data-name="Path 752"
            d="M14.122,31.061A1.061,1.061,0,1,1,13.061,30,1.061,1.061,0,0,1,14.122,31.061Z"
            transform="translate(-3.543 -9.622)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_753"
            data-name="Path 753"
            d="M30.622,31.061A1.061,1.061,0,1,1,29.561,30,1.061,1.061,0,0,1,30.622,31.061Z"
            transform="translate(-9.245 -9.622)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_754"
            data-name="Path 754"
            d="M1.5,1.5h4L8.18,12.858a1.953,1.953,0,0,0,2,1.366H19.9a1.953,1.953,0,0,0,2-1.366l1.6-7.117H6.5"
            transform="translate(0 0)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    ) : (
      <svg
        className={classes.cartIcon}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="23"
        viewBox="0 0 24 23"
      >
        <g
          id="Icon_feather-shopping-cart"
          data-name="Icon feather-shopping-cart"
          transform="translate(-0.5 -0.5)"
        >
          <path
            id="Path_752"
            data-name="Path 752"
            d="M14.122,31.061A1.061,1.061,0,1,1,13.061,30,1.061,1.061,0,0,1,14.122,31.061Z"
            transform="translate(-3.543 -9.622)"
            fill="none"
            stroke="#fd6a4f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_753"
            data-name="Path 753"
            d="M30.622,31.061A1.061,1.061,0,1,1,29.561,30,1.061,1.061,0,0,1,30.622,31.061Z"
            transform="translate(-9.245 -9.622)"
            fill="none"
            stroke="#fd6a4f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_754"
            data-name="Path 754"
            d="M1.5,1.5h4L8.18,12.858a1.953,1.953,0,0,0,2,1.366H19.9a1.953,1.953,0,0,0,2-1.366l1.6-7.117H6.5"
            transform="translate(0 0)"
            fill="none"
            stroke="#fd6a4f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    )

  return (
    <>
      <header className={classes.header}>
        {banner?.showBanner ? (
          <Box ref={bannerRef} id="banner" className={classes.banner}>
            <Box className={classes.bannerContent}>
              <span
                dangerouslySetInnerHTML={{ __html: banner.bannerContent }}
              />
            </Box>
          </Box>
        ) : null}
        <Box
          className={classes.headerContent}
          style={banner?.showBanner ? { paddingTop: 0 } : {}}
        >
          <div className={classes.leftHeader}>
            <IconButton
              className={classes.button}
              onClick={() => handleOpenSideNav()}
            >
              <svg
                className={classes.menuIcon}
                xmlns="http://www.w3.org/2000/svg"
                width="31.895"
                height="18"
                viewBox="0 0 31.895 18"
              >
                <g
                  id="Group_13"
                  data-name="Group 13"
                  transform="translate(1 1)"
                >
                  <line
                    id="Line_7"
                    data-name="Line 7"
                    x2="29.895"
                    fill="none"
                    stroke={stroke}
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Line_8"
                    data-name="Line 8"
                    x2="24"
                    transform="translate(0 8)"
                    fill="none"
                    stroke={stroke}
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <line
                    id="Line_9"
                    data-name="Line 9"
                    x2="29.895"
                    transform="translate(0 16)"
                    fill="none"
                    stroke={stroke}
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                </g>
              </svg>
              <Menu style={{ opacity: 0 }} />
            </IconButton>
            <Typography
              variant="h3"
              className={classnames(classes.candle, {
                [classes.altCandle]: white,
              })}
              onClick={() => history.push("/")}
            >
              CANDLEFISH
            </Typography>
          </div>
          <div className={classes.shop}>
            <IconButton
              style={{ marginRight: "8px", padding: "6px" }}
              onClick={handleSearchClick}
            >
              <SearchIcon
                style={{
                  width: "30px",
                  height: "30px",
                  color: "#fff",
                  transform: "rotateY(180deg)",
                }}
              />
            </IconButton>
            <Badge
              badgeContent={cartCount}
              color={badgeColor}
              classes={{
                colorPrimary: classes.color,
                colorSecondary: classes.color,
              }}
              onClick={() => handleShopClick()}
            >
              {cartIcon}
            </Badge>
          </div>
        </Box>
      </header>
      {spacing}
    </>
  )
}

export default Header
