import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  container: {
    display: "flex",
    paddingLeft: theme.spacing(8),
    "@media (max-width: 1024px)": {
      paddingLeft: 0,
      flexDirection: "column",
    },
  },
  leftContent: {
    maxWidth: 299,
    flexShrink: 0,
    "@media (max-width: 1024px)": {
      maxWidth: 410,
      paddingLeft: 35,
      paddingRight: 35,
      marginBottom: theme.spacing(4),
    },
  },
  featuredHeading: {
    letterSpacing: "3.57px",
    textTransform: "uppercase",
    marginBottom: "40px",
  },
  rightContent: {
    flexGrow: 1,
    overflow: "hidden",
  },
  scroll: {
    display: "flex",
    flexWrap: "nowrap",
    // overflow: "auto",
    borderRadius: theme.shape.borderRadius,
    // paddingBottom: "62px",
    paddingLeft: "35px",
  },
  candleCardSpacer: {
    width: "1px",
    flexShrink: "0",
  },
}))

export default styles
