import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  legendContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  sectionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  nopeBubble: {
    display: "inline-block",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: "#D5D5D5",
    margin: "4px 5px 0px 0px",
  },
  curiousBubble: {
    display: "inline-block",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.light,
    margin: "4px 5px 0px 30px",
  },
  loveBubble: {
    display: "inline-block",
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.main,
    margin: "4px 5px 0px 30px",
  },
  description: {
    color: theme.palette.primary.main,
    verticalAlign: "center",
    padding: "auto 0",
  },
}))

export default styles
