import React, { Component } from "react"
import FormSelector from "components/DiscoverForm/FormSelector"
import { withStyles } from "@material-ui/core/styles"
import styles from "./styles"
import FormOne from "./Form1"
import FormTwo from "./Form2"
import FormThree from "./Form3"
import FormFour from "./Form4"
import FormFive from "./Form5"
import FormSix from "./Form6"

class DiscoverForm extends Component {
  state = {
    activeForm: 1,
  }

  handleNewActiveForm = (newActive) => {
    this.setState({ activeForm: newActive })
  }

  render() {
    const { classes } = this.props

    let shownForm = null

    switch (this.state.activeForm) {
      case 1:
        shownForm = <FormOne handleNewActiveForm={this.handleNewActiveForm} />
        break
      case 2:
        shownForm = <FormTwo handleNewActiveForm={this.handleNewActiveForm} />
        break
      case 3:
        shownForm = <FormThree handleNewActiveForm={this.handleNewActiveForm} />
        break
      case 4:
        shownForm = <FormFour handleNewActiveForm={this.handleNewActiveForm} />
        break
      case 5:
        shownForm = <FormFive handleNewActiveForm={this.handleNewActiveForm} />
        break
      case 6:
        shownForm = <FormSix handleNewActiveForm={this.handleNewActiveForm} />
        break
      default:
        shownForm = null
    }

    return (
      <div className={classes.discoverFormContainer}>
        <FormSelector
          active={this.state.activeForm}
          handleNewActiveForm={this.handleNewActiveForm}
        />
        {shownForm}
      </div>
    )
  }
}

export default withStyles(styles)(DiscoverForm)
