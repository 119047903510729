import React from "react"
import useStyles from "./styles"

const Footer = ({ children, show }) => {
  const FooterLogo = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/cf-logo-footer.svg`
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <img src={FooterLogo} className={classes.footerLogo} />
    </footer>
  )
}

export default Footer
