import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  libraryCardContainer: {
    display: "flex",
    marginBottom: "20px",
    flexDirection: "column",
    // maxWidth: 950
  },
  itemsDesktop: {
    display: "none",
    "@media (min-width:736px)": {
      display: "block",
    },
  },
  itemsMobile: {
    display: "none",
    "@media (max-width:735px)": {
      display: "block",
    },
  },
}))

export default styles
