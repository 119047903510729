import React, { useRef } from "react"

// **** M-UI IMPORTS ****
import useStyles from "./styles"
import Grid from "@material-ui/core/Grid"
import SearchIcon from "@material-ui/icons/Search"
import TextField from "@material-ui/core/TextField"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import classnames from "clsx"
// **** M-UI IMPORTS ****

const SearchBar = ({ handleChangeSearchBar }) => {
  const classes = useStyles()

  const [openSearch, setOpenSearch] = React.useState(false)

  const inputRef = useRef(null)

  const handleOpenSearch = () => {
    if (!openSearch) {
      setOpenSearch((openSearch) => !openSearch)
      setTimeout(() => {
        inputRef.current.focus()
      }, 100)
    }
  }

  const handleCloseSearch = () => {
    if (openSearch) {
      setOpenSearch((openSearch) => !openSearch)
    }
  }

  return (
    <Grid
      className={classnames(classes.searchBar, {
        [classes.searchBarOpen]: openSearch,
      })}
      container
      wrap="nowrap"
    >
      <SearchIcon
        className={classes.searchIcon}
        fontSize="large"
        onClick={() => handleOpenSearch()}
      />
      <TextField
        inputRef={inputRef}
        className={classes.textField}
        InputProps={{
          className: classes.input,
        }}
        inputProps={{
          maxLength: 10,
        }}
        placeholder="SEARCH..."
        onChange={(event) => handleChangeSearchBar(event)}
        onBlur={handleCloseSearch}
      />
      <ArrowForwardIosIcon
        className={classes.arrowIcon}
        onClick={() => handleCloseSearch()}
      />
    </Grid>
  )
}

export default SearchBar
