import React, { useMemo } from "react"
import useStyles from "./styles"
import { Link } from "@material-ui/core"
import Drawer from "@material-ui/core/Drawer"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { closeSideNav } from "actions/side-nav"
import { openDialog } from "actions/dialogs"
import { setUser } from "actions/authentication"
import { setUserCandleReaction } from "actions/users_candles"
import { saveCandleData } from "actions/candles"
import CloseIcon from "@material-ui/icons/Close"
import { IconButton, Button, Typography } from "@material-ui/core"
import { connect } from "react-redux"
import { logout } from "actions/authentication"
import camelcase from "camelcase"

const SideNav = ({ children, user }) => {
  const workshopIcon = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/workshop-icon-home.png`
  const classes = useStyles()
  const history = useHistory()

  const open = useSelector((state) => state.sideNav?.open || false)
  const links = useSelector((state) => state.links.links)

  console.log("links", links)

  // const user = useSelector(state => state.authentication.authenticatedUser.id)

  const dispatch = useDispatch()

  const handleCloseSideNav = () => {
    dispatch(closeSideNav())
  }

  const handleNavigationClick = (route, newTab = false) => {
    if (newTab) {
      window.open(route, "_blank")
    } else if (route.includes("http")) {
      window.location.href = route
    } else {
      history.push(route)
    }

    handleCloseSideNav()
  }

  const logoutBtn = () => {
    dispatch(setUser({}, ""))
    dispatch(setUserCandleReaction([]))
    dispatch(saveCandleData("selectedCandle", -1))
    dispatch(logout())
    // if (history.location.pathname === "/library-card" || history.location.pathname === "/library") {
    // if (localStorage.employeePin) {
    localStorage.removeItem("employeePin")
    localStorage.removeItem("store")
    localStorage.removeItem("employeePin")
    handleCloseSideNav()
    history.push("/")
    // } else {
    //   history.push("/")
    // }
    // }
    // handleCloseSideNav()
  }

  let userButton = (
    <Button
      variant="outlined"
      className={classes.loginButton}
      onClick={() => logoutBtn()}
    >
      Logout
    </Button>
  )
  if (user.token === "") {
    userButton = (
      <Button
        variant="outlined"
        className={classes.loginButton}
        onClick={() => dispatch(openDialog("saveProfile"))}
      >
        Login
      </Button>
    )
  }

  const linkList = useMemo(() => {
    const formatedLinks = []

    // Add parent links
    links.forEach((link) => {
      const {
        id,
        name,
        link: linkUrl,
        parent_id: parentId,
        position,
        new_tab: newTab,
      } = link
      if (!parentId) {
        formatedLinks.push({
          id,
          name: name,
          link: linkUrl,
          parent: parentId,
          position: position,
          newTab,
          childLinks: [],
        })
      }
    })

    // Add child links
    links.forEach((link) => {
      const {
        id,
        name,
        link: linkUrl,
        parent_id: parentId,
        position,
        new_tab: newTab,
      } = link
      if (parentId) {
        const parentIndex = formatedLinks.findIndex((parentLink) => {
          return Number(parentLink.id) === Number(parentId)
        })
        if (formatedLinks[parentIndex]?.childLinks) {
          formatedLinks[parentIndex].childLinks = [
            ...formatedLinks[parentIndex].childLinks,
            { id, name, link: linkUrl, parentId, position, newTab },
          ]
        }
      }
    })

    const sortedLinks = formatedLinks.sort(
      (a, b) => Number(a.position) - Number(b.position)
    )
    return sortedLinks
  }, [links])

  const openScheduler = () => {
    handleCloseSideNav()
    try {
      window.showScheduler()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        onClose={handleCloseSideNav}
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <IconButton
          className={classes.closeButton}
          size="medium"
          onClick={() => handleCloseSideNav()}
        >
          <CloseIcon />
        </IconButton>
        <div className={classes.options}>
          {/* <Typography variant="h1" className={classes.shopOption}>Shop</Typography>
          <Link variant="h1" className={classes.shopOption}>Shop</Link>
          <div className={classes.shopOptions}>
            <Link variant="h3" className={classes.shopOption}>Shop</Link>
            <Link variant="h3" className={classes.shopOption}>Shop</Link>
            <Link variant="h3" className={classes.shopOption}>Shop</Link>
          </div> */}

          {linkList.map((link) => (
            <div className={classes.linkContainer} key={link.id}>
              {link.link ? (
                <Link
                  key={link.id}
                  variant="h1"
                  className={classes.shopOption}
                  onClick={() => handleNavigationClick(link.link, link.newTab)}
                >
                  {link.name}
                </Link>
              ) : (
                <Typography
                  key={link.id}
                  variant="h1"
                  className={classes.shopOption}
                >
                  {link.name}
                </Typography>
              )}
              {link.childLinks.length > 0 && (
                <div
                  key={`${link.id}-children`}
                  className={classes.shopOptions}
                >
                  {link.childLinks.map((childLink) => {
                    if (childLink.link) {
                      return (
                        <Link
                          key={childLink.id}
                          variant="h3"
                          className={classes.shopOption}
                          onClick={() =>
                            handleNavigationClick(
                              childLink.link,
                              childLink.newTab
                            )
                          }
                        >
                          {childLink.name}
                        </Link>
                      )
                    } else {
                      return (
                        <Typography
                          key={childLink.id}
                          variant="h3"
                          className={classes.shopOption}
                        >
                          {childLink.name}
                        </Typography>
                      )
                    }
                  })}
                </div>
              )}
            </div>
          ))}
          <Link
            variant="h1"
            className={classes.shopOption2}
            onClick={() => openScheduler()}
          >
            Candle Making Classes
          </Link>

          {/* <Typography variant="h1" className={classes.shopOption}>Shop</Typography>
          <div className={classes.shopOptions}>
            {links.map(link => {
              if (link.type === "shop") {
                return (
                  <Typography
                    variant="h3"
                    onClick={() => handleNavigationClick(link?.link)}
                    className={classes.shopOption}>{link?.name}</Typography>
                )
              }
              return null
            })}
          </div>
          <Typography
            variant="h1"
            className={classes.discoverOption}
            onClick={() => handleNavigationClickStatic("/discover")}>
            Discover
          </Typography>
          <Typography
            variant="h1"
            onClick={() => handleNavigationClickStatic("/library")}
            className={classes.discoverOption}>The Library</Typography>
          {links.map(link => {
            if (link.type === "main") {
              return (
                <Typography
                  variant="h1"
                  onClick={() => handleNavigationClickStatic(link?.link)}
                  className={classes.discoverOption}>{link?.name}</Typography>
              )
            }
            return null
          })} */}
        </div>
        <div className={classes.bottomNav}>
          {userButton}
          {/* <div className={classes.scheduleWorkshop}>
            <img src={workshopIcon} alt="Workshop Icon" className={classes.workshopIcon}/>
            <Typography variant="h4">SCHEDULE <br/> WORKSHOP</Typography>
          </div> */}
        </div>
      </Drawer>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    user: state.authentication,
  }
}
export default connect(mapStateToProps)(SideNav)
