import { createMuiTheme } from "@material-ui/core/styles"
// import Diddot from "../assets/fonts/Didot.ttc"
const Diddot = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/Didot.ttc`

const primary = {
  main: "#FD6A4F",
  light: "#FAE4DF",
}
const secondary = {
  main: "#B2916F",
}
const text = {
  primary: "#000000",
}
const error = {}

const spacing = (factor) => factor * 8

export default createMuiTheme({
  spacing,
  palette: {
    primary,
    secondary,
    text,
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: "futura-pt, Roboto, 'sans-serif'",
    fontWeight: 300,
    h1: {
      fontFamily: "futura-pt-bold, Roboto, 'sans-serif'",
      fontSize: "2.2666rem",
    },
    h2: {
      fontFamily: "futura-pt-bold, Roboto, 'sans-serif'",
      fontSize: "1.6rem",
    },
    h3: {
      fontSize: "1.333rem",
      fontWeight: 400,
    },
    h4: {
      fontSize: "1.066rem",
      fontWeight: 400,
      textTransform: "none",
      letterSpacing: 0.7,
    },
    h5: {
      fontSize: "0.875rem",
    },
    h6: {
      fontSize: "0.75rem",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 300,
    },
    body2: {
      fontSize: "1rem",
      fontWeight: 300,
    },
    button: {
      fontFamily: "futura-pt-bold, Roboto, 'sans-serif'",
      letterSpacing: 2.2,
      fontSize: "0.7333rem",
    },
    caption: {
      fontFamily: "futura-pt-bold, Roboto, 'sans-serif'",
      letterSpacing: 0.88,
      color: secondary.main,
      textTransform: "uppercase",
      fontSize: "0.7333rem",
    },
    subtitle1: {
      fontFamily: "Material-Design-Iconic-Font",
      src: `url(${Diddot}) format('truetype')`,
      fontStyle: "italic",
      fontSize: "1.3333rem",
      lineHeight: 1.3,
    },
    overline: {
      fontFamily: "futura-pt, Roboto, 'sans-serif'",
      letterSpacing: 0.88,
      color: secondary.main,
      textTransform: "uppercase",
      fontSize: "0.7333rem",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: `${spacing(1)}px ${spacing(3)}px`,
        borderRadius: 0,
      },
      sizeSmall: {
        fontSize: "0.6rem",
      },
      sizeLarge: {
        fontSize: "1rem",
      },
      contained: {
        backgroundColor: "white",
        color: primary.main,
        boxShadow: "none",
        margin: "6px",
        border: "none",
        position: "relative",
        "&:before": {
          content: "''",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          border: `2px solid ${primary.main}`,
        },
        "&:hover": {
          backgroundColor: "white",
          boxShadow: "none",
        },
        "&:active": {
          boxShadow: "none",
        },
        "&$disabled": {
          backgroundColor: "white",
          color: primary.main,
          opacity: 0.5,
        },
      },
      containedSizeLarge: {
        padding: "10px 26px",
      },
      containedPrimary: {
        color: "white",
        boxShadow: "none",
        border: `6px solid ${primary.main}`,
        "&:before": {
          content: "''",
          width: "100%",
          height: "100%",
          position: "absolute",
          border: "2px solid white",
        },
        "&:hover": {
          backgroundColor: primary.main,
        },
        "&$disabled": {
          backgroundColor: primary.main,
          color: "white",
          opacity: 0.5,
        },
      },
      containedSecondary: {
        color: "white",
        boxShadow: "none",
        border: `6px solid ${secondary.main}`,
        "&:before": {
          content: "''",
          width: "100%",
          height: "100%",
          position: "absolute",
          border: "2px solid white",
        },
        "&:hover": {
          backgroundColor: secondary.main,
        },
        "&$disabled": {
          backgroundColor: secondary.main,
          color: "white",
          opacity: 0.5,
        },
      },
      outlined: {
        borderWidth: [2, "!important"],
        borderColor: text.primary,
      },
      outlinedPrimary: {
        borderColor: primary.main,
        "&$disabled": {
          color: primary.main,
          borderColor: primary.main,
          opacity: 0.5,
        },
      },
      outlinedSecondary: {
        borderColor: secondary.main,
        borderRadius: "10px",
        padding: "5px 25px",
        "&$disabled": {
          color: secondary.main,
          borderColor: secondary.main,
          opacity: 0.5,
        },
      },
      text: {
        color: text.primary,
        "&$disabled": {
          color: text.primary,
          opacity: 0.5,
        },
      },
      textPrimary: {
        color: primary.main,
        "&$disabled": {
          color: primary.main,
          opacity: 0.5,
        },
      },
      textSecondary: {
        color: secondary.main,
        "&$disabled": {
          color: secondary.main,
          opacity: 0.5,
        },
      },
    },
    MuiFab: {
      root: {
        height: 68,
        width: 68,
        fontSize: "1.4666rem",
        "@media (max-width:735px)": {
          height: 47,
          width: 47,
          fontSize: "1.2rem",
        },
        boxShadow: "none",
        color: text.primary,
        backgroundColor: "transparent",
        border: `1px solid ${text.primary}`,
        "&$disabled": {
          color: text.primary,
          backgroundColor: "transparent",
          opacity: 0.5,
        },
      },
      primary: {
        color: primary.main,
        backgroundColor: "transparent",
        border: `1px solid ${primary.main}`,
        "&:hover": {
          backgroundColor: "inherit !important",
        },
      },
      secondary: {
        color: secondary.main,
        backgroundColor: "transparent",
        border: `1px solid ${secondary.main}`,
      },
    },
    MuiDialog: {
      root: {
        zIndex: 1301,
      },
      paper: {
        width: "100%",
        maxWidth: [800, "!important"],
        boxShadow: "0px 3px 6px #00000029",
      },
      paperFullScreen: {
        maxWidth: ["100%", "!important"],
      },
    },
    MuiDialogTitle: {
      root: {
        padding: spacing(2),
      },
    },
    MuiDialogContent: {
      root: {
        padding: spacing(2),
      },
    },
    MuiDialogActions: {
      root: {
        padding: spacing(2),
        paddingTop: 0,
      },
    },
    MuiCard: {
      root: {
        boxShadow: "-14px 0px 26px #00000012",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        color: "white",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 14px) scale(1)",
      },
      filled: {
        color: "white",
        "&$focused": {
          color: "white",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        "&:hover": {
          "& $notchedOutline": {
            borderColor: primary.main,
          },
        },
      },
      notchedOutline: {
        borderColor: primary.main,
        borderWidth: [1, "!important"],
        "& > legend": {
          maxWidth: ["0.01px", "!important"],
        },
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
      outlined: {
        padding: "12px 14px",
      },
      iconOutlined: {
        color: "inherit",
        top: "calc(50% - 9px)",
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius: [33, "!important"],
        backgroundColor: primary.main,
        color: "white",
        "&:hover": {
          backgroundColor: primary.main,
          "&:after": {
            borderBottom: "none",
          },
          "&:before": {
            borderBottom: ["none", "!important"],
          },
        },
        "&$focused": {
          backgroundColor: [primary.main, "!important"],
        },
        "&:not(.MuiInputBase-formControl)": {
          "& .MuiFilledInput-input": {
            padding: "12px 10px",
            fontSize: "4.7333rem",
            fontWeight: 400,
          },
        },
      },
      underline: {
        "&:after": {
          borderBottom: "none",
        },
        "&:before": {
          borderBottom: "none",
        },
      },
    },
    MuiRadio: {
      root: {
        color: primary.main,
      },
    },
    MuiSlider: {
      thumb: {
        width: 15,
        height: 15,
        marginTop: -7,
        marginLeft: -7,
        boxShadow: "0px 3px 6px #00000029",
        "&:hover": {
          boxShadow: "0px 3px 6px #00000029",
        },
      },
      valueLabel: {
        left: "calc(-50% - 2px)",
      },
      mark: {
        backgroundColor: "transparent",
      },
      markActive: {
        backgroundColor: "transparent",
      },
      markLabel: {
        color: secondary.main,
        backgroundColor: "transparent",
      },
      markLabelActive: {
        color: secondary.main,
        background: "transparent",
      },
      marked: {
        marginBottom: 0,
      },
    },
    PrivateValueLabel: {
      label: {
        color: "white",
        fontWeight: 400,
      },
    },
    MuiTableCell: {
      root: {
        padding: "10px",
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: secondary.main,
      },
      arrow: {
        color: secondary.main,
      },
    },
  },
})
