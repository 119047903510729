const styles = (theme) => ({
  primary: {
    "@media (max-width: 500px)": {
      "&:hover": {
        backgroundColor: "white",
      },
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderColor: [theme.palette.primary.main, "!important"],
    "&:hover": {
      backgroundColor: [theme.palette.primary.main, "!important"],
      borderColor: theme.palette.primary.main,
      color: "white",
    },
  },
  liked: {
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    color: "white",
    "&:hover": {
      backgroundColor: [theme.palette.secondary.main, "!important"],
      borderColor: theme.palette.secondary.main,
      borderWidth: "2px",
      color: "white",
    },
  },
  disliked: {
    backgroundColor: "#D5D5D5",
    borderColor: "#D5D5D5",
    color: "white",
    "&:hover": {
      backgroundColor: ["#D5D5D5", "!important"],
      borderColor: "#D5D5D5",
      color: "white",
    },
  },
  curious: {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: [theme.palette.primary.light, "!important"],
      borderColor: theme.palette.primary.light,
    },
  },
})

export default styles
