import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  formContainer: {
    marginTop: "40px",
    width: "100%",
  },
  discoverHeading: {
    margin: "0px 0px 20px 20px",
    color: theme.palette.primary.main,
  },
  discoverDescription: {
    margin: "0px 0px 0px 20px",
    color: theme.palette.secondary.main,
    "@media (max-width: 500px)": {
      margin: "0px 0px 60px 20px",
    },
  },
  form: {
    width: "100%",
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    margin: "140px 0px 16px 20px",
    justifyContent: "center",
    "@media (max-width: 500px)": {
      flexDirection: "column",
      margin: "0px 0px 0px 0px",
    },
  },
  textField: {
    width: "60%",
    "@media (max-width: 500px)": {
      width: "75%",
    },
  },
  formLabelRoot: {
    "&$formLabelFocused": {
      color: "red",
    },
  },
  formOneButton: {
    width: "40%",
    background: "black",
    color: "white",
    margin: "0px 0px 0px 10px",
    padding: "14px 0px 14px 0px",
    whiteSpace: "nowrap",
    border: "none",
    "&:hover": {
      background: "black",
    },
    "@media (max-width: 500px)": {
      width: "75%",
      margin: "30px 0px 30px 0px",
    },
  },
  textRoot: {
    "& > label": {
      paddingLeft: "5px",
    },
    "& $label + .MuiInput-formControl": {
      paddingLeft: "5px",
    },
    "& .MuiInput-underline": {
      "&:not(.Mui-disabled)": {
        "&:before": {
          borderBottom: `1px solid${theme.palette.primary.main}`,
        },
      },
      "&:hover": {
        "&:not(.Mui-disabled)": {
          "&:before": {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
          },
        },
      },
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.main,
      fontWeight: 700,
      letterSpacing: "3.4px",
      whiteSpace: "nowrap",
    },
  },
  closingText: {
    marginLeft: "20px",
    "@media (max-width: 500px)": {
      textAlign: "center",
      marginLeft: "0px",
    },
  },
}))

export default styles
