import { SIDENAV_OPEN, SIDENAV_CLOSE } from "types/actions/side-nav"

const sideNavInitialState = {}

export default (state = sideNavInitialState, action) => {
  switch (action.type) {
    case SIDENAV_OPEN:
      return {
        ...state,
        open: action.open,
      }
    case SIDENAV_CLOSE:
      return {
        ...state,
        open: action.open,
      }
    default:
      return state
  }
}
