import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  queue: {
    position: "absolute",
    top: "175px",
    bottom: "56px",
    maxHeight: "100vh",
  },
  contentContainer: {
    paddingTop: "50px",
    height: "100%",
  },
  list: {
    color: theme.palette.primary.main,
    width: "70%",
    overflowY: "scroll",
    "& .infinite-scroll-component__outerdiv": {
      width: "100%",
    },
    "@media (max-width:500px)": {
      width: "95%",
    },
  },
  listItem: {
    border: `1px solid ${theme.palette.primary.main}`,
    margin: "5px auto",
    height: "50px",
    width: "92%",
    "@media (max-width:700px)": {
      width: "90%",
    },
    "@media (max-width:500px)": {
      width: "75%",
    },
  },
  listItemTextTwo: {
    display: "flex",
    justifyContent: "flex-end",
  },
  form: {
    marginTop: "10px",
    width: "65%",
  },
  activeIndicator: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.main,
    position: "absolute",
    left: "-3%",
    "@media (max-width:700px)": {
      left: "-5%",
    },
    "@media (max-width:500px)": {
      width: "-10%",
    },
  },
  textField: {
    padding: "5px 0",
  },
  button: {
    justifyContent: "flex-end",
    color: "white",
    backgroundColor: "black",
    padding: "8px 36px",
    margin: "5px 0",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

export default styles
