import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  // notesList: {
  //   overflowX: "scroll",
  //   whiteSpace: "nowrap"
  // }
  notesList: {
    display: "flex",
    flexWrap: "nowrap",
  },
}))

export default styles
