import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import useStyles from "./styles"
import { Box, Paper, Typography } from "@material-ui/core"
import classnames from "clsx"
import NotesList from "./NotesList"

const Category = ({ category, modal }) => {
  const classes = useStyles()

  const BackgroundImage = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/category-background.png`

  const candles = useSelector((state) => state.candles["candles"])
  const familyCandles = useMemo(
    () => candles.filter((x) => x.family.name === category),
    [category]
  )

  let notes = familyCandles[0].family.notes

  return (
    <Box
      display="flex"
      className={classnames({
        [classes.category]: true,
        [classes.modalCategory]: modal,
      })}
    >
      <Box className={classes.cardArea}>
        <Paper elevation={4} className={classes.card}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            className={classes.cardText}
          >
            <Typography
              variant="h2"
              color="secondary"
              className={classnames(classes.h2, classes.cardTitle)}
            >
              About
            </Typography>
            <Typography variant="h1" className={classes.categoryTypography}>
              {familyCandles[0].family.name}
            </Typography>
          </Box>
          <img src={BackgroundImage} />
        </Paper>
      </Box>
      <Box
        className={classnames({
          [classes.contentArea]: true,
          [classes.modalContentArea]: modal,
        })}
      >
        <Typography variant="h2" color="secondary" className={classes.h2}>
          {category}
        </Typography>
        <Typography className={classes.paragraph}>
          {familyCandles[0].family.description}
        </Typography>
        <Typography variant="h2" color="secondary" className={classes.h2}>
          Where would I know this from?
        </Typography>
        <Typography className={classes.paragraph}>
          {familyCandles[0].family.where_description}
        </Typography>
        <Typography variant="h2" color="secondary" className={classes.h2}>
          Common Fragrance Notes
        </Typography>
        <NotesList notes={notes} />
      </Box>
    </Box>
  )
}

export default Category
