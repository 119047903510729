import API from "store/api"
import request from "superagent"
import {
  CANDLES_SET_ERROR,
  CANDLES_SET_DATA,
  CANDLES_UPDATE_DATA,
} from "types/actions/candles"

export const setError = (error = false) => ({
  type: CANDLES_SET_ERROR,
  error,
})

export const setUsers = (data) => ({
  type: CANDLES_SET_DATA,
  data,
})

export const updateData = (data) => ({
  type: CANDLES_UPDATE_DATA,
  data,
})

export const getCandles = () => {
  return API.get("/candles")
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.body)
    .then(async (result) => {
      return result.data
    })
    .catch((err) => {
      console.log(setError(err))
    })
}

export const getCandle = (candleId) => {
  return API.get(`/candles/${candleId}`)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.body)
    .then(async (result) => {
      return result.data
    })
    .catch((err) => {
      console.log(setError(err))
    })
}

export const getYourFragrances = (data) => {
  return API.post("/candles/discover")
    .send(data)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.body)
    .then(async (result) => {
      return result.data
    })
    .catch((err) => {
      console.log(setError(err))
    })
}

export const getFeaturedCandles = () => {
  // Featured Collection ID: 222342676632
  return API.get("/shopify/featured/222342676632")
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.body)
    .then(async (result) => {
      return result
    })
    .catch((err) => {
      console.log(setError(err))
    })
}

export const saveCandleData = (dataName, data) => ({
  type: CANDLES_SET_DATA,
  dataName,
  data,
})

export const getFragranceVariants = (id) => {
  return API.get(`/shopify/product/${id}`)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.body)
    .then((result) => {
      return result.product
    })
    .catch((err) => {
      console.error(err)
    })
}

export const getFragranceCollection = (id) => {
  let promiseArr = []

  return (dispatch) => {
    return API.get(`/shopify/collection/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.body)
      .then((result) => {
        if (!result.errors) {
          result.products.map((x) => {
            let curProduct = getFragranceVariants(x.id)
            promiseArr.push(curProduct)
          })
          Promise.allSettled(promiseArr).then((results) => {
            dispatch(saveCandleData("selectedCandleVariants", results.flat()))
          })
          return result.product
        } else {
          dispatch(saveCandleData("selectedCandleVariants", []))
          return -1
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export const getCartContents = () => {
  return request
    .get("https://www.candlefish.com/cart.js")
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => {
      return response
    })
    .catch((err) => {
      console.error(err)
    })
}

export const addToCart = (data) => {
  return (dispatch) => {
    return request
      .post("https://www.candlefish.com/cart/add.js")
      .type("form")
      .send(data)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.body)
      .then((result) => {
        getCartContents().then((res) => {
          dispatch(saveCandleData("cartCount", JSON.parse(res.text).item_count))
        })
        return result
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export const addUserSession = (data, user) => {
  return API.post("/sessions")
    .send({ data, user_id: user })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.body)
    .then(async (result) => {
      return result.data
    })
    .catch((err) => {
      console.log(setError(err))
    })
}

export const getFamilies = () => {
  return API.get("/families")
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then((response) => response.body)
    .then(async (result) => {
      return result.data
    })
    .catch((err) => {
      console.log(setError(err))
    })
}
