import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  header: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    // padding: "20px 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    pointerEvents: "none",
    zIndex: "10",
  },
  banner: {
    display: "flex",
    justifyContent: "center",
    background: "black",
    width: "100%",
    padding: theme.spacing(1, 0),
  },
  bannerContent: {
    maxWidth: 700,
    textAlign: "center",
    color: "#fff",
    pointerEvents: "all",
    fontSize: "15px",
    fontWeight: 400,
    "& a": {
      color: "#fff",
    },
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: theme.spacing(2.5, 1),
  },
  leftHeader: {
    display: "flex",
    alignItems: "center",
  },
  // spacer: {
  //   minHeight: 108
  // },
  candle: {
    color: theme.palette.secondary.main,
    letterSpacing: "2.2px",
    fontSize: "1.7rem",
    pointerEvents: "all",
    "&:hover": {
      cursor: "pointer",
    },
  },
  altCandle: {
    color: "white",
  },
  menuIcon: {
    pointerEvents: "all",
    position: "absolute",
  },
  button: {
    position: "relative",
    height: 68,
    width: 68,
  },
  color: {
    color: "white !important",
  },
  shop: {
    pointerEvents: "all",
    display: "flex",
    alignItems: "center",
    paddingRight: "12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  cartIcon: {
    height: "30px",
    width: "30px",
    pointerEvents: "all",
    "&:hover": {
      cursor: "pointer",
    },
  },
}))

export default styles
