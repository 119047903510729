import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  logo: {
    maxWidth: 254,
    margin: `${theme.spacing(6)}px auto`,
    display: "block",
    verticalAlign: "bottom",
  },
  featuredCandles: {
    margin: theme.spacing(13, 0, 6),
    "@media (max-width: 1024px)": {
      margin: theme.spacing(6, 0, 3),
    },
  },
  workshopBtn: {
    display: "flex",
    position: "fixed",
    maxHeight: "75px",
    maxWidth: "300px",
    bottom: 0,
    right: 50,
    margin: 0,
    textAlign: "left",
    "@media (max-width: 760px)": {
      display: "none",
    },
  },
}))

export default styles
