import React from "react"
import useStyles from "./styles"
import { Typography } from "@material-ui/core"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { useHistory } from "react-router-dom"

const HomeNavButton = ({ children, label = "", route = "", shop = false }) => {
  const history = useHistory()
  const classes = useStyles()

  const handleClick = () => {
    if (shop) {
      window.scrollTo(0, 0)
      window.open(route, "_blank")
    } else {
      history.push(route)
      window.scrollTo(0, 0)
    }
  }

  return (
    <div className={classes.optionContainer}>
      <div className={classes.option} onClick={handleClick}>
        <Typography variant="h2">{label}</Typography>
        <ChevronRightIcon className={classes.chevIconHidden} />
      </div>
      <span className={classes.optionsBar} />
    </div>
  )
}

export default HomeNavButton
