import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SET_DIALOG_DATA,
} from "types/actions/dialogs"

export const openDialog = (name, data = {}) => ({
  type: OPEN_DIALOG,
  name,
  data,
})

export const closeDialog = (name) => ({
  type: CLOSE_DIALOG,
  name,
})

export const setDialogData = (name, data = {}) => ({
  type: SET_DIALOG_DATA,
  name,
  data,
})
