import React from "react"
import { useDispatch, useSelector } from "react-redux"
import useStyles from "./styles"
import { Dialog, DialogContent, Box, IconButton } from "@material-ui/core"
import { closeDialog } from "actions/dialogs"
import ShopItem from "./ShopItem"
import CloseIcon from "@material-ui/icons/Close"

const AddToCartDialog = () => {
  const saveProfileCandlefish = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/saveProfileCandlefish2.png`
  const classes = useStyles()

  const dispatch = useDispatch()

  const open = useSelector((state) => state.dialogs.addToCart?.open || false)

  const item = useSelector((state) => state.candles.selectedCandleVariants)

  const handleClose = () => {
    dispatch(closeDialog("addToCart"))
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogContent className={classes.dialogContent}>
        <IconButton
          color="primary"
          onClick={handleClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
        <img
          src={saveProfileCandlefish}
          alt="Candlefish Logo"
          className={classes.candlefishLogo}
        />
        {item.length < 1 ? (
          <Box display="flex" className={classes.shopItems}>
            No Items Found
          </Box>
        ) : (
          <Box display="flex" className={classes.shopItems}>
            {item.map((item) => (
              <ShopItem
                key={item.value.id}
                item={item.value}
                closeDialog={handleClose}
              />
            ))}
            <div style={{ height: 0 }}>&nbsp;</div>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default AddToCartDialog
