import React, { useState, useEffect } from "react"
import useStyles from "./styles"
import { Box, Button, Typography, Tooltip } from "@material-ui/core"
import Note from "./Note"
import classnames from "clsx"

const noteTypeDescriptions = [
  {
    type: "top",
    description:
      "Top notes, or head notes, 'introduce' the fragrance. They have very small molecules, making them the first thing you smell when you experience a candle or perfume. Typically the strongest notes in a blend, top notes come on strong then go away quickly. Most often they are light and fresh notes like citrus and green notes.",
    characteristics: " clean, light, fresh, uplifting, refreshing",
  },
  {
    type: "middle",
    description:
      "Middle notes are the complex, full-bodied heart of the fragrance. These notes last longer than the top notes, and are often a combination of floral or fruit tones, sometimes with a hint of spice.",
    characteristics: " full bodied, pleasant, smooth, and well-rounded",
  },
  {
    type: "dry",
    description:
      "Dry notes (sometimes called base note, or dry-down) take the longest to evaporate and create the base for the blend. The heaviest notes in a blend, you notice these notes at the end of the fragrance. Sweeter, heavier, warmer notes like woods, amber, vanilla, sandalwood.",
    characteristics: " heavy, long lasting, dense, deep, strong",
  },
]

const FragranceNotes = ({ notes, selected }) => {
  const classes = useStyles()

  const [selectedNote, setSelectedNote] = useState(null)
  const [show, setShow] = useState([])

  useEffect(() => {
    setSelectedNote(null)
  }, [selected])

  const openTooltip = (i) => {
    setShow((show) => [...show, i])
  }

  const closeTooltip = (i) => {
    setShow((show) => show.filter((y) => y !== i))
  }

  const handleNoteClick = (i) => {
    if (i === selectedNote) {
      i = null
    }
    setSelectedNote(i)
  }

  return (
    <Box className={classes.notes}>
      {notes.map((note, i) => (
        <Note
          isFlipped={selectedNote === i}
          key={i}
          frontSide={
            <Box
              className={classnames(classes.note, {
                [classes.noteFirst]: i === 0,
                [classes.noteLast]: i === notes.length - 1,
              })}
              data-index={i}
              display="flex"
              alignItems="center"
            >
              <Button
                className={classes.noteButton}
                onClick={() => handleNoteClick(i)}
              >
                {note.type} Fragrance
              </Button>
              <img src={note.image} alt={note.name} />
              <Box>
                <Typography variant="h3" color="primary">
                  {note.name}
                </Typography>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  onClose={() => closeTooltip(i)}
                  onOpen={() => openTooltip(i)}
                  open={show.includes(i)}
                  title={
                    <>
                      <Typography className={classes.noteTooltipTitle}>
                        What is a {note.type} fragrance note?
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.noteTooltipDescription}
                      >
                        {
                          noteTypeDescriptions.find((n) => n.type === note.type)
                            .description
                        }
                      </Typography>
                      <Typography variant="subtitle2">
                        <strong>Characteristics:</strong>
                        {
                          noteTypeDescriptions.find((n) => n.type === note.type)
                            .characteristics
                        }
                      </Typography>
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <Typography
                    component="span"
                    className={classes.noteLabel}
                    onClick={() => openTooltip(i)}
                  >
                    {note.type} Fragrance
                  </Typography>
                </Tooltip>
                <Typography>{note.description}</Typography>
              </Box>
            </Box>
          }
          backSide={
            <Box
              className={classnames(classes.note, classes.noteBack)}
              display="flex"
              alignItems="center"
            >
              <Button color="inherit" onClick={() => handleNoteClick(i)}>
                {note.type} Fragrance
              </Button>
              <Typography variant="h3" className={classes.noteBackTitle}>
                What is a {note.type} fragrance note?
              </Typography>
              <Typography>
                {
                  noteTypeDescriptions.find((n) => n.type === note.type)
                    .description
                }
              </Typography>
              <Typography className={classes.noteCharacteristics}>
                <strong>Characteristics:</strong>
                {
                  noteTypeDescriptions.find((n) => n.type === note.type)
                    .characteristics
                }
              </Typography>
            </Box>
          }
        />
      ))}
      <Box className={classes.spacer}>&nbsp;</Box>
    </Box>
  )
}

export default FragranceNotes
