import React, { useState, useEffect, useMemo } from "react"
import useStyles from "./styles"
import { Box, Typography, Container, Button } from "@material-ui/core"
import FragranceBubble from "./FragranceBubble"
import { useDispatch, useSelector } from "react-redux"
import { openQuickEdit } from "actions/quick-edit"
import LongContent from "components/LibraryCard/LongContent"
import ArrowIcon from "@material-ui/icons/ExpandMore"
import { saveCandleData } from "actions/candles"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { getFragranceCollection } from "actions/candles"

const YourFragrances = ({ children }) => {
  const classes = useStyles()
  const candles = useSelector((state) => state.candles["candles"])
  const yourFragrances = useSelector((state) => {
    return state.candles["fragrances"]
  })
  const selectedCandle = useSelector((state) => state.candles["selectedCandle"])

  const dispatch = useDispatch()

  if (selectedCandle < 0) {
    dispatch(saveCandleData("selectedCandle", yourFragrances[0]))
  }

  let editText = window.innerWidth < 735 ? "EDIT" : "EDIT DISCOVER FIELDS"

  let editButton =
    window.innerWidth < 735 ? (
      <KeyboardArrowDownIcon
        classes={{ containedPrimary: classes.updateIcon }}
      />
    ) : (
      <KeyboardArrowUpIcon classes={{ containedPrimary: classes.updateIcon }} />
    )

  const fragrances = [
    {
      number: yourFragrances[0],
    },
    {
      number: yourFragrances[1],
    },
    {
      number: yourFragrances[2],
    },
  ]

  const [selectedItem, setSelectedItem] = useState(0)

  const leftSpacerFlexGrowValue = useMemo(() => {
    if (selectedItem === 0) {
      return 0
    } else if (selectedItem === 2) {
      return 2
    } else {
      return 1
    }
  }, [selectedItem])

  const rightSpacerFlexGrowValue = useMemo(() => {
    if (selectedItem === 2) {
      return 0
    } else if (selectedItem === 0) {
      return 2
    } else {
      return 1
    }
  }, [selectedItem])

  const handleClick = (i) => {
    setSelectedItem(i)
    dispatch(saveCandleData("selectedCandle", yourFragrances[i]))
  }

  const loadFragranceVariants = async () => {
    let shopifyId = -1
    let selectedCandle = -1
    if (selectedItem > -1) {
      selectedCandle = candles.filter((x) => {
        return x.id === selectedItem + 1
      })
      if (typeof selectedCandle[0] !== "undefined") {
        shopifyId = selectedCandle[0].collection_id
      }
      const response = await dispatch(getFragranceCollection(shopifyId))
      return response
    } else return -1
  }

  useEffect(() => {
    loadFragranceVariants().then((res) => {
      if (res !== -1 && res) {
        let image = new Image()
        image.src = res.image.src
      }
    })
  }, [selectedItem])

  return (
    <Box className={classes.page}>
      <Container className={classes.container}>
        <Box display="flex" classes={{ root: classes.boxRoot }}>
          <Box className={classes.textArea}>
            <Typography
              variant="h1"
              className={classes.heading}
              color="primary"
            >
              Your top suggested fragrances
            </Typography>
            <Typography className={classes.text} color="secondary">
              Based on your answers, we curated three fragrances we think you’ll
              love. Click shuffle or edit discover fields to keep discovering
              more fragrances!
            </Typography>
          </Box>
          <Box className={classes.fragrancesArea}>
            <Box className={classes.bubbles}>
              {fragrances.map((fragrance, i) => (
                <FragranceBubble
                  key={i}
                  number={fragrance.number}
                  onClick={() => handleClick(i)}
                  selected={selectedItem === i}
                />
              ))}
            </Box>
            <Box className={classes.indicatorArea}>
              <Box
                className={classes.spacer}
                style={{ flexGrow: leftSpacerFlexGrowValue }}
              />
              <Box className={classes.indicatorBox}>
                <ArrowIcon style={{ fontSize: 48 }} color="secondary" />
              </Box>
              <Box
                className={classes.spacer}
                style={{ flexGrow: rightSpacerFlexGrowValue }}
              />
            </Box>
          </Box>
        </Box>
      </Container>
      <LongContent
        selectedItem={fragrances[selectedItem].number}
        setSelectedItem={setSelectedItem}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => dispatch(openQuickEdit())}
        className={classes.workshopBtn}
        classes={{ contained: classes.buttonOverride }}
      >
        {editText}
        {editButton}
      </Button>
    </Box>
  )
}

export default YourFragrances
