import React, { Component } from "react"
import { withRouter } from "react-router"
import { withStyles } from "@material-ui/core/styles"
import withWidth from "@material-ui/core/withWidth"
import styles from "./styles"
import { connect } from "react-redux"
import { compose } from "recompose"
import Snackbar from "@material-ui/core/Snackbar"
import { closeSnackBar } from "../../actions/snack-bar"
import Router, { routeConfig } from "router"
import SaveProfile from "dialogs/SaveProfile"
import SideNav from "components/SideNav"
import QuickEdit from "components/QuickEdit"
import FamilyModal from "components/FamilyModal"
import AddToCartDialog from "dialogs/AddToCart"
import { saveCandleData } from "actions/candles"
import {
  getCandles,
  getFamilies,
  getFeaturedCandles,
  getCartContents,
} from "actions/candles"
import preloadImages from "utils/preloadImages"
import preloadNotes from "utils/preloadNotes"
import { getLinks } from "actions/links"
import { getBannerData } from "actions/settings"
import ReactGA from "react-ga"

class App extends Component {
  componentDidMount() {
    // Intialize GA code
    ReactGA.initialize("UA-47554537-2")

    // GA Listen for page change
    this.props.history.listen((location) => {
      ReactGA.set({ page: location.pathname })
      ReactGA.pageview(location.pathname)
    })

    // GA Intial page load
    ReactGA.pageview(this.props.location.pathname)

    this.props.dispatch(getBannerData())

    getCandles()
      .then((res) => {
        this.props.dispatch(saveCandleData("candles", res))
      })
      .then(preloadImages)
      .then(preloadNotes)
    getFamilies().then((res) => {
      this.props.dispatch(saveCandleData("families", res))
    })
    getFeaturedCandles().then((res) => {
      this.props.dispatch(saveCandleData("featuredCandles", res))
    })
    this.props.dispatch(getLinks())
    getCartContents().then((res) => {
      if (res) {
        this.props.dispatch(
          saveCandleData("cartCount", JSON.parse(res.text).item_count)
        )
      }
    })
  }

  render() {
    const { classes } = this.props
    const snackBarOrigin =
      this.props.width === "sm" || this.props.width === "xs"
        ? { vertical: "bottom", horizontal: "left" }
        : { vertical: "bottom", horizontal: "left" }
    return (
      <div className={classes.app}>
        <Router routes={routeConfig} />
        <div id="dialog-holder">
          {/* LEAVE THIS IN PLACE FOR DIALOGS TO POPULATE INTO */}
          <SaveProfile />
          <SideNav />
          <QuickEdit />
          <FamilyModal />
          <AddToCartDialog />
        </div>
        <Snackbar
          anchorOrigin={snackBarOrigin}
          open={this.props.snackBar.open}
          onClose={() => this.props.dispatch(closeSnackBar())}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          autoHideDuration={this.props.snackBar.autoHideDuration}
          message={<span id="message-id">{this.props.snackBar.message}</span>}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    snackBar: state.snackBar,
    candles: state.candles,
  }
}

export default withRouter(
  compose(connect(mapStateToProps), withStyles(styles), withWidth())(App)
)
