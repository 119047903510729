import API from "store/api"
import {
  SET_EMPLOYEE_DATA,
  SET_ADMIN_DATA,
  SET_STORE_LOCATION,
  SET_SUPER_ADMIN,
  SET_KIOSK_DATA,
  SET_CHARLESTON_DATA,
  SET_ATLANTA_DATA,
} from "types/actions/admins"
import { USERS_SET_LOADING, USERS_SET_ERROR } from "types/actions/users"
import { setAdminAuth } from "actions/authentication"
export const setEmployeeList = (data) => ({
  type: SET_EMPLOYEE_DATA,
  data,
})

export const setAdminData = (dataName, data) => ({
  type: SET_ADMIN_DATA,
  dataName,
  data,
})
export const setSuperAdmin = (data) => ({
  type: SET_SUPER_ADMIN,
  data,
})
export const setKioskData = (data) => ({
  type: SET_KIOSK_DATA,
  data,
})
export const setCharlestonData = (data) => ({
  type: SET_CHARLESTON_DATA,
  data,
})
export const setAtlantaData = (data) => ({
  type: SET_ATLANTA_DATA,
  data,
})
export const setStoreLocation = (data) => ({
  type: SET_STORE_LOCATION,
  data,
})

export const setLoading = (loading = true) => ({
  type: USERS_SET_LOADING,
  loading,
})

export const setError = (error = false) => ({
  type: USERS_SET_ERROR,
  error,
})

export const getEmployeeList = () => {
  return (dispatch) => {
    return API.get("/admins")
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.body)
      .then((result) => {
        dispatch(setEmployeeList(result.data))
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export const addAdmin = (query) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return API.post("/admins")
      .send(query)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.body)
      .then((result) => {
        return API.get("/admins")
          .then((response) => {
            if (!response.ok) {
              throw Error(response.statusText)
            }
            return response
          })
          .then((response) => response.body)
          .then((result) => {
            dispatch(setEmployeeList(result.data))
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export const updateAdmin = (id, values) => {
  return (dispatch) => {
    return API.put(`/admins/${id}`)
      .send(values)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => {
        dispatch(getEmployeeList())
        return response
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export const deleteAdmin = (id) => {
  return (dispatch) => {
    return API.delete(`/admins/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => {
        dispatch(getEmployeeList())
        return response
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export const checkPin = (query) => {
  return (dispatch) => {
    return API.post("/admins/pin")
      .send(query)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.body)
      .then((result) => {
        let storeLocation = result.location
        dispatch(setStoreLocation(storeLocation))
        return true
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export const EmpcheckPin = (query) => {
  return (dispatch) => {
    return API.post("/admins/pin")
      .send(query)
      .then((response) => {
        return response
      })
      .then((response) => response.body)
      .then((result) => {
        let data = result
        let query = {}
        query.token = data.token
        query.admin = data.body
        query.admin_id = data.user_id
        API.set("Authorization", data.token)
        dispatch(setAdminAuth(query))
        dispatch(setAdminData("chandler", false))
        return true
      })
      .catch((err) => {
        console.error(err)
      })
  }
}
