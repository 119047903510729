import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  form: {
    margin: "35px auto",
    padding: "20px 15px",
    maxWidth: "400px",
  },
  title: {
    marginBottom: "15px",
    color: "#B2916F",
    textAlign: "center",
  },
  textField: {
    marginBottom: 10,
    width: "100%",
  },
  textRoot: {
    "& > label": {
      color: theme.palette.primary.main,
      fontSize: "20px",
      fontWeight: "500",
      paddingLeft: "5px",
    },
    "& $label + .MuiInput-formControl": {
      paddingLeft: "5px",
      paddingBottom: "5px",
    },
    "& .MuiInput-underline": {
      "&:not(.Mui-disabled)": {
        "&:before": {
          borderBottom: `2px solid${theme.palette.primary.main}`,
        },
      },
      "&:hover": {
        "&:not(.Mui-disabled)": {
          "&:before": {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
          },
        },
      },
    },
  },
  spinner: {
    color: theme.palette.primary.main,
  },
  // snackbarRoot: {
  //   color: theme.palette.primary.main
  // },
  button: {
    margin: "25px auto 0",
  },
}))

export default styles
