import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  home: {
    height: "100%",
    width: "100%",
    background: "#B2916F80",
    display: "flex",
    "@media (max-width: 500px)": {
      display: "block",
      background: "white",
    },
  },
  workshopBtn: {
    display: "flex",
    position: "absolute",
    maxHeight: "116px",
    maxWidth: "335px",
    bottom: 20,
    right: 0,
    textAlign: "left",
    "@media (max-width: 500px)": {
      position: "fixed",
      width: "100%",
      maxWidth: "500px",
      bottom: 0,
      margin: "auto",
      "& h2": {
        fontSize: "16px",
        margin: 0,
      },
    },
  },
  buttonOverride: {
    "@media (max-width: 500px)": {
      border: "none",
      outline: "none",
    },
  },
  workshopIcon: {
    marginRight: "15px",
    width: "50px",
    height: "50px",
    "@media (max-width: 500px)": {
      marginRight: "10px",
      width: "30px",
      height: "30px",
    },
  },
  homeNavOptions: {
    marginTop: "40px",
    pointerEvents: "all",
    "@media (max-width: 500px)": {
      marginTop: "15px",
    },
    animation: "$fadeIn 800ms forwards",
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}))

export default styles
