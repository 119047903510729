import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { addAdmin } from "actions/admins"
import { updateAdmin, deleteAdmin } from "actions/admins"
import { connect } from "react-redux"
// **** M-UI IMPORTS ****
import useStyles from "./styles"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import Typography from "@material-ui/core/Typography"
import DeleteIcon from "@material-ui/icons/Delete"
// **** M-UI IMPORTS ****

import { Formik, Form } from "formik"
import * as Yup from "yup"
import { FormControl, FormHelperText } from "@material-ui/core"

const ChandlerForm = (props) => {
  const [openValid, setValid] = React.useState(false)
  const errorPin = "Pin Already Exists"
  var row = props
  if (props.editRow != null) {
    row = props.editRow[0]
  } else {
    row = props
  }
  // Changing value of admin for radio button when props recieved
  if (row && props.editRow != null) {
    if (row.super_admin === 0) {
      row.super_admin = false
    } else {
      row.super_admin = true
    }
  }
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {}, [props.editRow])

  const ChandlerSchema = Yup.object().shape({
    name: Yup.string()
      .required("A name is required")
      .max(40, "The name should be less than 40 characters"),
    super_admin: Yup.boolean().required("Specify if chandler is admin"),
    location: Yup.string().required("Specify chandler location"),
    pin: Yup.string()
      .required("A pin number is required")
      .max(4, "The pin should be 4 digits")
      .min(4, "The pin should be 4 digits"),
  })

  const handleSubmit = (values) => {
    // Action Edit
    // checks for our custom validation, Pin Unique
    if (openValid === false) {
      // Changing value of admin for radio button when dispatching to DB, super_admin 0 : 1
      if (props.editRow && props.editRow.length) {
        if (values.super_admin === false) {
          values.super_admin = 0
        } else {
          values.super_admin = 1
        }
        let id = row.id
        dispatch(updateAdmin(id, values)).then((response) => {
          if (response.status === 201) {
            props.closeModal(true)
          }
        })
      } else {
        // make API call
        dispatch(addAdmin(values)).then(props.closeModal(true))
      }
    }
  }

  const handleDelete = () => {
    if (row.id !== 1) {
      dispatch(deleteAdmin(row.id)).then((response) => {
        props.closeModal(true)
      })
    }
  }

  const onPinHandle = (values) => {
    let inputPin = values.pin
    if (row && props.editRow != null) {
      let arrayPins = props.adminList
      if (inputPin != null) {
        //check for edit Chandler, If Pin is unique from array pins or not
        inputPin = inputPin.toString()
        let uniqueValue = arrayPins.find((element) => element.pin === inputPin)
        if (uniqueValue == null) {
          setValid(false)
        } else {
          setValid(true)
        }
        //check for edit Chandler, If pin is similar for a current user then not to show error. like if user
        // has pin 1234 and he edits 1234 so errors should not prompt up.
        arrayPins.filter((ele) => {
          if (props.editRow.length && inputPin === row.pin) {
            setValid(false)
          }
        })
      }
    } else {
      let selectedPin = values.pin
      let arrayPins = props.adminList
      arrayPins.filter((ele) => {
        //check for Add Chandler If Pin is unique from array pins or not
        ele.pin = Number(ele.pin)
        if (ele.pin === selectedPin) {
          setValid(true)
        }
      })
      //check for Add Chandler If Pin is unique from array all array so set State
      let uniqueValue = arrayPins.find((element) => element.pin === selectedPin)
      if (uniqueValue == null) {
        setValid(false)
      }
    }
  }

  const handleAdminChange = (e, setFieldValue) => {
    const value = e.currentTarget.value
    setFieldValue("super_admin", value === "Yes")
  }

  return (
    <Formik
      validationSchema={ChandlerSchema}
      initialValues={{
        name: row && row.name ? row.name : "",
        pin: row && row.pin ? row.pin : "",
        super_admin: row && row.super_admin ? row.super_admin : "",
        location: row && row.location ? row.location : "",
      }}
      onSubmit={handleSubmit}
    >
      {({ handleChange, values, errors, setFieldValue }) => (
        <Form>
          <Grid
            className={classes.paper}
            container
            direction="column"
            alignItems="center"
            justify="space-between"
          >
            <TextField
              name="name"
              value={values.name}
              onChange={handleChange}
              className={classes.textField}
              fullWidth
              label="Name"
              error={Boolean(errors.name)}
              helperText={errors.name}
            />
            <TextField
              type="number"
              name="pin"
              value={values.pin}
              onChange={handleChange}
              onInput={() => onPinHandle(values)}
              fullWidth
              className={classes.textField}
              label="Pin"
              error={errors ? Boolean(errors.pin) : errorPin}
              helperText={openValid === true ? errorPin : errors.pin}
            />
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <FormControl fullWidth>
                  <Typography variant="caption">Admin</Typography>
                  <RadioGroup
                    value={values.admin}
                    onChange={(e) => handleAdminChange(e, setFieldValue)}
                    aria-label="admin"
                    name="super_admin"
                    row
                  >
                    <FormControlLabel
                      value="Yes"
                      checked={values.super_admin === true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      checked={values.super_admin === false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  <FormHelperText error={Boolean(errors.admin)}>
                    {errors.admin || ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <FormControl fullWidth>
                  <Typography variant="caption">Location</Typography>
                  <RadioGroup
                    value={values.location}
                    onChange={handleChange}
                    aria-label="location"
                    name="location"
                    row
                  >
                    <FormControlLabel
                      value="CHS"
                      control={<Radio />}
                      label="CHS"
                    />
                    <FormControlLabel
                      value="ATL"
                      control={<Radio />}
                      label="ATL"
                    />
                    <FormControlLabel
                      value="BOTH"
                      control={<Radio />}
                      label="Both"
                    />
                  </RadioGroup>
                  <FormHelperText error={Boolean(errors.location)}>
                    {errors.location || ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.manageButtons}>
              <Button className={classes.button} type="submit">
                SAVE
              </Button>
              <Button
                onClick={() => handleDelete()}
                className={classes.deleteButton}
              >
                <div className={classes.deleteContainer}>
                  <DeleteIcon />
                  Delete
                </div>
              </Button>
            </div>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

const mapStateToProps = (state) => {
  return {
    adminList: state.admins.employees,
  }
}
export default connect(mapStateToProps)(ChandlerForm)
