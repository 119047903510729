import React, { useRef, useEffect } from "react"
import useStyles from "./styles"
import { useDispatch, useSelector } from "react-redux"
import { Box, Typography, Button, Tooltip, Avatar } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import BlockIcon from "@material-ui/icons/Block"
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined"
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined"
// import Avatar from "components/Avatar"
import classnames from "clsx"
import { addReaction, userCandles } from "actions/users_candles"
import { saveCandleData } from "actions/candles"
import { openDialog } from "actions/dialogs"
import FragranceNotes from "./FragranceNotes"
import shuffleFragrances from "utils/shuffleFragrances"
import { getFragranceCollection } from "actions/candles"
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder"

const scrollToRef = (ref) => ref.current.scrollTo(0, 0)

const ShortContent = ({ selectedItem, setSelectedItem, closeButton }) => {
  const ShuffleIcon = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/shuffle_icon.svg`
  const classes = useStyles()
  const user = useSelector((state) => state.authentication.authenticatedUser.id)
  const candles = useSelector((state) => state.candles["candles"])
  const reactions = useSelector((state) => state.userCandles)
  const dispatch = useDispatch()
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  useEffect(() => {
    executeScroll()
  }, [selectedItem])

  let liked = false
  let disliked = false
  let curious = false
  let comment = null

  const result = Object.values(reactions.userReactions).filter(
    (x) => x.candle_id === selectedItem
  )
  if (result.length > 0) {
    let reaction = result[0].reaction
    comment = result[0].comment
    if (reaction === "love") {
      liked = true
    }
    if (reaction === "nope") {
      disliked = true
    }
    if (reaction === "curious") {
      curious = true
    }
  }

  const placement = window.innerWidth > 750 ? "right" : "bottom"

  const fragranceNotes = [
    {
      type: "top",
      image: `${process.env.REACT_APP_S3_ASSETS}/assets/panel-expanded-icon.png`,
      name: "Note not found",
      description: "Note not found",
    },
    {
      type: "middle",
      image: `${process.env.REACT_APP_S3_ASSETS}/assets/panel-expanded-icon.png`,
      name: "Note not found",
      description: "Note not found",
    },
    {
      type: "dry",
      image: `${process.env.REACT_APP_S3_ASSETS}/assets/panel-expanded-icon.png`,
      name: "Note not found",
      description: "Note not found",
    },
  ]

  let candleInfo = {
    description: "",
    chandlerNotes: "",
    chandler: "",
    family: "",
    mainUrl: "",
    topNote: "",
    middleNote: "",
    dryNote: "",
    candleImage: null,
  }

  if (candles.length && selectedItem > 0) {
    candleInfo.description = candles[selectedItem - 1].description
    candleInfo.chandlerNotes = candles[selectedItem - 1].chandler_comment
    candleInfo.chandler = candles[selectedItem - 1].chandler
    candleInfo.family = candles[selectedItem - 1].family.name
    candleInfo.mainUrl = candles[selectedItem - 1].image
    candleInfo.topNote = candles[selectedItem - 1].top_note
    candleInfo.middleNote = candles[selectedItem - 1].middle_note
    candleInfo.dryNote = candles[selectedItem - 1].dry_note
    candleInfo.candleImage = <img src={candleInfo.mainUrl} alt={selectedItem} />
  }

  fragranceNotes[0].image =
    candleInfo?.topNote?.image || fragranceNotes[0].image
  fragranceNotes[0].name = candleInfo?.topNote?.name || fragranceNotes[0].name
  fragranceNotes[0].description =
    candleInfo?.topNote?.description || fragranceNotes[0].description
  fragranceNotes[1].image =
    candleInfo?.middleNote?.image || fragranceNotes[1].image
  fragranceNotes[1].name =
    candleInfo?.middleNote?.name || fragranceNotes[1].name
  fragranceNotes[1].description =
    candleInfo?.middleNote?.description || fragranceNotes[1].description
  fragranceNotes[2].image =
    candleInfo?.dryNote?.image || fragranceNotes[2].image
  fragranceNotes[2].name = candleInfo?.dryNote?.name || fragranceNotes[2].name
  fragranceNotes[2].description =
    candleInfo?.dryNote?.description || fragranceNotes[2].description

  let closeBtn = closeButton ? (
    <Button
      onClick={() => setSelectedItem(-1)}
      color="primary"
      className={classnames(classes.actionButton, classes.closeButton)}
    >
      <CloseIcon />
    </Button>
  ) : (
    <Button
      onClick={() => shuffleFragrances()}
      color="primary"
      className={classnames(classes.actionButton, classes.closeButton)}
    >
      <img src={ShuffleIcon} alt="Shuffle Icon" className={classes.icon} />
    </Button>
  )
  const handleOpenAddDialog = () => {
    dispatch(openDialog("addToCart"))
  }

  const openFamilyModal = () => {
    dispatch(saveCandleData("selectedFamily", candleInfo.family))
    dispatch(openDialog("familyModal"))
  }
  const handleReaction = (reaction) => {
    if (!user) {
      dispatch(openDialog("saveProfile"))
    } else {
      let query = {}
      query.user_id = user
      query.candle_id = selectedItem
      query.reaction = reaction
      dispatch(addReaction(query)).then((res) => {
        dispatch(userCandles(user))
      })
    }
  }

  return (
    <Box className={classes.contentBox} display="flex">
      <Box className={classes.mainCandleImage}>
        <Typography variant="h1" className={classes.candleNumber}>
          {selectedItem}
        </Typography>
        {candleInfo.candleImage}
      </Box>
      <Box className={classes.candleContent} ref={myRef}>
        <Box className={classes.aboutFragrance}>
          <div style={{ display: "flex" }}>
            <Typography variant="h3" color="primary">
              About Fragrance {selectedItem}
            </Typography>
            {comment !== null && (
              <Tooltip
                title={comment}
                placement={placement}
                enterDelay={300}
                enterTouchDelay={0}
                disableTouchListener={false}
                leaveTouchDelay={2500}
                interactive
                transitioncomponent="zoom"
              >
                <BookmarkBorderIcon
                  style={{
                    color: "#FD6A4F",
                    paddingLeft: "5px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            )}
          </div>
          <Typography>{candleInfo.description}</Typography>
        </Box>
        <Box className={classes.notesContent}>
          <Typography variant="h3" color="primary">
            Fragrance Notes
            <Button className={classes.familyButton} onClick={openFamilyModal}>
              {candleInfo.family} family
            </Button>
          </Typography>
          <FragranceNotes notes={fragranceNotes} selected={selectedItem} />
        </Box>
        <Box className={classes.chandlerNotes}>
          <Typography variant="h3" color="primary" id="here">
            Chandler Notes
          </Typography>
          <Typography variant="subtitle1" color="secondary">
            {candleInfo.chandlerNotes}
          </Typography>
          <Box className={classes.chandlerInfo}>
            <Avatar
              className={classes.chandlerImage}
              src={candleInfo.chandler?.image}
              alt={candleInfo.chandler.name}
            >
              {candleInfo.chandler?.name ? candleInfo.chandler?.name[0] : ""}
            </Avatar>
            <Box>
              <Typography variant="h3" color="primary">
                {candleInfo.chandler.name}
              </Typography>
              <Typography variant="overline" color="secondary">
                Candlefish Chandler
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* chandlerNotes */}
      </Box>
      {/* candleContent */}
      <Box className={classes.buttonsBox}>
        {closeBtn}
        <Button
          color="primary"
          onClick={() => handleReaction("love")}
          className={classnames({
            [classes.actionButton]: true,
            [classes.liked]: liked,
          })}
        >
          <FavoriteBorderIcon
            className={classnames({
              [classes.fav]: liked,
            })}
          />
          Love
        </Button>
        <Button
          color="primary"
          onClick={() => handleReaction("nope")}
          className={classnames({
            [classes.actionButton]: true,
            [classes.disliked]: disliked,
          })}
        >
          <BlockIcon
            className={classnames({
              [classes.nope]: disliked,
            })}
          />
          Nope
        </Button>
        <Button
          color="primary"
          onClick={() => handleReaction("curious")}
          className={classnames({
            [classes.actionButton]: true,
            [classes.curious]: curious,
          })}
        >
          <VisibilityOutlinedIcon />
          Curious
        </Button>
        <Button
          color="primary"
          onClick={handleOpenAddDialog}
          className={classnames(classes.actionButton, classes.addButton)}
        >
          <LocalMallOutlinedIcon />
          Add
        </Button>
      </Box>
      {/* buttonsBox */}
    </Box>
  )
}

export default ShortContent
