import { SIDENAV_OPEN, SIDENAV_CLOSE } from "types/actions/side-nav"

export const openSideNav = (data) => ({
  type: SIDENAV_OPEN,
  open: true,
})

export const closeSideNav = (data) => ({
  type: SIDENAV_CLOSE,
  open: false,
})
