import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  discoverPage: {
    height: "100%",
    width: "100%",
    "@media (max-width: 500px)": {
      height: "100%",
    },
  },
  letter: {
    fontSize: "170px",
    fontWeight: "bold",
    opacity: ".1",
    color: theme.palette.secondary.main,
  },
}))

export default styles
