import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  panel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    color: "white",
    transition: "all 150ms ease-in-out",
    "&:hover": {
      flexGrow: 1.8,
      "& $panelLabel": {
        fontSize: "74px",
        letterSpacing: "16.28px",
        marginRight: "-16.28px",
        transition: "all 500ms ease-in-out",
        "@media (max-width: 500px)": {
          fontSize: "34px",
          letterSpacing: "5.27px",
          marginRight: "-5.27px",
        },
      },
    },
    "@media (max-width: 500px)": {
      marginBottom: "5px",
      maxHeight: "33%",
    },
  },
  mobileOpen: {
    maxHeight: "66%",
  },
  panelLabel: {
    fontSize: "34px",
    textAlign: "center",
    letterSpacing: "5.27px",
    marginRight: "-5.27px",
  },
  description: {
    animation: "$fadeIn 800ms forwards",
  },
  panelIcon: {
    width: "74px",
    marginBottom: "40px",
    opacity: 0,
    animation: "$fadeIn 800ms forwards",
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  shopPanelIcon: {
    width: "74px",
    marginTop: "80px",
    marginBottom: "40px",
    opacity: 0,
    animation: "$fadeIn 800ms forwards",
  },
  panelContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
  },
  header: {
    lineSpacing: "5.27px",
  },
}))

export default styles
