import React from "react"
import useStyles from "./styles"
import { Fab, Typography } from "@material-ui/core"
import { useSelector } from "react-redux"

import classnames from "clsx"

const FormSelector = ({ children, active, handleNewActiveForm }) => {
  const classes = useStyles()

  const formValues = useSelector((state) => state.forms)

  return (
    <div className={classes.stepSelector}>
      <Fab
        color="primary"
        className={classnames(
          classes.step,
          { [classes.active]: active === 1 },
          { [classes.completed]: formValues.completedOne },
        )}
        onClick={() => handleNewActiveForm(1)}
      >
        <Typography variant="body1" className="stepText">
          1
        </Typography>
      </Fab>
      <Fab
        color="primary"
        className={classnames(
          classes.step,
          { [classes.active]: active === 2 },
          { [classes.completed]: formValues.completedTwo },
        )}
        onClick={() => handleNewActiveForm(2)}
      >
        <Typography variant="body1" className="stepText">
          2
        </Typography>
      </Fab>
      <Fab
        color="primary"
        className={classnames(
          classes.step,
          { [classes.active]: active === 3 },
          { [classes.completed]: formValues.completedThree },
        )}
        onClick={() => handleNewActiveForm(3)}
      >
        <Typography variant="body1" className="stepText">
          3
        </Typography>
      </Fab>
      <Fab
        color="primary"
        className={classnames(
          classes.step,
          { [classes.active]: active === 4 },
          { [classes.completed]: formValues.completedFour },
        )}
        onClick={() => handleNewActiveForm(4)}
      >
        <Typography variant="body1" className="stepText">
          4
        </Typography>
      </Fab>
      <Fab
        color="primary"
        className={classnames(
          classes.step,
          { [classes.active]: active === 5 },
          { [classes.completed]: formValues.completedFive },
        )}
        onClick={() => handleNewActiveForm(5)}
      >
        <Typography variant="body1" className="stepText">
          5
        </Typography>
      </Fab>
      <Fab
        color="primary"
        className={classnames(
          classes.step,
          { [classes.active]: active === 6 },
          { [classes.completed]: formValues.completedSix },
        )}
        onClick={() => handleNewActiveForm(6)}
      >
        <Typography variant="body1" className="stepText">
          6
        </Typography>
      </Fab>
    </div>
  )
}

export default FormSelector
