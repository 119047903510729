import React, { useEffect } from "react"
import PubNub from "pubnub"
import { useSelector, useDispatch } from "react-redux"
import { userCandles } from "actions/users_candles"

const pubnubActions = ["create", "update", "delete"]

const LibraryCardPubnub = ({ userId }) => {
  const { token: userToken } = useSelector((state) => state.authentication)
  const dispatch = useDispatch()
  useEffect(() => {
    const pubnub = new PubNub({
      subscribeKey: process.env.REACT_APP_PUBNUB_SUB_KEY,
    })
    pubnub.subscribe({
      channels: [`library-card-${userId}`],
    })
    pubnub.addListener({
      message: (msg) => {
        try {
          const { message } = msg
          const { action, data, token } = message
          if (token !== userToken) {
            if (pubnubActions.includes(action)) {
              dispatch(userCandles(data.user_id))
            }
          }
        } catch (err) {
          console.log(err)
        }
      },
    })
  }, [])

  return null
}

export default LibraryCardPubnub
