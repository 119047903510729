import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  formsSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
    overflow: "scroll",
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
    margin: "20px 10px 0px 10px",
    "@media (max-width: 900px)": {
      height: "auto",
      flexDirection: "column",
      margin: "0px 0px 0px 0px",
    },
  },
  formContainer2: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
    margin: "20px 10px 20px 10px",
    "@media (max-width: 900px)": {
      height: "auto",
      flexDirection: "column",
      margin: "0px 0px 0px 0px",
    },
  },
  slider: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    margin: "10px 10px 10px 0px",
    background: "#FCFAFA",
    "@media (max-width: 500px)": {
      padding: "10px 0px 30px 0px",
      margin: "0px 0px 20px 0px",
      width: "95%",
    },
  },
  slider2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    margin: "10px 0px 10px 10px",
    background: "#FCFAFA",
    "@media (max-width: 500px)": {
      padding: "10px 0px 30px 0px",
      margin: "0px 0px 20px 0px",
      width: "95%",
    },
  },
  scentSelector: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    background: "#FCFAFA",
    "@media (max-width: 500px)": {
      padding: "10px 0px 30px 0px",
      margin: "0px 0px 20px 0px",
      width: "95%",
    },
  },
  bottomUpdateButton: {
    background: "black",
    color: "white",
    width: "100%",
    height: "100%",
    maxWidth: "150px",
    maxHeight: "100px",
    margin: "20px 0px",
  },
}))

export default styles
