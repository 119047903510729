import React from "react"
import useStyles from "./styles"
import Note from "./Note"
import { Box } from "@material-ui/core"
import StyledScrollbar from "components/StyledScrollbar"

const NotesList = ({ notes }) => {
  const classes = useStyles()

  return (
    <StyledScrollbar style={{ height: 130 }}>
      <Box className={classes.notesList}>
        {notes.map((note, i) => (
          <Note key={i} note={note} />
        ))}
      </Box>
    </StyledScrollbar>
  )
}

export default NotesList
