import React, { Fragment } from "react"
import useStyles from "./styles"
import { useFormik } from "formik"
import Router from "router"
import LibraryCard from "components/LibraryCard"
import ShortContent from "components/LibraryCard/ShortContent"
import PageLogo from "components/PageLogo"
import PageHeader from "components/PageHeader"
import Notes from "components/Notes"
import Modal from "@material-ui/core/Modal"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { getNotesCandles } from "actions/users_candles"
import LibraryCardPubnub from "components/LibraryCardPubnub/index"
import KioskPubnub from "components/KioskPubnub/index"
import { useSelector, useDispatch } from "react-redux"
import { updateUser } from "actions/users"
import LibraryLegend from "components/LibraryLegend"

const LibraryCardRoute = ({ routes = [], ...rest }) => {
  //Update the document title using the browser A
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.authentication.authenticatedUser)
  const token = useSelector((state) => state.authentication.token)
  const kiosk = useSelector((state) => state.admins.kiosk)
  const selectedItem = useSelector((state) => state.candles["selectedCandle"])
  //  *** NOTES MODAL STATE & HANDLERS ***
  const [openModal, setOpenModal] = React.useState(false)
  const [notes, getNotes] = React.useState(false)
  const [reaction, setReaction] = React.useState(false)

  const handleOpenModal = () => {
    /* pass current state at time of current call, rather than state stored
      in component variable ( prevents race condition ) */
    setOpenModal((openModal) => !openModal)
  }
  const getterNotes = (data) => {
    getNotes(data)
  }
  const getReaction = async (reaction) => {
    await setReaction(reaction)
  }
  const handleCloseModal = () => {
    setOpenModal((openModal) => !openModal)
  }
  const handleonClick = () => {
    // let query = {}

    //// TO BE DISCUSSED ABOUT COMMENTS SECTIONS \\\\

    // query.user_id = user
    // query.candle_id = selectedItem + 1
    // query.comment = notes
    // query.reaction = reaction
    // if (query.reaction !== false && query.comment !== false && query.candle_id !== null) {
    //   dispatch(postNotes(query)).then(res => {
    //     // dispatch(userCandles())
    //     dispatch(getNotesCandles())
    //   })
    //   handleOpenModal()
    // } else {
    // dispatch(userCandles())
    dispatch(getNotesCandles(user.id))
    handleOpenModal()
  }
  // *** NOTES MODAL STATE & HANDLERS ***

  const formik = useFormik({
    initialValues: {
      name: user.name,
    },
    onSubmit: (values) => {
      let data = user
      data.name = values.name
      dispatch(updateUser(data, token))
    },
  })

  return (
    <Fragment>
      <PageHeader kiosk={kiosk} />
      <PageLogo />
      <div className={classes.nameForm}>
        <Typography variant="h4" style={{ marginBottom: "15px" }}>
          Please name your library card
        </Typography>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <TextField
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleSubmit}
            id="standard-basic"
            label="First Name"
            className={classes.textField}
            classes={{
              root: classes.textRoot,
            }}
          />
        </form>
      </div>
      <div className={classes.legend}>
        <LibraryLegend />
      </div>
      <div className={classes.libraryCard}>
        <LibraryCard>
          {({ selectedItem }) => (
            <ShortContent
              selectedItem={selectedItem + 1}
              getReaction={getReaction}
              getNotes={getterNotes}
            />
          )}
        </LibraryCard>
      </div>
      <Grid container justify="center">
        <Grid item xs={4}>
          <Button className={classes.button} fullWidth onClick={handleonClick}>
            REVIEW
          </Button>
        </Grid>
      </Grid>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Grid className={classes.paper} container direction="column">
          <Notes handleCloseModal={handleCloseModal} />
        </Grid>
      </Modal>
      <Router routes={routes} {...rest} />
      <LibraryCardPubnub userId={user.id} />
      <KioskPubnub />
    </Fragment>
  )
}

export default LibraryCardRoute
