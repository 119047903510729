import API from "store/api"
import { LINKS_SET_DATA } from "types/actions/links"

export const saveLinksData = (dataName, data) => ({
  type: LINKS_SET_DATA,
  dataName,
  data,
})

export const getLinks = () => {
  return (dispatch) => {
    return API.get("/links")
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.body)
      .then((result) => {
        dispatch(saveLinksData("links", result.data))
      })
      .catch((err) => {
        console.error(err)
      })
  }
}
