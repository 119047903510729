import React from "react"
import { IconButton } from "@material-ui/core"
import { useFormikContext } from "formik"
import { useDispatch } from "react-redux"
import { closeQuickEdit } from "actions/quick-edit"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import { saveFormData } from "actions/forms"
import getFragrances from "utils/getFragrances"

import useStyles from "./styles"

const OptionsSection = ({ number }) => {
  const classes = useStyles()

  const UndoIcon = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/undo-icon.svg`
  const CancelIcon = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/cancel-icon.svg`

  const dispatch = useDispatch()

  const { values, handleReset } = useFormikContext()

  const handleUpdate = () => {
    dispatch(saveFormData("form2", values.form2))
    dispatch(saveFormData("form3", values.form3))
    dispatch(saveFormData("form4", values.form4))
    dispatch(saveFormData("form5", values.form5))
    dispatch(saveFormData("form6", values.form6))
    getFragrances()
    window.scrollTo(0, 0)
    dispatch(closeQuickEdit())
  }

  const handleCancel = () => {
    dispatch(closeQuickEdit())
    handleReset()
  }

  const updateButton =
    window.innerWidth < 735 ? (
      <div className={classes.updateContainer} onClick={() => handleUpdate()}>
        <IconButton classes={{ root: classes.updateButton }}>
          <KeyboardArrowUpIcon classes={{ root: classes.updateIcon }} />
        </IconButton>
        <span className={classes.updateText}>UPDATE</span>
      </div>
    ) : (
      <div className={classes.updateContainer} onClick={() => handleUpdate()}>
        <IconButton classes={{ root: classes.updateButton }}>
          <KeyboardArrowDownIcon classes={{ root: classes.updateIcon }} />
        </IconButton>
        <span className={classes.updateText}>UPDATE</span>
      </div>
    )

  return (
    <div className={classes.optionsContainer}>
      {updateButton}
      <div
        className={classes.iconButtonContainer}
        onClick={() => handleCancel()}
      >
        <div className={classes.iconButton}>
          <img src={CancelIcon} alt="Cancel Icon" className={classes.icon} />
        </div>
        <span className={classes.optionText}>CANCEL</span>
      </div>
      <div
        className={classes.iconButtonContainer}
        onClick={() => handleReset()}
      >
        <div className={classes.iconButton}>
          <img src={UndoIcon} alt="Undo Icon" className={classes.icon} />
        </div>
        <span className={classes.optionText}>RESET</span>
      </div>
    </div>
  )
}

export default OptionsSection
