import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  footer: {
    position: "absolute",
    bottom: 0,
    height: "56px",
  },
  logoContainer: {
    textAlign: "center",
    marginTop: "20px",
  },
  button: {
    margin: "0 31px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  paper: {
    outline: "none",
    position: "fixed",
    top: "50%",
    left: "50%",
    width: "100%",
    transform: "translate(-50%, -50%)", // helps with centering
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "&:focus": {
      outline: "none",
    },
  },
}))

export default styles
