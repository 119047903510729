import React from "react"
import useStyles from "./styles"
import { useSelector, useDispatch } from "react-redux"
import { saveCandleData } from "actions/candles"
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed"

const Filter = ({ filter, setFilter }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const filters = useSelector((state) =>
    Array.from(new Set(state.candles["candles"].map((x) => x.family.name))),
  )

  const scrollToRow = () => {
    const node = document.getElementById("index0")
    smoothScrollIntoView(node, {
      block: "start",
    })
  }

  const handleButtonClick = (e) => {
    if (e.target.value.length > 0) {
      setFilter(e.target.value)
      setTimeout(scrollToRow, 300)
    } else {
      setFilter(e.target.value)
      dispatch(saveCandleData("selectedCandle", -1))
    }
  }
  return (
    <>
      <Box className={classes.filter} display="flex">
        <Box className={classes.spacer} />
        <Box className={classes.textArea}>
          <Typography variant="h2" color="primary" className={classes.title}>
            We have 100 one-of-a-kind fragrances in our library
          </Typography>
          <Typography
            variant="h4"
            color="secondary"
            className={classes.subtitle}
          >
            Choose a number to learn more
          </Typography>
        </Box>
        <Box
          className={classes.selectArea}
          display="flex"
          justifyContent="flex-end"
        >
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              className={classes.select}
              displayEmpty
              value={filter}
              onChange={handleButtonClick}
              label="Age"
            >
              <MenuItem value="">Filter by fragrance</MenuItem>
              {filters.map((x) => {
                return (
                  <MenuItem key={x} value={x}>
                    {x}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box textAlign="center" className={classes.arrowBox}>
        <ExpandMoreIcon color="secondary" style={{ fontSize: 40 }} />
      </Box>
    </>
  )
}

export default Filter
