import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  filter: {
    width: "90%",
    maxWidth: 1266,
    margin: "auto",
    marginBottom: theme.spacing(1),
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  textArea: {
    flexBasis: "50%",
    textAlign: "center",
  },
  title: {
    fontSize: "1.333rem",
    marginBottom: theme.spacing(1),
    letterSpacing: "1.9px",
  },
  subtitle: {
    marginBottom: theme.spacing(3),
  },
  spacer: {
    flexBasis: "25%",
  },
  selectArea: {
    flexBasis: "25%",
    "@media (max-width: 768px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
  select: {
    minWidth: 200,
    color: theme.palette.primary.main,
  },
  arrowBox: {
    marginBottom: theme.spacing(1),
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
}))

export default styles
