import React, { Fragment } from "react"
import useStyles from "./styles"
import Router from "router"
import LibraryCard from "components/LibraryCard"
import LongContent from "components/LibraryCard/LongContent"
import Footer from "components/Footer"

const LibraryCardRoute = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const Logo = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/cf-library-logo.svg`

  return (
    <Fragment>
      <img src={Logo} className={classes.logo} />
      <div className={classes.libraryCard}>
        <LibraryCard>
          {({ selectedItem, setSelectedItem }) => (
            <LongContent
              selectedItem={selectedItem + 1}
              setSelectedItem={setSelectedItem}
              closeButton={false}
            />
          )}
        </LibraryCard>
      </div>
      <Footer show />
      <Router routes={routes} {...rest} />
    </Fragment>
  )
}

export default LibraryCardRoute
