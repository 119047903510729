import React from "react"
import useStyles from "./styles"
import { Typography, Slider, Button } from "@material-ui/core"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { saveFormData } from "actions/forms"

const Form3 = ({ children, handleNewActiveForm }) => {
  const classes = useStyles()

  const initialValues = useSelector((state) => state.forms["form3"])

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      dispatch(saveFormData("completedThree", true))
      dispatch(saveFormData("form3", values))
      window.scrollTo(0, 0)
      handleNewActiveForm(4)
    },
  })

  const onPreviousClicked = () => {
    dispatch(saveFormData("completedThree", true))
    dispatch(saveFormData("form3", formik.values))
    window.scrollTo(0, 0)
    handleNewActiveForm(2)
  }

  const onChange = (e, value) => {
    formik.setFieldValue("strength", value)
  }

  const marks = [
    {
      value: -2,
      label: "Subtle",
    },
    {
      value: 0,
      label: "Neutral",
    },
    {
      value: 2,
      label: "Dramatic",
    },
  ]

  return (
    <div className={classes.formContainer}>
      <Typography variant="h1" className={classes.discoverHeading}>
        WOULD YOU LIKE A SUBTLE OR DRAMATIC FRAGRANCE?
      </Typography>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Slider
          min={-2}
          max={2}
          aria-labelledby="discrete-slider-always"
          step={0.05}
          marks={marks}
          className={classes.slider}
          value={formik.values.strength}
          name="strength"
          id="strength"
          onChange={onChange}
        />
        <div className={classes.labelDescriptions}>
          <Typography variant="body1" className={classes.labelDescriptionLeft}>
            Subtle and not <br /> overpowering a room
          </Typography>
          <Typography variant="body1" className={classes.labelDescriptionRight}>
            Obvious and <br /> encompasses a room
          </Typography>
        </div>
        <div className={classes.btnContainer}>
          <Button
            variant="outlined"
            onClick={onPreviousClicked}
            className={classes.formButton}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            type="submit"
            className={classes.formButton}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Form3
