import {
  SET_USER_CANDLES_DATA,
  SET_CANDLES_REACTION,
} from "types/actions/usersCandles"

const usersCandleState = {
  notes: [],
  userReactions: [],
}

export default (state = usersCandleState, action) => {
  switch (action.type) {
    case SET_USER_CANDLES_DATA:
      return {
        ...state,
        notes: action.data,
      }
    case SET_CANDLES_REACTION:
      return {
        ...state,
        userReactions: action.data,
      }
    default:
      return state
  }
}
