import { makeStyles } from "@material-ui/core/styles"
const background = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/LibraryPopoverBackground2.png`

const styles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "white",
    width: "100%",
    height: "100%",
    maxHeight: "300px",
    backgroundImage: `linear-gradient(
        rgba(255, 69, 0, .8),
        rgba(255, 69, 0, .8)),
        url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    border: "none",
    boxShadow: "none",
    padding: "0px 60px 0px 60px",
    "&:focus": {
      outline: "none",
    },
  },
  acceptButton: {
    background: "black",
    color: "white",
    whiteSpace: "nowrap",
    marginTop: "40px",
    padding: "10px",
    "&:hover": {
      background: "black",
    },
    "@media (max-width: 500px)": {
      width: "65%",
      padding: "10px",
    },
  },
}))

export default styles
