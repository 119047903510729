import API from "store/api"
import { SET_BANNER } from "types/actions/settings"

export const setBanner = (data) => ({
  type: SET_BANNER,
  data,
})

export const getBannerData = () => {
  return async (dispatch) => {
    try {
      const response = (await API.get("/admins/banner")).body.data
      const {
        show_banner: showBanner = 0,
        banner_content: bannerContent = "",
      } = response
      const data = { showBanner, bannerContent }

      dispatch(setBanner(data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const updateBannerHeight = (height) => {
  return (dispatch, getState) => {
    dispatch(setBanner({ ...getState().settings.banner, height: height }))
  }
}
