import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  searchBarOpen: {},
  searchBar: {
    marginTop: "15px",
    backgroundColor: "",
    height: "75px",
    width: "75px !important",
    color: "white",
    padding: "20px",
    position: "absolute",
    top: 0,
    right: 0,
    transition: "width .3s ease-in-out, background-color .3s ease-in-out",
    overflow: "hidden", // prevent temp X axis scrollbar
    "&$searchBarOpen": {
      width: "325px !important",
      backgroundColor: "#FD6A4F",
      "& $searchIcon": {
        color: "white",
      },
      "& $textField": {
        display: "flex",
        color: "white",
      },
      "& $arrowIcon": {
        display: "flex",
      },
    },
  },
  searchIcon: {
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
    color: theme.palette.primary.main,
  },
  arrowIcon: {
    margin: "5px 10px",
    display: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  textField: {
    width: "90%",
    display: "none",
  },
  textRoot: {
    "& > label": {
      paddingLeft: "5px",
    },
    "& $label + .MuiInput-formControl": {
      paddingLeft: "5px",
    },
    "& .MuiInput-root": {
      color: "white",
    },
    "& .MuiInputBase-root": {
      color: "white",
    },
    "& .MuiInput-underline": {
      "&:not(.Mui-disabled)": {
        "&:before": {
          borderBottom: "1px solid white",
        },
        "&:after": {
          borderBottom: "2px solid white",
        },
      },
      "&:hover": {
        "&:not(.Mui-disabled)": {
          "&:before": {
            borderBottom: "2px solid white",
          },
        },
      },
    },
    "& .MuiFormLabel-root": {
      color: "white",
      fontWeight: 500,
      letterSpacing: "3.4px",
      whiteSpace: "nowrap",
    },
  },
  input: {
    color: "white",
    fontWeight: 600,
    letterSpacing: "2px",
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
  },
}))

export default styles
