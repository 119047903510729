import { SET_BANNER } from "types/actions/settings"

const settingsInitialState = {
  banner: {},
}

export default (state = settingsInitialState, action) => {
  switch (action.type) {
    case SET_BANNER:
      return {
        ...state,
        banner: action.data,
      }
    default:
      return state
  }
}
