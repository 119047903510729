import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  optionContainer: {
    width: "100%",
    pointerEvents: "all",
    "@media (min-width: 768px)": {
      "&:hover": {
        "& $chevIconHidden": {
          opacity: 1,
        },
        "& $optionsBar": {
          width: "117%",
        },
        "& $option": {
          transform: "translate(15px)",
        },
      },
    },
  },
  option: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "colummn",
    alignItems: "center",
    transition: "transform 500ms ease-in-out",
    "&:hover": {
      cursor: "pointer",
    },
  },
  chevIconHidden: {
    position: "absolute",
    right: "-22px",
    opacity: 0,
    transition: "opacity 500ms ease-in-out",
  },
  optionsBar: {
    display: "flex",
    height: "3px",
    margin: "5px 0px",
    backgroundColor: "white",
    width: "100%",
    transition: "width 500ms ease-in-out",
  },
}))

export default styles
