import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  track: {
    backgroundColor: [theme.palette.grey[100], "!important"],
  },
  thumb: {
    backgroundColor: [theme.palette.grey[200], "!important"],
  },
}))

export default styles
