import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  SET_DIALOG_DATA,
} from "types/actions/dialogs"
import update from "immutability-helper"

const dialogsInitialState = {
  familyModal: true,
}

export default (state = dialogsInitialState, action) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return update(state, {
        [action.name]: {
          $set: {
            open: true,
            data: action.data,
          },
        },
      })
    case CLOSE_DIALOG:
      return update(state, {
        [action.name]: {
          $set: {
            open: false,
            data: {},
          },
        },
      })
    case SET_DIALOG_DATA:
      return update(state, {
        [action.name]: {
          data: {
            $set: action.data,
          },
        },
      })
    default:
      return state
  }
}
