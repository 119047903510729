import React from "react"
import { Drawer, Typography } from "@material-ui/core"
import { Formik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { closeQuickEdit } from "actions/quick-edit"
import FragranceBubble from "components/YourFragrances/FragranceBubble"
import FormsSection from "components/QuickEdit/FormsSection"
import OptionsSection from "components/QuickEdit/OptionsSection"
import useStyles from "./styles"

const QuickEdit = ({ children }) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const open = useSelector((state) => state.quickEdit?.open || false)

  const initialValues = useSelector((state) => state.forms)
  const fragrances = useSelector((state) => state.candles.fragrances)

  const handleClose = () => {
    dispatch(closeQuickEdit())
  }

  let description = (
    <Typography variant="h2" className={classes.description}>
      ADJUST <br /> PREFERENCES
    </Typography>
  )

  let anchor = "bottom"
  if (window.innerWidth < 735) {
    anchor = "top"
  }
  if (window.innerWidth > 735) {
    description = null
  }

  return (
    <Drawer
      open={open}
      anchor={anchor}
      onClose={handleClose}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            actions.setSubmitting(false)
          }, 1000)
        }}
      >
        <div className={classes.drawerContainer}>
          <div className={classes.selections}>
            <FragranceBubble
              number={fragrances[0]}
              selected
              className={classes.bubble}
            />
            <FragranceBubble
              number={fragrances[1]}
              selected
              className={classes.bubble}
            />
            <FragranceBubble
              number={fragrances[2]}
              selected
              className={classes.bubble}
            />
          </div>
          <div className={classes.formsContainer}>
            <FormsSection />
          </div>
          <div className={classes.buttonsContainer}>
            {description}
            <OptionsSection />
          </div>
        </div>
      </Formik>
    </Drawer>
  )
}

export default QuickEdit
