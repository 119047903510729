import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    maxWidth: "600px !important",
    background: "red",
  },
  close: {
    position: "absolute",
    top: 0,
    right: 0,
    color: theme.palette.secondary.main,
  },
}))

export default styles
