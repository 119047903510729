import React from "react"
import styles from "./styles"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { compose } from "recompose"
import { Fab } from "@material-ui/core"
import classnames from "clsx"
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed"

class Button extends React.Component {
  shouldComponentUpdate(props) {
    const willChange = ["active", "liked", "disliked", "curious"]
    const statusChanged =
      willChange.filter((x) => this.props[x] !== props[x]).length > 0
    const numberChanged = props.number !== this.props.number

    const reactionsChanged = this.props.userCandles !== props.userCandles

    return statusChanged || numberChanged || reactionsChanged
  }

  render() {
    const { children, onClick, active, number, family, classes } = this.props

    const scrollToRow = () => {
      if (family) {
        const node = document.getElementById("index0")
        smoothScrollIntoView(node, {
          block: "start",
        })
      } else if (!active) {
        const denom = window.innerWidth > 735 ? 10 : 5
        const num = Math.floor(number / denom)
        const id = "index" + num
        const node = document.getElementById(id)
        smoothScrollIntoView(node, {
          block: "start",
        })
      }
    }

    let liked = false
    let disliked = false
    let curious = false

    const handleButtonClick = () => {
      onClick()
      setTimeout(scrollToRow, 300)
    }
    const result = Object.values(this.props.userCandles.userReactions).filter(
      (x) => x.candle_id === this.props.number + 1,
    )
    if (result.length === 1) {
      let reaction = result[0].reaction
      if (reaction === "love") {
        liked = true
      }
      if (reaction === "nope") {
        disliked = true
      }
      if (reaction === "curious") {
        curious = true
      }
    }

    return (
      <Fab
        onClick={handleButtonClick}
        color="primary"
        classes={{
          primary: classes.primary,
        }}
        className={classnames({
          [classes.liked]: liked,
          [classes.disliked]: disliked,
          [classes.curious]: curious,
          [classes.active]: active,
        })}
      >
        {children}
      </Fab>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userCandles: state.userCandles,
  }
}

export default compose(connect(mapStateToProps), withStyles(styles))(Button)
