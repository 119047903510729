import React from "react"
import useStyles from "./styles"
import { Typography, Slider } from "@material-ui/core"
import { Form, useFormikContext } from "formik"

const SimpleForm5 = ({ children }) => {
  const classes = useStyles()

  const { values, setFieldValue } = useFormikContext()

  const onSliderChange = (value, name) => {
    setFieldValue(name, value)
  }

  const simpleMarks = [
    {
      value: -2,
      label: "Simple",
    },
    {
      value: 2,
      label: "Complex",
    },
  ]
  const sweetMarks = [
    {
      value: -2,
      label: "Dry",
    },
    {
      value: 2,
      label: "Sweet",
    },
  ]
  const feminineMarks = [
    {
      value: -2,
      label: "Feminine",
    },
    {
      value: 2,
      label: "Masculine",
    },
  ]

  return (
    <div className={classes.formContainer}>
      <Typography variant="h4">Scent preference</Typography>
      <Form className={classes.form}>
        <div className={classes.sliderContainer}>
          {/* <Typography variant="h4">Simple</Typography> */}
          <Slider
            min={-2}
            max={2}
            aria-labelledby="discrete-slider-always"
            step={0.05}
            marks={simpleMarks}
            className={classes.slider}
            classes={{
              markLabel: classes.markLabel,
            }}
            value={values.form5.simple}
            name="simple"
            id="simple"
            onChange={(e, value) => onSliderChange(value, "form5.simple")}
          />
          {/* <Typography variant="h4">Complex</Typography> */}
        </div>
        <div className={classes.sliderContainer}>
          {/* <Typography variant="h4">Dry</Typography> */}
          <Slider
            min={-2}
            max={2}
            aria-labelledby="discrete-slider-always"
            step={0.05}
            marks={sweetMarks}
            className={classes.slider}
            classes={{
              markLabel: classes.markLabel,
            }}
            value={values.form5.dry}
            name="dry"
            id="dry"
            onChange={(e, value) => onSliderChange(value, "form5.dry")}
          />
          {/* <Typography variant="h4">Sweet</Typography> */}
        </div>
        <div className={classes.sliderContainer}>
          {/* <Typography variant="h4">Feminine</Typography> */}
          <Slider
            min={-2}
            max={2}
            aria-labelledby="discrete-slider-always"
            step={0.05}
            marks={feminineMarks}
            className={classes.slider}
            classes={{
              markLabel: classes.markLabel,
            }}
            value={values.form5.feminine}
            name="feminine"
            id="feminine"
            onChange={(e, value) => onSliderChange(value, "form5.feminine")}
          />
          {/* <Typography variant="h4">Masculine</Typography> */}
        </div>
      </Form>
    </div>
  )
}

export default SimpleForm5
