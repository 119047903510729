import store from "store"

const preloadImages = () => {
  const { candles } = store.getState().candles
  var images = []
  images[0] = new Image()
  images[0].src = `${process.env.REACT_APP_S3_ASSETS}/assets/panel-expanded-icon.png`
  images[1] = new Image()
  images[1].src = `${process.env.REACT_APP_S3_ASSETS}/assets/side-nav-background.png`
  images[2] = new Image()
  images[2].src = `${process.env.REACT_APP_S3_ASSETS}/assets/library-popover-background.png`
  images[3] = new Image()
  images[3].src = `${process.env.REACT_APP_S3_ASSETS}/assets/discover-home.jpg`
  images[4] = new Image()
  images[4].src = `${process.env.REACT_APP_S3_ASSETS}/assets/shop-home.jpg`
  images[5] = new Image()
  images[5].src = `${process.env.REACT_APP_S3_ASSETS}/assets/library-home.jpg`
  candles.forEach((x, i) => {
    images[i + 6] = new Image()
    images[i + 6].src = x.image
  })
}

export default preloadImages
