import API from "store/api"
import {
  SET_USER_CANDLES_DATA,
  SET_CANDLES_REACTION,
} from "types/actions/usersCandles"

export const setUserCandleReaction = (data) => ({
  type: SET_CANDLES_REACTION,
  data,
})
export const setUserCandles = (data) => ({
  type: SET_USER_CANDLES_DATA,
  data,
})
export const userCandles = (id) => {
  return (dispatch) => {
    return API.get(`/users_candles/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.body)
      .then(async (result) => {
        dispatch(setUserCandleReaction(result.data))
        return result
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export const getNotesCandles = (id) => {
  return (dispatch) => {
    return API.get(`/users_candles/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.body)
      .then((result) => {
        dispatch(setUserCandles(result.data))
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export const addReaction = (body) => {
  return (dispatch) => {
    return API.post("/users_candles/add_reaction/")
      .send(body)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.body)
      .then(async (result) => {
        // dispatch(setUserCandleReaction(result.data))
        return result
      })
      .catch((err) => {
        console.error(err)
      })
  }
}
