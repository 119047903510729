import React from "react"
import { withStyles } from "@material-ui/core/styles"
import styles from "./styles"
import MaskedInput from "react-text-mask"

const MaskedMobileInput = ({ classes, inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
      showMask={false}
      keepCharPositions
    />
  )
}

export default withStyles(styles)(MaskedMobileInput)
