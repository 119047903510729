import React from "react"
import { Switch, Redirect } from "react-router-dom"
import Route from "components/Route"
import NotFound from "pages/NotFound"
import KitchenSink from "components/KitchenSink"

const PageComponents = {
  KitchenSink,
}
const componentsReq = require.context("./pages", true, /^(.*\.(jsx$))[^.]*$/im)
componentsReq
  .keys()
  .filter((x) => x.indexOf("index.jsx") > -1)
  .forEach((x) => {
    // https://regexr.com/54uqs - selects ./ and /pages/ from file locations
    // https://regexr.com/54uqv - selects index.jsx files
    const componentName = x
      .replace(/\/(\w|:|-)+\.jsx$/g, "")
      .replace(/(\/pages\/|\.\/)/g, "")
    PageComponents[componentName] = componentsReq(x).default
  })

export const routeConfig = [
  {
    path: "/",
    exact: true,
    component: "Index",
  },
  {
    path: "/login",
    component: "Login",
    protected: false,
  },
  {
    path: "/kiosk",
    component: "Kiosk",
    protected: false,
    exact: true,
  },
  {
    path: "/kiosk/queue",
    component: "Queue",
    protected: false,
  },
  {
    path: "/kiosk/analytics",
    component: "KioskAnalytics",
    protected: true,
  },
  {
    path: "/discover",
    component: "Discover",
    protected: false,
    routes: [
      {
        path: "/discover",
        component: "DiscoverIndex",
        protected: false,
        exact: true,
      },
      {
        path: "/discover/library-card",
        component: "DiscoverLibraryCard",
        protected: false,
      },
      {
        path: "/discover/your-fragrance",
        component: "DiscoverYourFragrance",
        protected: false,
      },
    ],
  },
  {
    path: "/library-card",
    component: "LibraryCard",
    protected: false,
  },
  {
    path: "/kitchen-sink",
    component: "KitchenSink",
    protected: false,
  },
  {
    path: "/library",
    component: "Library",
    protected: false,
  },
  {
    path: "/start/:shortCode",
    component: "MagicLink",
    protected: false,
  },
  {
    path: "/register/:location",
    component: "Register",
    protected: false,
  },
]

export const Router = ({ routes, match }) => {
  let path = ""
  if (match) {
    path = match.path
  }
  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
      {routes.map(({ component, ...rest }, i) => (
        <Route key={i} {...rest} component={PageComponents[component]} />
      ))}
      <Route path={`${path}/*`} component={NotFound} />
    </Switch>
  )
}
export default Router
