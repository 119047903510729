import React, { useEffect } from "react"
import { connect } from "react-redux"
// **** M-UI IMPORTS ****
import useStyles from "./styles"
import Grid from "@material-ui/core/Grid"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import Typography from "@material-ui/core/Typography"
import Modal from "@material-ui/core/Modal"
// **** M-UI IMPORTS ****

import ChandlerForm from "../ChandlerForm"
import EmployeeList from "../../components/EmployeeList"

const ManageUsersDialog = ({ handleCloseDialog, manageUserProp }) => {
  const FooterLogo = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/cf-logo-footer.svg`
  const classes = useStyles()
  const [employeeId, setEmployeeId] = React.useState("")
  const [openModal, setOpenModal] = React.useState(false)
  // initialize list, eventually from server data. timeout to mimic delay

  // *** MODAL STATE & HANDLERS ***
  const handleOpenModal = async (row, action) => {
    await setEmployeeId(row)
    setOpenModal((openModal) => !openModal)
  }

  const handleCloseModal = () => {
    setOpenModal((openModal) => !openModal)
  }
  // *** MODAL STATE & HANDLERS ***
  return (
    <Grid container>
      <Grid className={classes.header} container justify="space-between">
        <ArrowBackIcon
          className={classes.backIcon}
          color="primary"
          onClick={() => {
            handleCloseDialog()
          }}
        />
        <Typography variant="caption" color="primary">
          MANAGE USERS
        </Typography>
        {/* css trick to keep proper spacing */}
        <div style={{ color: "white" }}>invis</div>
      </Grid>
      <EmployeeList handleOpenModal={handleOpenModal} />
      <Grid className={classes.footer} container justify="center">
        <img src={FooterLogo} alt="footer logo" />
      </Grid>
      <Modal open={openModal} onClose={handleCloseModal}>
        <div>
          <ChandlerForm
            editRow={employeeId}
            closeModal={() => handleOpenModal()}
            action="edit"
          />
        </div>
      </Modal>
    </Grid>
  )
}
const mapStateToProps = (state) => {
  return {
    isAdmin: state.authentication.authenticatedAdmin,
  }
}
export default connect(mapStateToProps)(ManageUsersDialog)
