import React from "react"
import useStyles from "./styles"
import { Typography, Radio, FormControlLabel, Button } from "@material-ui/core"
import classnames from "clsx"
import { Form, useFormikContext } from "formik"

const SimpleForm4 = ({ children }) => {
  const classes = useStyles()

  const { values, setFieldValue } = useFormikContext()

  let radio = (
    <div className={classes.noneSelected}>
      <FormControlLabel
        value="best"
        control={
          <div
            onClick={() => {
              setFieldValue("form4.not_sure", true)
              setFieldValue("form4.office", false)
              setFieldValue("form4.bathroom", false)
              setFieldValue("form4.living_room", false)
              setFieldValue("form4.bedroom", false)
              setFieldValue("form4.porch", false)
              setFieldValue("form4.kitchen", false)
            }}
          >
            <Radio color="primary" checked={false} />
          </div>
        }
        label="Not sure"
      />
    </div>
  )

  if (values.form4.not_sure) {
    radio = (
      <div className={classes.noneSelected}>
        <FormControlLabel
          value="best"
          control={
            <div onClick={() => setFieldValue("form4.not_sure", false)}>
              <Radio color="primary" checked />
            </div>
          }
          label="Not sure"
        />
      </div>
    )
  }

  let padding = "15px"

  if (window.innerWidth < 735) {
    padding = "5px"
  }

  return (
    <div className={classes.formContainer}>
      <Typography variant="h4" style={{ paddingBottom: padding }}>
        Room use
      </Typography>
      <Form className={classes.form}>
        <div className={classes.checkBtnContainer}>
          <div className={classes.checkBtns}>
            <Button
              variant="contained"
              classes={{ contained: classes.buttonOverride }}
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form4.office === true,
              })}
              onClick={() => {
                setFieldValue("form4.office", !values.form4.office)
                if (values.form4.not_sure) {
                  setFieldValue("form4.not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Office</Typography>
            </Button>
            <Button
              variant="contained"
              classes={{ contained: classes.buttonOverride }}
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form4.bathroom === true,
              })}
              onClick={() => {
                setFieldValue("form4.bathroom", !values.form4.bathroom)
                if (values.form4.not_sure) {
                  setFieldValue("form4.not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Bathroom</Typography>
            </Button>
            <Button
              variant="contained"
              classes={{ contained: classes.buttonOverride }}
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form4.living_room === true,
              })}
              onClick={() => {
                setFieldValue("form4.living_room", !values.form4.living_room)
                if (values.form4.not_sure) {
                  setFieldValue("form4.not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Living Room</Typography>
            </Button>
            <Button
              variant="contained"
              classes={{ contained: classes.buttonOverride }}
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form4.bedroom === true,
              })}
              onClick={() => {
                setFieldValue("form4.bedroom", !values.form4.bedroom)
                if (values.form4.not_sure) {
                  setFieldValue("form4.not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Bedroom</Typography>
            </Button>
            <Button
              variant="contained"
              classes={{ contained: classes.buttonOverride }}
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form4.porch === true,
              })}
              onClick={() => {
                setFieldValue("form4.porch", !values.form4.porch)
                if (values.form4.not_sure) {
                  setFieldValue("form4.not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Porch</Typography>
            </Button>
            <Button
              variant="contained"
              classes={{ contained: classes.buttonOverride }}
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form4.kitchen === true,
              })}
              onClick={() => {
                setFieldValue("form4.kitchen", !values.form4.kitchen)
                if (values.form4.not_sure) {
                  setFieldValue("form4.not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Kitchen</Typography>
            </Button>
          </div>
        </div>
        {radio}
      </Form>
    </div>
  )
}

export default SimpleForm4
