import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  libraryCard: {
    marginTop: "24px",
    marginBottom: theme.spacing(3),
  },
  paper: {
    position: "fixed",
    top: "50%",
    left: "50%",
    width: "80%",
    maxHeight: "80%",
    transform: "translate(-50%, -50%)", // helps with centering
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2, 1),
    paddingTop: "15px !important",
    "&:focus": {
      outline: "none",
    },
  },
  button: {
    color: "white",
    minHeight: "50px",
    backgroundColor: "black",
    padding: "8px 36px",
    margin: "5px 0",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  legend: {
    marginTop: "24px",
  },
  nameForm: {
    width: "100%",
    marginTop: "40px",
    textAlign: "center",
    color: theme.palette.secondary.main,
  },
  textField: {
    width: "40%",
    "@media (max-width: 500px)": {
      width: "70%",
    },
  },
  textRoot: {
    "& > label": {
      color: theme.palette.primary.main,
      fontSize: "20px",
      fontWeight: "500",
      paddingLeft: "5px",
    },
    "& $label + .MuiInput-formControl": {
      paddingLeft: "5px",
      paddingBottom: "5px",
    },
    "& .MuiInput-underline": {
      "&:not(.Mui-disabled)": {
        "&:before": {
          borderBottom: `2px solid${theme.palette.primary.main}`,
        },
      },
      "&:hover": {
        "&:not(.Mui-disabled)": {
          "&:before": {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
          },
        },
      },
    },
  },
}))

export default styles
