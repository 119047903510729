import React from "react"
import useStyles from "./styles"
import PageHeader from "components/PageHeader"
import Router from "router"
import Footer from "components/Footer"
import PageWrapper from "components/PageWrapper"

const Discovery = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  return (
    <>
      <PageHeader overText="DISCOVER" />
      <PageWrapper>
        <div className={classes.discoverPage}>
          <Router routes={routes} {...rest} />
          <Footer show />
        </div>
      </PageWrapper>
    </>
  )
}

export default Discovery
