import React from "react"
import useStyles from "./styles"
import { Typography, Button } from "@material-ui/core"
import classnames from "clsx"
import { Form, useFormikContext } from "formik"

const SimpleForm3 = ({ children }) => {
  const classes = useStyles()

  const { values, setFieldValue } = useFormikContext()

  let padding = "15px"

  if (window.innerWidth < 735) {
    padding = "5px"
  }

  return (
    <div className={classes.formContainer}>
      <Typography variant="h4" style={{ paddingBottom: padding }}>
        Select your interest
      </Typography>
      <Form className={classes.form}>
        <div className={classes.checkBtnContainer}>
          <div className={classes.checkBtns}>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form6.spicy === true,
              })}
              classes={{ contained: classes.contained }}
              onClick={() => {
                setFieldValue("form6.spicy", !values.form6.spicy)
              }}
            >
              <Typography variant="h4">Spicy</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form6.fresh === true,
              })}
              classes={{ contained: classes.contained }}
              onClick={() => {
                setFieldValue("form6.fresh", !values.form6.fresh)
              }}
            >
              <Typography variant="h4">Fresh</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form6.cozy === true,
              })}
              classes={{ contained: classes.contained }}
              onClick={() => {
                setFieldValue("form6.cozy", !values.form6.cozy)
              }}
            >
              <Typography variant="h4">Cozy</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form6.earthy === true,
              })}
              classes={{ contained: classes.contained }}
              onClick={() => {
                setFieldValue("form6.earthy", !values.form6.earthy)
              }}
            >
              <Typography variant="h4">Earthy</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form6.relaxing === true,
              })}
              classes={{ contained: classes.contained }}
              onClick={() => {
                setFieldValue("form6.relaxing", !values.form6.relaxing)
              }}
            >
              <Typography variant="h4">Relaxing</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form6.vibrant === true,
              })}
              classes={{ contained: classes.contained }}
              onClick={() => {
                setFieldValue("form6.vibrant", !values.form6.vibrant)
              }}
            >
              <Typography variant="h4">Vibrant</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form6.juicy === true,
              })}
              classes={{ contained: classes.contained }}
              onClick={() => {
                setFieldValue("form6.juicy", !values.form6.juicy)
              }}
            >
              <Typography variant="h4">Juicy</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form6.smoky === true,
              })}
              classes={{ contained: classes.contained }}
              onClick={() => {
                setFieldValue("form6.smoky", !values.form6.smoky)
              }}
            >
              <Typography variant="h4">Smoky</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form6.herbal === true,
              })}
              classes={{ contained: classes.contained }}
              onClick={() => {
                setFieldValue("form6.herbal", !values.form6.herbal)
              }}
            >
              <Typography variant="h4">Herbal</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form6.floral === true,
              })}
              classes={{ contained: classes.contained }}
              onClick={() => {
                setFieldValue("form6.floral", !values.form6.floral)
              }}
            >
              <Typography variant="h4">Floral</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form6.citrusy === true,
              })}
              classes={{ contained: classes.contained }}
              onClick={() => {
                setFieldValue("form6.citrusy", !values.form6.citrusy)
              }}
            >
              <Typography variant="h4">Citrusy</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form6.yummy === true,
              })}
              classes={{ contained: classes.contained }}
              onClick={() => {
                setFieldValue("form6.yummy", !values.form6.yummy)
              }}
            >
              <Typography variant="h4">Yummy</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: values.form6.festive === true,
              })}
              classes={{ contained: classes.contained }}
              onClick={() => {
                setFieldValue("form6.festive", !values.form6.festive)
              }}
            >
              <Typography variant="h4">Festive</Typography>
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default SimpleForm3
