import React from "react"
import useStyles from "./styles"
import { Typography, Slider } from "@material-ui/core"
import { Form, useFormikContext } from "formik"

const SimpleForm3 = ({ children }) => {
  const classes = useStyles()

  const { values, setFieldValue } = useFormikContext()

  const onChange = (e, value) => {
    setFieldValue("form3.strength", value)
  }

  const marks = [
    {
      value: -2,
      label: "Light",
    },
    {
      value: 2,
      label: "Strong",
    },
  ]

  return (
    <div className={classes.formContainer}>
      <Typography variant="h4">Fragrance Strength</Typography>
      <Form className={classes.form}>
        <Slider
          min={-2}
          max={2}
          aria-labelledby="discrete-slider-always"
          step={0.05}
          marks={marks}
          value={values.form3.strength}
          name="strength"
          id="strength"
          onChange={onChange}
          className={classes.slider}
        />
      </Form>
    </div>
  )
}

export default SimpleForm3
