import { QUICKEDIT_OPEN, QUICKEDIT_CLOSE } from "types/actions/quick-edit"

export const openQuickEdit = (data) => ({
  type: QUICKEDIT_OPEN,
  open: true,
})

export const closeQuickEdit = (data) => ({
  type: QUICKEDIT_CLOSE,
  open: false,
})
