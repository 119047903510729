import React from "react"
import styles from "./styles"
import { withStyles } from "@material-ui/core/styles"
import { Box, Collapse } from "@material-ui/core"
import Button from "../Button"
import padNumber from "utils/padNumber"

class Row extends React.Component {
  shouldComponentUpdate(props) {
    return (
      props.items.indexOf(props.selectedItem) >= 0 ||
      props.items.indexOf(this.props.selectedItem) >= 0
    )
  }
  getItemInteractions = (number) => {
    return this.props.interactedItems.find((x) => x.number === number + 1)
  }
  render() {
    const { children, items, selectedItem, setSelectedItem, id, classes } =
      this.props
    return (
      <Box className={classes.buttonsRow} id={id}>
        <Box className={classes.buttons}>
          {/* loop over individual items to create buttons */}
          {items.map((number, itemIndex) => {
            const itemInteractions = this.getItemInteractions(number)
            return (
              <Box className={classes.itemBox} key={itemIndex}>
                <Button
                  active={selectedItem === number}
                  onClick={() =>
                    setSelectedItem(selectedItem === number ? -1 : number)
                  }
                  liked={itemInteractions?.liked}
                  disliked={itemInteractions?.disliked}
                  curious={itemInteractions?.curious}
                  number={number}
                >
                  {padNumber(number + 1)}
                </Button>
              </Box>
            )
          })}
        </Box>
        <Collapse
          in={items.indexOf(selectedItem) > -1}
          style={{ width: "100%" }}
          timeout={100}
        >
          {children({ selectedItem, setSelectedItem })}
        </Collapse>
      </Box>
    )
  }
}

export default withStyles(styles)(Row)
