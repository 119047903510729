import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  shopItem: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(1.5),
    width: "100%",
    maxWidth: 260,
    "@media (max-width: 768px)": {
      flexGrow: 1,
      flexShrink: 0,
    },
  },
  imageBox: {
    width: "100%",
    paddingTop: "100%",
    position: "relative",
    overflow: "hidden",
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    "& > img": {
      position: "absolute",
      top: "25%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
    },
  },
  content: {
    padding: theme.spacing(1, 2),
    maxWidth: 260,
  },
  price: {
    fontFamily: "futura-pt-bold, Roboto, 'sans-serif'",
  },
  title: {
    fontFamily: "futura-pt-bold, Roboto, 'sans-serif'",
    lineHeight: 1.2,
  },
  buttons: {
    marginTop: theme.spacing(2),
    "& > form": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  addButton: {
    backgroundColor: "black",
    color: "white",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
}))

export default styles
