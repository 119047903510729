import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    padding: "15px 30px",
    "@media (max-width: 735px)": {
      padding: "0px 30px 15px 30px",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    color: theme.palette.primary.main,
    justifyContent: "center",
  },
  sliderContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: "10px 0px 20px 0px",
    width: "100%",
    "@media (max-width: 500px)": {
      margin: "0px 0px 10px 0px",
    },
  },
  slider: {
    margin: "0px 8px 0px 8px",
    width: "100%",
  },
  markLabel: {
    top: "30px",
  },
}))

export default styles
