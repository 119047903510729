import React from "react"
import useStyles from "./styles"
import { Typography, Slider, Button } from "@material-ui/core"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { saveFormData } from "actions/forms"

const Form2 = ({ children, handleNewActiveForm }) => {
  const classes = useStyles()

  const initialValues = useSelector((state) => state.forms["form2"])

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      dispatch(saveFormData("completedTwo", true))
      dispatch(saveFormData("form2", values))
      window.scrollTo(0, 0)
      handleNewActiveForm(3)
    },
  })

  const onPreviousClicked = () => {
    dispatch(saveFormData("completedTwo", true))
    dispatch(saveFormData("form2", formik.values))
    window.scrollTo(0, 0)
    handleNewActiveForm(1)
  }

  const onChange = (e, value) => {
    formik.setFieldValue("uniqueness", value)
  }

  const marks = [
    {
      value: -2,
      label: "Low",
    },
    {
      value: 0,
      label: "Neutral",
    },
    {
      value: 2,
      label: "High",
    },
  ]

  return (
    <div className={classes.formContainer}>
      <Typography variant="h1" className={classes.discoverHeading}>
        WHAT LEVEL OF UNIQUENESS WOULD YOU LIKE?
      </Typography>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <Slider
          min={-2}
          max={2}
          aria-labelledby="discrete-slider-always"
          step={0.05}
          marks={marks}
          value={formik.values.uniqueness}
          name="uniqueness"
          id="uniqueness"
          onChange={onChange}
          className={classes.slider}
        />
        <div className={classes.labelDescriptions}>
          <Typography variant="body1" className={classes.labelDescriptionLeft}>
            Popular and overall <br /> crowd pleaser
          </Typography>
          <Typography variant="body1" className={classes.labelDescriptionRight}>
            Unique and <br /> something different
          </Typography>
        </div>
        <div className={classes.btnContainer}>
          <Button
            variant="outlined"
            className={classes.formButton}
            onClick={onPreviousClicked}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            type="submit"
            className={classes.formButton}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Form2
