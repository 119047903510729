import React, { useState } from "react"
import useStyles from "./styles"
import { Typography, Button } from "@material-ui/core"

const GiftSection = ({ children }) => {
  const classes = useStyles()
  const [opened, setOpened] = useState(false)

  const closePopover = () => {
    window.open(
      "https://www.candlefish.com/collections/top-selling-fragrances",
      "_blank",
    )
    sessionStorage.setItem("shown", "true")
    setOpened(true)
  }

  if (sessionStorage.getItem("shown") === "true" && opened === false) {
    setOpened(true)
  }

  return (
    <div className={classes.paper}>
      <div>
        <Typography variant="h2">NEED HELP FINDING A GIFT?</Typography>
      </div>
      <Button
        variant="outlined"
        className={classes.acceptButton}
        onClick={() => closePopover()}
      >
        SHOP TOP SELLERS
      </Button>
    </div>
  )
}

export default GiftSection
