import React from "react"
import useStyles from "./styles"
import { useSelector, useDispatch } from "react-redux"
import { saveCandleData } from "actions/candles"
import { Paper, Typography } from "@material-ui/core"
import padNumber from "utils/padNumber"
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed"

const CandleCard = ({ setCurrentFamily, number }) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const candles = useSelector((state) => state.candles["candles"])

  console.log("candles", candles)

  let image = null
  if (candles.length > 0) {
    let i = number - 1
    image = encodeURI(candles[i].image)
  }

  const scrollToRow = () => {
    const denom = window.innerWidth > 735 ? 10 : 5
    const num = Math.floor((number - 1) / denom)
    const id = "index" + num
    const node = document.getElementById(id)
    smoothScrollIntoView(node, {
      block: "start",
    })
  }

  const handleClickFeatured = () => {
    setCurrentFamily("")
    dispatch(saveCandleData("selectedCandle", number - 1))
    setTimeout(scrollToRow, 300)
  }

  return (
    <Paper
      className={classes.paper}
      onClick={handleClickFeatured}
      style={{
        backgroundImage: `linear-gradient(
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)),
        url(${image})`,
      }}
    >
      <Typography variant="h3" className={classes.noText}>
        N<sup>o</sup>
      </Typography>
      <Typography variant="h1" className={classes.candleNumber}>
        {padNumber(number)}
      </Typography>
    </Paper>
  )
}

export default CandleCard
