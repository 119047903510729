import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  header: {
    position: "absolute",
    top: 0,
    height: "50px",
    padding: "31px",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    height: "56px",
  },
  dialog: {
    position: "absolute",
    top: "140px",
    bottom: "135px",
  },
  backIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableContainer: {
    width: "95%",
  },
  table: {
    minWidth: "500px",
  },
  headCell: {
    color: theme.palette.secondary.main,
  },
  button: {
    color: "white",
    backgroundColor: "black",
    padding: "19px 36px",
    margin: "10px 0",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

export default styles
