import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  formContainer: {
    marginTop: "40px",
    width: "100%",
    position: "relative",
  },
  discoverHeading: {
    margin: "0px 20px 20px 20px",
    color: theme.palette.primary.main,
  },

  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    color: theme.palette.primary.main,
    margin: "40px 0px 16px 0px",
  },
  sliderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: "10px 0px 10px 0px",
    "@media (max-width: 500px)": {
      width: "90%",
    },
  },
  slider: {
    margin: "0px 15px 20px 15px",
    width: "70%",
    "@media (max-width: 500px)": {
      width: "80%",
      margin: "0px 10px 0px 10px",
    },
  },
  textField: {
    width: "60%",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingTop: "20px",
  },
  formButton: {
    width: "40%",
    maxWidth: "150px",
    background: "black",
    color: "white",
    margin: "14px 0px 0px 10px",
    "&:hover": {
      color: "black",
    },
    "@media (max-width: 500px)": {
      "&:hover": {
        background: "black",
        color: "white",
      },
    },
  },
  markLabel: {
    top: "-26px",
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
}))

export default styles
