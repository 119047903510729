import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  overText: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    position: "absolute",
    top: theme.spacing(12),
    width: "100%",
    overflow: "hidden",
    pointerEvents: "none",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  letter: {
    fontSize: 194,
    lineHeight: "145px",
    fontWeight: "bold",
    opacity: ".1",
    color: theme.palette.secondary.main,
  },
  adminHeader: {
    display: "flex",
    alignItems: "center",
    height: "50px",
  },
  backArrow: {
    margin: "31px 0px 0px 31px",
    height: "35px",
    width: "35px",
    color: theme.palette.secondary.main,
    "&:hover": {
      cursor: "pointer",
    },
  },
}))

export default styles
