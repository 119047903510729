import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  backButton: {
    width: 75,
    height: 75,
    background: "#FFF4F2",
    color: theme.palette.primary.main,
    borderRadius: 0,
  },
  backIcon: {
    width: 25,
    height: 25,
  },
  title: {
    fontSize: 12,
    textTransform: "uppercase",
    fontWeight: 700,
    letterSpacing: "1px",
    color: theme.palette.primary.main,
    height: "100%",
  },
}))

export default styles
