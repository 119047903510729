import { makeStyles } from "@material-ui/core/styles"
import { fade } from "@material-ui/core/styles/colorManipulator"

const styles = makeStyles((theme) => ({
  contentBox: {
    backgroundColor: "white",
    display: "flex",
    maxHeight: 690,
    maxWidth: 1440,
    margin: "auto",
    width: "100%",
    "& .MuiTypography-h3": {
      fontFamily: "futura-pt-bold, Roboto, 'sans-serif'",
      textTransform: "uppercase",
    },
    "@media (max-width: 1200px)": {
      maxHeight: 457,
    },
    "@media (max-width: 735px)": {
      flexDirection: "column",
      maxHeight: "initial",
    },
  },
  mainCandleImage: {
    maxWidth: 473,
    maxHeight: 690,
    overflow: "hidden",
    position: "relative",
    height: "100%",
    width: "100%",
    flexShrink: "0",
    "& img": {
      verticalAlign: "bottom",
      width: "100%",
    },
    "@media (max-width: 1200px)": {
      maxWidth: 315,
      maxHeight: 457,
    },
    "@media (max-width: 735px)": {
      maxWidth: "initial",
      paddingTop: "70.67%",
      overflow: "hidden",
      "& img": {
        position: "absolute",
        top: "50%",
        left: 0,
        transform: "translateY(-50%)",
      },
    },
  },
  candleNumber: {
    position: "absolute",
    color: "white",
    fontSize: "6.2666rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
  },
  candleContent: {
    backgroundColor: "#fafafa",
    padding: theme.spacing(6.875, 7.75),
    overflow: "auto",
    "& > .MuiTypography-h3": {
      marginBottom: theme.spacing(1.5),
    },
    "& .MuiButton-text": {
      // borderBottom: "1px solid currentColor"
      textDecoration: "underline",
    },
    "@media (max-width: 1200px)": {
      padding: theme.spacing(4, 5),
    },
    "@media (max-width: 735px)": {
      padding: theme.spacing(4, 2),
    },
  },
  aboutFragrance: {
    marginBottom: theme.spacing(6.25),
    "& > .MuiTypography-h3": {
      marginBottom: theme.spacing(1.5),
    },
  },
  familyButton: {
    color: "#BABABA",
    fontSize: "inherit",
    position: "relative",
    top: -3,
  },
  notesContent: {
    "& > .MuiTypography-h3": {
      display: "inline-block",
      marginBottom: theme.spacing(4),
      "& .MuiButton-root": {
        marginLeft: theme.spacing(1),
      },
    },
    marginBottom: theme.spacing(4),
    "& img": {
      maxWidth: 109,
      marginRight: theme.spacing(4),
    },
    "& .MuiButton-root": {
      marginLeft: theme.spacing(-1),
    },
  },
  chandlerNotes: {
    "& > .MuiTypography-h3": {
      marginBottom: theme.spacing(4),
    },
    "& .MuiTypography-subtitle1": {
      padding: theme.spacing(0, 4),
      marginBottom: theme.spacing(4),
    },
  },
  chandlerInfo: {
    display: "flex",
    justifyContent: "center",
    "& > .MuiTypography-h3": {
      textTransform: "capitalize",
      lineHeight: "1em",
    },
    "& .MuiAvatar-root": {
      marginRight: theme.spacing(2),
    },
  },
  buttonsBox: {
    width: 140,
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 1200px)": {
      width: 91,
    },
    "@media (max-width: 735px)": {
      position: "sticky",
      background: "white",
      bottom: "0",
      width: "initial",
      flexDirection: "row",
      justifyContent: "center",
      // backgroundColor: theme.palette.primary.main
    },
  },
  actionButton: {
    height: 140,
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      "& > svg": {
        marginBottom: theme.spacing(),
      },
    },
    "&:first-child": {
      "& .MuiButton-label": {
        "& > svg": {
          marginBottom: [0, "!important"],
        },
      },
    },
    "@media (max-width: 1200px)": {
      height: 91,
    },
    "@media (max-width: 735px)": {
      height: "initial",
      // color: "white",
      margin: theme.spacing(0, 2),
    },
  },
  addButton: {
    "@media (max-width: 735px)": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  closeButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "@media (max-width: 735px)": {
      display: "none",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  icon: {
    height: "30px",
    width: "30px",
    color: "white",
    fill: "white",
    marginBottom: "-5px",
    "@media (max-width: 568px)": {
      marginBottom: "0px",
    },
  },
  liked: {
    color: theme.palette.secondary.main,
    backgroundColor: fade(theme.palette.secondary.main, 0.5),
    "&:hover": {
      backgroundColor: fade(theme.palette.secondary.main, 0.5),
    },
  },
  fav: {
    color: theme.palette.secondary.main,
  },
  disliked: {
    color: "#D5D5D5",
    backgroundColor: fade("#D5D5D5", 0.5),
    "&:hover": {
      backgroundColor: fade("#D5D5D5", 0.5),
    },
  },
  nope: {
    color: "#D5D5D5",
  },
  curious: {
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  chandlerImage: {
    width: 45,
    height: 45,
  },
}))

export default styles
