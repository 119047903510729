import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  stepSelector: {
    display: "flex",
    margin: "60px 0px 0px 0px",
    width: "100%",
    justifyContent: "center",
    flexShrink: 0,
    "@media (max-width: 500px)": {
      justifyContent: "space-around",
      margin: "0px 0px 0px 0px",
    },
  },
  step: {
    height: "40px",
    width: "40px",
    margin: "0px 30px 0px 30px",
    "@media (max-width: 1130px)": {
      margin: "0px 20px 0px 20px",
    },
    "@media (max-width: 900px)": {
      margin: "0px 10px 0px 10px",
    },
    "@media (max-width: 500px)": {
      margin: "0px 0px 0px 0px",
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    opacity: "1 !important",
    "&:hover": {
      backgroundColor: [theme.palette.primary.main, "!important"],
    },
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    opacity: ".5",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  MuiFabPrimary: {
    "&hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

export default styles
