import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    padding: "0px 30px 15px 30px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    color: theme.palette.primary.main,
    justifyContent: "center",
  },
  slider: {
    margin: "0px 8px 0px 8px",
    width: "92%",
  },
}))

export default styles
