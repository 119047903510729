import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  logo: {
    maxWidth: 254,
    margin: `${theme.spacing(6)}px auto`,
    display: "block",
    verticalAlign: "bottom",
  },
  libraryCard: {
    marginBottom: theme.spacing(3),
  },
}))

export default styles
