import React from "react"
import Flippy, { FrontSide, BackSide } from "react-flippy"
// import useStyles from "./styles"

const Note = ({ frontSide, backSide, className, ...props }) => {
  // const classes = useStyles()

  return (
    <Flippy
      className={className}
      flipOnClick={false}
      flipDirection="horizontal" // horizontal or vertical
      {...props}
    >
      <FrontSide>{frontSide}</FrontSide>
      <BackSide>{backSide}</BackSide>
    </Flippy>
  )
}

export default Note
