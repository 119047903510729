import store from "store"
import { saveCandleData } from "actions/candles"

const shuffleFragrances = async () => {
  const allFragrances = store.getState().candles.allFragrances

  const shuffledFragrances = [
    ...allFragrances.map((x, i) => ({ ...x, i })),
  ].sort((a, b) => (Math.random() > 0.5 ? b.i - a.i : a.i - b.i))

  let topThree = []
  for (let i = 0; i < 3; i++) {
    topThree.push(shuffledFragrances[i].candle_id)
  }

  store.dispatch(saveCandleData("allFragrances", shuffledFragrances))
  store.dispatch(saveCandleData("fragrances", topThree))
}

export default shuffleFragrances
