import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  bubble: {
    width: 150,
    height: 150,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    fontSize: 20,
    backgroundColor: "white",
    "@media (max-width: 890px)": {
      width: 120,
      height: 120,
    },
    "@media (max-width: 735px)": {
      width: 88,
      height: 88,
      fontSize: 13,
      "& $candleNumberText": {
        // marginRight: -22
      },
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "& $candleNumberText": {
      "& > sup": {
        borderColor: "white",
      },
    },
  },
  candleNumberText: {
    minWidth: 43,
    fontSize: "1em",
    letterSpacing: "0.246rem",
    textAlign: "center",
    // marginRight: "-0.746rem",
    "& > sup": {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      letterSpacing: 0,
    },
  },
  candleNumber: {
    fontSize: "2.6em",
  },
}))

export default styles
