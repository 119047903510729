import API from "store/api"
import serialize from "store/serialize"
import {
  USERS_SET_LOADING,
  USERS_SET_ERROR,
  USERS_SET_CUSTOMER_LIST,
} from "types/actions/users"
import { setUser } from "./authentication"

export const setCustomerList = (data) => ({
  type: USERS_SET_CUSTOMER_LIST,
  data,
})

export const setLoading = (loading = true) => ({
  type: USERS_SET_LOADING,
  loading,
})

export const setError = (error = false) => ({
  type: USERS_SET_ERROR,
  error,
})

export const getUser = (userId) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return API.get(`/users/${userId}`)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.body)
      .then((result) => {
        return serialize("user", result.data)
      })
      .catch((err) => {
        dispatch(setLoading(false))
        dispatch(setError(err))
        console.error(err)
      })
  }
}

export const addUser = (userData) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return new Promise((resolve, reject) => {
      API.post("/users")
        .send(userData)
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText)
          }
          return response
        })
        .then((response) => response.body)
        .then(async (data) => {
          API.set("Authorization", data.token)
          const user = await dispatch(getUser(data.user.id, true))
          dispatch(setUser(user, data.token))
          dispatch(setLoading(false))
          resolve(user)
        })
        .catch((err) => {
          dispatch(setLoading(false))
          dispatch(setError(err))
          reject(err.response)
          console.error(err)
        })
    })
  }
}

export const postNotes = (query) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    return API.post("/users_candles/post_notes/")
      .send(query)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.body)
      .then((result) => {
        return serialize("user", result.data)
      })
      .catch((err) => {
        dispatch(setLoading(false))
        dispatch(setError(err))
        console.error(err)
      })
  }
}

export const getCustomerList = (storeLocation) => {
  let query = {}
  query.location = storeLocation
  return (dispatch) => {
    return API.post("/users/list")
      .send(query)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.body)
      .then((result) => {
        dispatch(setCustomerList(result.data))
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export const updateUser = (data, token) => {
  return (dispatch) => {
    return API.put(`/users/${data.id}`)
      .send(data)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.body)
      .then((result) => {
        dispatch(setUser(result.data, token))
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export const sendTwilio = (data, token) => {
  return (dispatch) => {
    return API.post("/twilio/")
      .send(data)
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) => response.body)
      .catch((err) => {
        console.error(err)
      })
  }
}
