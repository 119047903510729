import React, { useState } from "react"
import useStyles from "./styles"
import { Modal, Typography, Button } from "@material-ui/core"

const LibraryPopover = ({ children }) => {
  const CandlefishIcon = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/cf-library-logo-white.svg`
  const classes = useStyles()
  const [opened, setOpened] = useState(false)

  const closePopover = () => {
    sessionStorage.setItem("shown", "true")
    setOpened(true)
  }

  if (sessionStorage.getItem("shown") === "true" && opened === false) {
    setOpened(true)
  }

  return (
    <Modal className={classes.modal} hideBackdrop open={!opened}>
      <div className={classes.paper}>
        <img
          src={CandlefishIcon}
          alt="Panel Expanded Icon"
          className={classes.panelIcon}
        />
        <div>
          <Typography variant="h2">
            Welcome to your Candlefish Library Card
          </Typography>
        </div>
        <div>
          <Typography variant="body1">
            This card will help you explore our 100 unique fragrances and track
            what you love, are curious about or do not like. Enter your phone
            number (optional) to save your preferences and add to your card over
            time.
          </Typography>
        </div>
        <Button
          variant="outlined"
          className={classes.acceptButton}
          onClick={() => closePopover()}
        >
          GOT IT!
        </Button>
      </div>
    </Modal>
  )
}

export default LibraryPopover
