import React from "react"
import PinInput from "react-pin-input"
import _ from "lodash"
import { useHistory } from "react-router-dom"
import { setAdminData } from "actions/admins"
import { checkPin, EmpcheckPin, setSuperAdmin } from "actions/admins"
import { login } from "actions/authentication"
import { useDispatch } from "react-redux"
import { connect } from "react-redux"
import { getCustomerList } from "actions/users"

// **** M-UI IMPORTS ****
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import FormHelperText from "@material-ui/core/FormHelperText"
// **** M-UI IMPORTS ****

const PinForm = ({ endpoint, page, storeLocation }) => {
  // refs
  const errorRef = React.createRef()
  const pinRef = React.createRef()
  const formContainerRef = React.createRef()
  const history = useHistory()

  const dispatch = useDispatch()
  /* in the future will contain a ternary to determine
    whether this is for the store pin or a user pin endpoint
    and will handle each situation */
  const handleSubmit = async (value, index) => {
    try {
      // reset shake
      formContainerRef.current.style.animation = ""

      // replace with real axios call
      if (value && endpoint === "store") {
        let query = {}
        query.endpoint = "store"
        query.pin = value
        localStorage.setItem("store", value)
        await dispatch(checkPin(query)).then((res) => {
          if (res === true) {
            history.push("/kiosk/queue")
          } else {
            pinRef.current.clear()
            formContainerRef.current.style.animation = "shake 0.5s"
          }
        })
      }
      if (value && endpoint !== "store") {
        let query = {}
        query.pin = value
        query.endpoint = "employee"
        dispatch(EmpcheckPin(query)).then((res) => {
          if (res === true) {
            localStorage.setItem("employeePin", value)
          } else {
            pinRef.current.clear()
            formContainerRef.current.style.animation = "shake 0.5s"
          }
        })
      }
    } catch (error) {
      if (pinRef.current) {
        // clear after submission
        pinRef.current.clear()
        // refocus if error
        pinRef.current.focus()
        // set error text
        errorRef.current.innerHTML = error.message

        formContainerRef.current.style.animation = "shake 0.5s"
      }
    }
  }

  /* throttle submittion by 1 second, to prevent unnecessary spam. Further
  throttling should be done on the server side to prevent bombarding the endpoint.
  */
  const throttledSubmit = _.throttle(handleSubmit, 2000, { trailing: true })

  const handleChange = (value, index) => {
    // reset error on change
    errorRef.current.innerHTML = ""
  }

  return (
    <Grid
      ref={formContainerRef}
      container
      direction="column"
      alignItems="center"
    >
      <PinInput
        ref={pinRef}
        length={4}
        initialValue=""
        autoFocus
        focus
        onChange={(value, index) => handleChange(value, index)}
        type="numeric"
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
        inputStyle={{
          display: "flex",
          color: "white",
          height: "100px",
          width: "100px",
          borderRadius: "33px",
          backgroundColor: "#FD6A4F",
          border: "0px",
          margin: "10px 20px",
          textAlign: "center",
          padding: "12px 10px",
          fontSize: "4rem",
          fontWeight: 400,
        }}
        inputFocusStyle={{}}
        onComplete={(value, index) => throttledSubmit(value, index)}
      />
      <FormHelperText ref={errorRef} error />
      <Typography
        style={{
          margin: "40px",
          color: "#ad936d",
          fontWeight: 400,
        }}
      >
        {`Please enter your ${endpoint} pin`}
      </Typography>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    storeLocation: state.admins.storeLocation,
  }
}
export default connect(mapStateToProps)(PinForm)
