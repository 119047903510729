import API from "store/api"
import {
  AUTHENTICATION_SET_LOADING,
  AUTHENTICATION_SET_ERROR,
  AUTHENTICATION_SET_USER,
  AUTHENTICATION_UPDATE_USER,
  LOGOUT,
  AUTHENTICATION_SET_ONBOARDED,
  RESET_USER,
  SET_ADMIN_LOGGED_IN,
  RESET_ADMIN_LOGGED_IN,
  SET_ADMIN_AUTH,
  RESET_ADMIN,
} from "types/actions/authentication"
import { getUser } from "actions/users"

export const setAdminAuth = (data) => ({
  type: SET_ADMIN_AUTH,
  data,
})

// export const resetAdmin = (data) => ({
//   type: RESET_ADMIN_LOGGED_IN,
//   data
// })

// export const setAdminLoggedIn = (data) => ({
//   type: SET_ADMIN_LOGGED_IN,
//   data
// })

export const setLoading = (loading = true) => ({
  type: AUTHENTICATION_SET_LOADING,
  loading,
})

export const setError = (error = false) => ({
  type: AUTHENTICATION_SET_ERROR,
  error,
})

export const setUser = (user = {}, token = "") => ({
  type: AUTHENTICATION_SET_USER,
  user,
  token,
})

export const updateLoggedInUser = (updates = {}) => ({
  type: AUTHENTICATION_UPDATE_USER,
  updates,
})

export const logout = () => ({
  type: LOGOUT,
})

export const onBoarded = (onBoarded = true) => ({
  type: AUTHENTICATION_SET_ONBOARDED,
  onBoarded,
})

export const resetUserLoggedIn = () => ({
  type: RESET_USER,
})

export const resetAdminLoggedIn = () => ({
  type: RESET_ADMIN,
})

export const login = (data, history) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    const deferred = new Promise((resolve, reject) => {
      API.post("/auth")
        .set("Content-Type", "application/json")
        .send(data)
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText)
          }
          return response
        })
        .then((response) => response.body)
        .then(async (data) => {
          API.set("Authorization", data.token)
          const user = await dispatch(getUser(data.user_id, true))
          dispatch(setUser(user, data.token))
          dispatch(setLoading(false))
          resolve(data.user_id)
        })
        .catch((err) => {
          reject(err)
          console.error(err)
        })
    })
    return deferred
  }
}

export const magicLink = (magicLink) => {
  return (dispatch) => {
    dispatch(setLoading(true))
    const deferred = new Promise((resolve, reject) => {
      API.post("/auth/magic")
        .set("Content-Type", "application/json")
        .send({ magic_link: magicLink })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText)
          }
          return response
        })
        .then(async (data) => {
          API.set("Authorization", data.body.token)
          const user = await dispatch(getUser(data.body.user_id, true))
          dispatch(setUser(user, data.body.token))
          dispatch(setLoading(false))
          resolve(user)
        })
        .catch((err) => {
          dispatch(setLoading(false))
          dispatch(setError(err))
          reject(err.response)
          console.error(err)
        })
    })
    return deferred
  }
}
