import React from "react"

import SvgIcon from "@material-ui/core/SvgIcon"

const CandlefishLibraryLogo = ({ height, width }) => {
  return (
    <SvgIcon
      style={{
        height: height ? height : "200px",
        width: width ? width : "200px",
      }}
      viewBox="0 0 254.5 125.802"
    >
      <g transform="translate(0.027)">
        <path
          d="M111.838,125.776v10.7h-.871v-10.7H107.31V124.7h8.129v1.076Z"
          transform="translate(-45.341 -51.414)"
          fill="#ad936d"
        />
        <path
          d="M132.726,136.474v-5.6h-6.655v5.6H125.2V124.7h.871v5.119h6.655V124.7h.858v11.774Z"
          transform="translate(-52.104 -51.414)"
          fill="#ad936d"
        />
        <path
          d="M145,136.485V124.73h7.862v1.107h-7.034v4.037h3.825v1.076h-3.825v4.466h7.314v1.07Z"
          transform="translate(-59.589 -51.425)"
          fill="#ad936d"
        />
        <path
          d="M170,136.485V124.73h.871V135.4h6.22v1.088Z"
          transform="translate(-69.04 -51.425)"
          fill="#ad936d"
        />
        <path
          d="M185.35,136.485V124.73h.846v11.755Z"
          transform="translate(-74.843 -51.425)"
          fill="#ad936d"
        />
        <path
          d="M200.046,135.776a2.606,2.606,0,0,1-2.108.709h-5.6V124.73h5.6a2.867,2.867,0,0,1,2.687,
            3.153,3.626,3.626,0,0,1-.958,2.488,3.359,3.359,0,0,1,1.393,2.855,3.564,3.564,0,0,1-1.014,
            2.55Zm-2.108-9.951H193.2v4.1h4.739a2.071,2.071,0,0,0,0-4.111Zm0,5.193H193.2v4.354h4.739a1.922,
            1.922,0,0,0,1.654-.522,2.357,2.357,0,0,0,.578-1.623,2.034,2.034,0,0,0-2.233-2.214Z"
          transform="translate(-77.485 -51.425)"
          fill="#ad936d"
        />
        <path
          d="M218.569,130.847a3.011,3.011,0,0,1-1.555.622l2.413,
          4.976H218.42l-2.4-4.976h-4.155v4.976H211V124.69h5.6a2.575,2.575,0,0,1,
          1.8.473,3.791,3.791,0,0,1,1.244,2.923A3.937,3.937,0,0,1,
          218.569,130.847Zm-.622-4.7c-.33-.255-.678-.361-1.779-.361h-4.354v4.634h4.354c1.014,
          0,1.462-.075,1.866-.423a2.724,2.724,0,0,0,.728-1.959,2.289,2.289,0,0,0-.79-1.916Z"
          transform="translate(-84.539 -51.404)"
          fill="#ad936d"
        />
        <path
          d="M236.309,136.474l-1.673-4.192h-4.882l-1.673,4.192h-.97l4.665-11.774h.865l4.627,
            11.774Zm-4.086-10.412-2.034,5.144h4.055Z"
          transform="translate(-90.63 -51.414)"
          fill="#ad936d"
        />
        <path
          d="M253.957,130.847a3.023,3.023,0,0,1-1.561.622l2.413,
          4.976H253.8l-2.4-4.976h-4.155v4.976h-.9V124.69h5.6a2.575,
            2.575,0,0,1,1.8.473A3.791,3.791,0,0,1,255,128.086,
            3.931,3.931,0,0,1,253.957,130.847Zm-.622-4.7c-.33-.255-.678-.361-1.773-.361h-4.354v4.634h4.354c1.008,
            0,1.455-.075,1.835-.423a2.706,2.706,0,0,0,.734-1.959,2.276,2.276,0,0,0-.777-1.916Z"
          transform="translate(-97.903 -51.404)"
          fill="#ad936d"
        />
        <path
          d="M267.691,131.36v5.125h-.877V131.36l-4.354-6.63H263.6l3.645,5.6,3.589-5.6h1.1Z"
          transform="translate(-103.993 -51.425)"
          fill="#ad936d"
        />
        <path
          d="M295.315,136.474l-1.679-4.192h-4.882l-1.673,4.192h-.97l4.665-11.774h.865l4.627,
            11.774Zm-4.093-10.412-2.034,5.144h4.055Z"
          transform="translate(-112.934 -51.414)"
          fill="#ad936d"
        />
        <path
          d="M308.629,125.776v10.7h-.852v-10.7H304.12V124.7h8.135v1.076Z"
          transform="translate(-119.742 -51.414)"
          fill="#ad936d"
        />
        <path
          d="M68.329,165,59,192.068h2.376l2.873-8.515H75.034l2.88,8.515h2.37L70.929,165Zm-3.421,
            16.563,4.721-13.683,4.721,13.683Z"
          transform="translate(-27.078 -66.648)"
          fill="#ad936d"
        />
        <path
          d="M117.731,181.558l-13.982-23.4h-2.7v27.049h2.171V161.842L117.2,
            185.209H119.9V158.16h-2.164Z"
          transform="translate(-38.21 -59.79)"
          fill="#ad936d"
        />
        <path
          d="M164.292,165H156.94v27.049h7.352c8.191,
            0,14.367-5.815,14.367-13.528S172.483,165,164.292,
            165Zm-.037,24.879-5.144-.037V167.183h5.144c6.96,0,12.2,4.882,12.2,
            11.357S171.214,189.879,164.254,189.879Z"
          transform="translate(-64.103 -66.648)"
          fill="#ad936d"
        />
        <path
          d="M201.584,158.16H199.42v27.049h15.188v-2.171H201.584Z"
          transform="translate(-75.398 -59.79)"
          fill="#ad936d"
        />
        <path
          d="M238.94,185.209h15.014v-1.978H241.1V172.67h9.97V170.7H241.1V160.138h12.85V158.16H238.94Z"
          transform="translate(-90.337 -59.79)"
          fill="#ad936d"
        />
        <path
          d="M282.77,185.209h2.171V172.67h9.97V170.7h-9.97V160.138h12.844V158.16H282.77Z"
          transform="translate(-106.907 -59.79)"
          fill="#ad936d"
        />
        <rect
          width="2.164"
          height="27.049"
          transform="translate(200.412 98.37)"
          fill="#ad936d"
        />
        <path
          d="M359.573,177.654c-2.861-1.051-5.113-1.63-5.113-1.63a4.5,4.5,0,0,
            1-3.259-4.267,5.119,5.119,0,0,1,5.144-4.976,
            5.038,5.038,0,0,1,4.516,2.681l2.059-1.082a7.408,7.408,0,0,0-6.574-3.732A7.233,
            7.233,0,0,0,349,171.8a6.416,6.416,0,0,0,4.4,
            6.276c.771.249,4.1,1.244,4.87,1.493a5.119,5.119,0,0,1,3.57,4.882,5.567,5.567,0,
            0,1-10.43,2.668l-2.034.846a7.986,7.986,0,0,
            0,6.935,4.186,7.781,7.781,0,0,0,7.694-7.663,7.52,7.52,0,0,0-4.435-6.829Z"
          transform="translate(-136.708 -66.515)"
          fill="#ad936d"
        />
        <path
          d="M396.378,158.1v12.383H383.261V158.1H381.09v27.087h2.171V172.841h13.117v12.346h2.171V158.1Z"
          transform="translate(-144.075 -59.767)"
          fill="#ad936d"
        />
        <path
          d="M21.545,166.582A12.327,12.327,0,0,1,29.972,170l1.455-1.455a13.907,13.907,0,1,0-.037,
            19.6l-1.437-1.592a11.817,11.817,0,0,1-8.409,3.514,11.743,11.743,0,0,1,0-23.479Z"
          transform="translate(-7.66 -66.427)"
          fill="#ad936d"
        />
        <path
          d="M200.692,73.74a1.462,1.462,0,1,0,1.462,1.462,1.462,1.462,0,0,0-1.462-1.462Z"
          transform="translate(-80.09 -32.149)"
          fill="#af8f6e"
        />
        <circle
          cx="1.462"
          cy="1.462"
          r="1.462"
          transform="translate(132.394 41.591)"
          fill="#af8f6e"
        />
        <path
          d="M207.61,12.144l5.144-3.16-1.095-1.779-6.077,3.732-6.63-4.068-6.63,
            4.068-6.083-3.732-1.088,1.779,5.137,3.16c-3.806,2.4-13.2,
            9.56-13.2,21.7,0,14.927,14.156,22.3,14.759,22.608l.473.243.479-.243a30.209,
            30.209,0,0,0,5.106-3.514v4.515H200V52.939a29.936,
            29.936,0,0,0,5.106,3.514l.479.243.473-.243c.622-.311,14.759-7.706,14.759-22.608C220.814,
            21.7,211.423,14.545,207.61,12.144Zm10.536,
            26.427a2.842,2.842,0,0,1-2.9-2.388,4.895,4.895,0,0,0,
            3.464-1.965,19.523,19.523,0,0,1-.56,4.354Zm-20.239-4.727V47.889a21.446,21.446,
            0,0,1-5.473-14.044,21.925,21.925,0,0,1,6.524-15.195,21.931,
            21.931,0,0,1,6.512,15.195A21.451,21.451,0,0,1,200,47.876V33.845Zm-18.155,
            4.727a19.848,19.848,0,0,1-.554-4.354,4.87,4.87,0,0,0,3.433,
            1.965,2.836,2.836,0,0,1-2.88,2.388Zm6.431-15.885a2.78,2.78,0,0,1-4.683,1.791,
            24.093,24.093,0,0,1,2.171-3.452A4.907,4.907,0,0,0,186.183,
            22.687Zm8.378-7.862a2.766,2.766,0,0,1-5.218.56,30.8,30.8,0,0,1,2.992-2.028C192.795,
            13.637,193.6,14.128,194.561,14.825ZM183.129,34.143a2.805,2.805,0,
            0,1-2.762-2.376A4.845,4.845,0,0,0,184.1,29.9a4.87,4.87,0,0,0,1.866,1.462h-.031A2.817,
            2.817,0,0,1,183.129,34.143Zm4.124-8.708a4.87,4.87,0,0,0,3.5,1.866,
            2.8,2.8,0,1,1-5.6-.311,4.932,4.932,0,0,0,2.1-1.574Zm3.265,5.635a22.511,22.511,0,
            0,0-.174,2.793c0,.087,0,.162,0,.249a2.8,2.8,0,0,1-2.289-2.326,4.826,
            4.826,0,0,0,2.463-.734Zm4.018-10.343a2.824,2.824,0,0,1-1.953-1.717,
            4.876,4.876,0,0,0,3.732-2.842c.367.3.74.622,1.126.976a28.808,28.808,
            0,0,0-2.9,3.564Zm-3.2.261a4.907,4.907,0,0,0,2.059,1.511,24.7,24.7,0,0,
            0-1.244,2.488A2.73,2.73,0,0,1,188.3,22.78a4.87,4.87,0,0,0,
            3.035-1.81Zm-8.285,6.3a2.724,2.724,0,0,1-3.415,2.307,20.025,20.025,0,0,1,.977-3.153,
            4.845,4.845,0,0,0,2.438.846Zm3.937,7.028a4.858,4.858,0,0,0,3.39,1.866,
            2.793,2.793,0,0,1-5.56-.267A4.895,4.895,0,0,0,186.985,34.317Zm18.6-20.96a30.834,
            30.834,0,0,1,2.992,2.021,2.774,2.774,0,0,1-5.231-.547C204.313,
            14.128,205.11,13.637,205.582,13.357Zm3.987,9.435a2.73,2.73,0,0,1-3.844,2.2,23.461,23.461,
            0,0,0-1.244-2.488,4.889,4.889,0,0,0,2.065-1.555A4.87,4.87,0,0,0,
            209.569,22.792Zm3.147,4.192a2.793,2.793,0,0,1-5.56.311,4.882,4.882,0,0,0,3.5-1.866A4.913,
            4.913,0,0,0,212.716,26.984Zm4.82,4.783a2.807,2.807,0,1,1-5.6-.435h0A4.92,
            4.92,0,0,0,213.8,29.87,4.845,4.845,0,0,0,217.536,31.767Zm-10.15-.715a4.82,4.82,0,
            0,0,2.444.715,2.793,2.793,0,0,1-2.289,2.326v-.249a23.792,23.792,0,0,
            0-.155-2.793ZM201.6,16.175a4.889,4.889,0,0,0,3.732,2.836,2.8,2.8,0,0,1-1.953,1.7,29.278,
            29.278,0,0,0-2.886-3.558C200.862,16.8,201.235,16.479,201.6,
            16.175Zm9.329,18.143a4.864,4.864,0,0,0,2.177,1.6,2.8,2.8,0,1,1-5.6.267,4.845,4.845,0,0,0,
            3.408-1.866Zm6.7-4.627a2.8,2.8,0,0,1-2.768-2.4,4.845,4.845,
            0,0,0,2.438-.846,19.209,19.209,0,0,1,.977,3.153,2.8,2.8,0,0,1-.659.093Zm-3.11-4.447a2.811,2.811,0,
            0,1-2.786-2.556,4.976,4.976,0,0,0,2.525-1.667,24.07,24.07,
            0,0,1,2.164,3.452,2.78,2.78,0,0,1-1.928.771Zm-1.654-5.909a2.768,2.768,0,0,1-5.1-.547,4.889,4.889,0,
            0,0,2.488-2.04,30.862,30.862,0,0,1,2.587,2.587Zm-9.329-7.19a32.653,32.653,
            0,0,0-4.59,3.514,33.143,33.143,0,0,0-4.6-3.514l4.6-2.824ZM187.67,16.753a4.895,4.895,0,
            0,0,2.488,2.034,2.774,2.774,0,0,1-5.1.56,30.105,30.105,0,0,1,2.618-2.594Zm4.646,
            37.579c-1.916-1.113-9.093-5.735-11.948-13.683a4.845,4.845,0,0,0,3.377-1.866,4.851,
            4.851,0,0,0,7.265.51,25.152,25.152,0,0,0,6.406,11.258,30.991,30.991,0,0,1-5.094,
            3.782Zm13.254,0a31.5,31.5,0,0,1-5.1-3.788,25.184,25.184,0,0,0,6.419-11.251,4.851,
            4.851,0,0,0,7.265-.51,4.87,4.87,0,0,0,3.371,1.866c-2.861,7.936-10.014,12.564-11.948,13.683Z"
          transform="translate(-71.72 -6.87)"
          fill="#af8f6e"
        />
      </g>
    </SvgIcon>
  )
}

export default CandlefishLibraryLogo
