import React from "react"
import useStyles from "./styles"
import { Typography, Slider, Button } from "@material-ui/core"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { saveFormData } from "actions/forms"

const Form5 = ({ children, handleNewActiveForm }) => {
  const classes = useStyles()

  const initialValues = useSelector((state) => state.forms["form5"])
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      dispatch(saveFormData("completedFive", true))
      dispatch(saveFormData("form5", values))
      window.scrollTo(0, 0)
      handleNewActiveForm(6)
    },
  })

  const onPreviousClicked = () => {
    dispatch(saveFormData("completedFive", true))
    dispatch(saveFormData("form5", formik.values))
    window.scrollTo(0, 0)
    handleNewActiveForm(4)
  }

  const onSliderChange = (value, name) => {
    formik.setFieldValue(name, value)
  }

  const marks = [
    {
      value: 0,
      label: "Neutral",
    },
  ]
  const simpleMarks = [
    {
      value: -2,
      label: "Simple",
    },
    {
      value: 2,
      label: "Complex",
    },
  ]
  const sweetMarks = [
    {
      value: -2,
      label: "Dry",
    },
    {
      value: 2,
      label: "Sweet",
    },
  ]
  const feminineMarks = [
    {
      value: -2,
      label: "Feminine",
    },
    {
      value: 2,
      label: "Masculine",
    },
  ]

  // const handleCheckBoxChange = e => {
  //   const name = e.currentTarget.name
  //   const checked = e.currentTarget.checked
  //   formik.setFieldValue(name, checked)
  // }

  return (
    <div className={classes.formContainer}>
      <Typography variant="h1" className={classes.discoverHeading}>
        WOULD YOU ALSO LIKE A FRAGRANCE THAT IS...
      </Typography>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <div className={classes.sliderContainer}>
          {/* <Typography variant="h4">Simple</Typography> */}
          <Slider
            min={-2}
            max={2}
            aria-labelledby="discrete-slider-always"
            step={0.05}
            marks={simpleMarks}
            className={classes.slider}
            // classes={{
            //   markLabel: classes.markLabel
            // }}
            value={formik.values.simple}
            name="simple"
            id="simple"
            onChange={(e, value) => onSliderChange(value, "simple")}
          />
          {/* <Typography variant="h4">Complex</Typography> */}
        </div>
        <div className={classes.sliderContainer}>
          {/* <Typography variant="h4">Dry</Typography> */}
          <Slider
            min={-2}
            max={2}
            aria-labelledby="discrete-slider-always"
            step={0.05}
            marks={sweetMarks}
            className={classes.slider}
            value={formik.values.dry}
            name="dry"
            id="dry"
            onChange={(e, value) => onSliderChange(value, "dry")}
          />
          {/* <Typography variant="h4">Sweet</Typography> */}
        </div>
        <div className={classes.sliderContainer}>
          {/* <Typography variant="h4">Feminine</Typography> */}
          <Slider
            min={-2}
            max={2}
            aria-labelledby="discrete-slider-always"
            step={0.05}
            marks={feminineMarks}
            className={classes.slider}
            value={formik.values.feminine}
            name="feminine"
            id="feminine"
            onChange={(e, value) => onSliderChange(value, "feminine")}
          />
          {/* <Typography variant="h4">Masculine</Typography> */}
          {/* <FormControlLabel
            control={
              <Checkbox
                onChange={handleCheckBoxChange}
                checked={formik.values.spicy_not_sure}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
                name="spicy_not_sure"
                color="primary"
                className={classes.checkbox} />
            }
            label="Not Sure"
          />*/}
        </div>
        <div className={classes.btnContainer}>
          <Button
            variant="outlined"
            type="submit"
            onClick={onPreviousClicked}
            className={classes.formButton}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            type="submit"
            className={classes.formButton}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Form5
