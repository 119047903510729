import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 1,
  },
  button: {
    border: [`1px solid ${theme.palette.secondary.main}`, "!important"],
    borderRadius: 0,
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  quantityInputWrapper: {
    flexGrow: "1",
    display: "inline-block",
    maxWidth: 37,
    height: 37,
    verticalAlign: "middle",
  },
  quantityInput: {
    border: "none",
    padding: "0",
    fontSize: "18px",
    width: 37,
    textAlign: "center",
    outline: "none",
    backgroundColor: "transparent",
    height: 37,
    lineHeight: 37,
  },
}))

export default styles
