const styles = (theme) => ({
  buttonsRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttons: {
    display: "flex",
    maxWidth: 950,
    width: "100%",
    "@media (max-width: 735px)": {
      maxWidth: 315,
    },
  },
  itemBox: {
    width: "10%",
    paddingTop: "10%",
    "@media (max-width:735px)": {
      width: "20%",
      paddingTop: "20%",
    },
    position: "relative",
    "& > button": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
})

export default styles
