import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF4F2D6",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "center",
    color: "white",
    width: "100%",
    height: "100%",
    maxWidth: "500px",
    maxHeight: "400px",
    backgroundImage: `linear-gradient(
        rgba(255, 69, 0, .8),
        rgba(255, 69, 0, .8)),
        url(${process.env.REACT_APP_S3_ASSETS}/assets/library-popover-background.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    border: "none",
    boxShadow: "none",
    padding: "0px 60px 0px 60px",
    "@media (max-width: 500px)": {
      maxWidth: "90%",
    },
    "&:focus": {
      outline: "none",
    },
  },
  panelIcon: {
    width: "150px",
    margin: "50px 0px 20px 0px",
    "@media (max-width: 500px)": {
      margin: "20px 0px 10px 0px",
    },
  },
  acceptButton: {
    width: "50%",
    background: "black",
    color: "white",
    whiteSpace: "nowrap",
    margin: "0px auto 40px auto",
    "&:hover": {
      background: "black",
    },
    "@media (max-width: 500px)": {
      margin: "0px auto 20px auto",
    },
  },
}))

export default styles
