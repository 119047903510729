import React, { Fragment, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import useStyles from "./styles"
import PageHeader from "components/PageHeader"
import PageLogo from "components/PageLogo"
import FeaturedCandles from "components/FeaturedCandles"
import Footer from "components/Footer"
import { Box, Button } from "@material-ui/core"
import Filter from "components/Filter"
import LibraryCard from "components/LibraryCard"
import LongContent from "components/LibraryCard/LongContent"
import LibraryPopover from "components/LibraryPopover"
import Category from "components/Category"
import GiftSection from "components/GiftSection"
import { openDialog } from "actions/dialogs"
import {
  userCandles,
  setUserCandles,
  setUserCandleReaction,
} from "actions/users_candles"
import LibraryLegend from "components/LibraryLegend"
import PageWrapper from "components/PageWrapper"

const Library = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.authentication.authenticatedUser.id)
  const featuredCandles = useSelector((state) => state.candles.featuredCandles)
  // useEffect(() => {
  //   dispatch(userCandles(user))
  // }, [])

  const renderCandles =
    typeof featuredCandles.ids !== "undefined" && featuredCandles.ids.length > 0

  const [currentFamily, setCurrentFamily] = useState("")
  let libraryLegend = window.innerWidth > 500 ? <LibraryLegend /> : null
  return (
    <Fragment>
      <LibraryPopover />
      <PageHeader overText="LIBRARY" />
      <PageWrapper>
        <PageLogo />
        {renderCandles ? (
          <Box className={classes.featuredCandles}>
            <FeaturedCandles setCurrentFamily={setCurrentFamily} />
          </Box>
        ) : null}
        <Filter filter={currentFamily} setFilter={setCurrentFamily} />
        {libraryLegend}
        <LibraryCard currentFamily={currentFamily}>
          {({ selectedItem, setSelectedItem }) => (
            <LongContent
              selectedItem={selectedItem + 1}
              setSelectedItem={setSelectedItem}
              closeButton
            />
          )}
        </LibraryCard>
        {currentFamily ? <Category category={currentFamily} /> : null}
        <Button
          variant="contained"
          color="primary"
          onClick={() => dispatch(openDialog("saveProfile"))}
          className={classes.workshopBtn}
          classes={{ contained: classes.buttonOverride }}
        >
          ALREADY HAVE A LIBRARY CARD?
        </Button>
        <GiftSection />
        <Footer show />
      </PageWrapper>
    </Fragment>
  )
}

export default Library
