import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    padding: "15px 30px",
    "@media (max-width: 735px)": {
      padding: "0px 30px 15px 30px",
      flexDirection: "column",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    color: theme.palette.primary.main,
    "@media (max-width: 610px)": {
      margin: "0px 0px 16px 0px",
    },
  },
  checkBtnContainer: {
    width: "100%",
    "@media (max-width: 610px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  checkBtns: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    "@media (max-width: 768px)": {
      justifyContent: "center",
    },
  },
  checkBtn: {
    boxSizing: "border-box",
    width: "15%",
    minWidth: "100px",
    whiteSpace: "nowrap",
    margin: "6px",
    "@media (max-width: 610px)": {
      width: "40%",
      minWidth: "0px",
      border: "none",
      margin: "6px 6px 6px 6px",
      "&:hover": {
        backgroundColor: "white",
      },
    },
  },
  checked: {
    background: theme.palette.primary.main,
    color: "white",
    whiteSpace: "nowrap",
    "&:hover": {
      background: theme.palette.primary.main,
      color: "white",
    },
  },
  spacer: {
    flexGrow: 1,
  },
  contained: {
    "&:hover": {
      boxShadow: "none",
    },
  },
}))

export default styles
