import React, { useState } from "react"
import useStyles from "./styles"
import { Typography, Button } from "@material-ui/core"
import HomePanel from "components/HomePanel"
import HomeNavButton from "components/HomeNavButton"
import PageHeader from "components/PageHeader"
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed"
import { useSelector } from "react-redux"
import PageWrapper from "components/PageWrapper"

const Index = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const workshopIcon = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/workshop-icon-home.png`
  const backgroundDiscover = `${process.env.REACT_APP_S3_ASSETS}/assets/discover-home.jpg`
  const backgroundShop = `${process.env.REACT_APP_S3_ASSETS}/assets/shop-home.jpg`
  const backgroundLibrary = `${process.env.REACT_APP_S3_ASSETS}/assets/library-home.jpg`

  const [expanded, setExpanded] = useState(0)

  const links = useSelector((state) => state.links.links)

  const fragrances = useSelector((state) => state.candles.fragrances)

  let workshopText = (
    <Typography variant="h2">
      SCHEDULE <br /> A WORKSHOP
    </Typography>
  )
  if (window.innerWidth < 500) {
    workshopText = <h2>SCHEDULE A WORKSHOP</h2>
  }

  const setClose = () => {
    setExpanded(0)
  }

  const setOpen = (exp) => {
    if (window.innerWidth > 500) {
      setExpanded(exp)
    }
  }

  const scrollToRow = (el) => {
    const node = document.getElementById(el)
    smoothScrollIntoView(node)
  }

  const handleTouch = (exp, el) => {
    if (window.innerWidth < 500) {
      if (expanded === exp) {
        setClose()
      } else {
        setExpanded(exp)
        setTimeout(() => scrollToRow(el), 145)
      }
    }
  }

  return (
    <div className={classes.home}>
      <PageHeader hideSpacing />
      <PageWrapper className={classes.home}>
        <HomePanel
          label="SHOP"
          backgroundPath={backgroundShop}
          id="shop"
          handleTouch={() => handleTouch(1, "shop")}
          setOpen={() => setOpen(1)}
          setClose={() => setClose()}
          open={expanded === 1}
        >
          <div className={classes.homeNavOptions}>
            {links.map((link) => {
              if (link.type === "shop") {
                return (
                  <HomeNavButton
                    key={link?.link}
                    label={link?.name}
                    route={link?.link}
                    shop
                  />
                )
              } else {
                return null
              }
            })}
          </div>
        </HomePanel>
        <HomePanel
          label="DISCOVER"
          description="See what fragrance you are feeling today"
          id="discover"
          backgroundPath={backgroundDiscover}
          handleTouch={() => handleTouch(2, "discover")}
          setOpen={() => setOpen(2)}
          setClose={() => setClose()}
          open={expanded === 2}
        >
          <div className={classes.homeNavOptions}>
            <HomeNavButton label="DISCOVER NOW" route="/discover" />
          </div>
        </HomePanel>
        <HomePanel
          label={"BROWSE<br />LIBRARY"}
          description="Browse our 100 unique fragrances"
          id="library"
          backgroundPath={backgroundLibrary}
          handleTouch={() => handleTouch(3, "library")}
          setOpen={() => setOpen(3)}
          setClose={() => setClose()}
          open={expanded === 3}
        >
          <div className={classes.homeNavOptions}>
            <HomeNavButton label="LIBRARY CARD" route="/library" />
          </div>
        </HomePanel>
      </PageWrapper>
      {/* <Button
        variant="contained"
        color="primary"
        className={classes.workshopBtn}
        classes={{ contained: classes.buttonOverride }}>
        <img src={workshopIcon} alt="Workshop Icon" className={classes.workshopIcon}/>
        {workshopText}
      </Button> */}
    </div>
  )
}

export default Index
