import {
  AUTHENTICATION_SET_LOADING,
  AUTHENTICATION_SET_ERROR,
  AUTHENTICATION_SET_USER,
  LOGOUT,
  RESET_USER,
  AUTHENTICATION_SET_ONBOARDED,
  SET_ADMIN_LOGGED_IN,
  RESET_ADMIN_LOGGED_IN,
  SET_ADMIN_AUTH,
  RESET_ADMIN,
} from "types/actions/authentication"

export const authenticationReducerDefaultState = {
  authenticatedUser: {},
  loading: false,
  error: false,
  token: "",
  onBoarded: false,
  authenticatedAdmin: null,
}

export default (state = authenticationReducerDefaultState, action) => {
  switch (action.type) {
    case SET_ADMIN_AUTH:
      return {
        ...state,
        authenticatedAdmin: action.data,
      }
    case RESET_ADMIN_LOGGED_IN:
      return {
        ...state,
        authenticatedUser: action.data,
      }
    case RESET_USER:
      return {
        ...state,
        authenticatedUser: {},
        token: "",
      }
    case RESET_ADMIN:
      return {
        ...state,
        authenticatedAdmin: null,
      }
    case AUTHENTICATION_SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case AUTHENTICATION_SET_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case AUTHENTICATION_SET_USER:
      return {
        ...state,
        authenticatedUser: action.user,
        token: action.token,
      }
    case AUTHENTICATION_SET_ONBOARDED:
      return {
        ...state,
        onBoarded: action.onBoarded,
      }
    case LOGOUT:
      return {
        ...state,
        authenticatedUser: {},
        token: "",
        authenticatedAdmin: {},
      }
    default:
      return state
  }
}
