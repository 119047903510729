import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import useStyles from "./styles"
import { Box, Typography, Button, TextField } from "@material-ui/core"
import padNumber from "utils/padNumber"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import BlockIcon from "@material-ui/icons/Block"
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined"
import { addReaction, userCandles } from "actions/users_candles"
import classnames from "clsx"

const ShortContent = ({ selectedItem, getReaction, getNotes }) => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const user = useSelector((state) => state.authentication.authenticatedUser.id)

  const reactions = useSelector((state) => state.userCandles)

  const [notes, setNotes] = useState("")

  let liked = false
  let disliked = false
  let curious = false

  let placeholderText = "Type and notes here..."

  const result = Object.values(reactions.userReactions).filter(
    (x) => x.candle_id === selectedItem,
  )
  if (result.length === 1) {
    let reaction = result[0].reaction
    if (reaction === "love") {
      liked = true
    }
    if (reaction === "nope") {
      disliked = true
    }
    if (reaction === "curious") {
      curious = true
    }
  } else {
    placeholderText = "Select a reaction to enable notes"
  }

  useEffect(() => {
    if (result.length === 1 && result[0].comment) {
      setNotes(result[0].comment)
    } else {
      setNotes("")
    }
  }, [selectedItem])

  const submitNotes = (e) => {
    if (result.length === 1) {
      let query = {}
      query.user_id = user
      query.candle_id = selectedItem
      query.reaction = result[0].reaction
      query.comment = notes
      dispatch(addReaction(query)).then((res) => {
        dispatch(userCandles(user))
      })
    }
  }

  const setterNotes = (e) => {
    // set State
    setNotes(e.currentTarget.value)
    // send prop to parent
    getNotes(e.currentTarget.value)
  }
  const handleReaction = (reaction) => {
    // send prop to parent
    let query = {}
    query.user_id = user
    query.candle_id = selectedItem
    query.reaction = reaction
    dispatch(addReaction(query)).then((res) => {
      dispatch(userCandles(user))
    })
  }

  return (
    <Box className={classes.contentBox} display="flex">
      <Typography variant="h2" className={classes.candleNumber} color="primary">
        N<sup>o</sup>
        <br />
        {padNumber(selectedItem)}
      </Typography>
      <Box className={classes.buttons}>
        <Button
          color="primary"
          onClick={() => handleReaction("love")}
          className={classnames({
            [classes.liked]: liked,
          })}
          classes={{
            label: classes.buttonLabel,
          }}
        >
          <FavoriteBorderIcon
            className={classnames({
              [classes.fav]: liked,
            })}
          />
          Love
        </Button>
        <Button
          color="primary"
          onClick={() => handleReaction("nope")}
          className={classnames({
            [classes.disliked]: disliked,
          })}
          classes={{
            label: classes.buttonLabel,
          }}
        >
          <BlockIcon
            className={classnames({
              [classes.nope]: disliked,
            })}
          />
          Nope
        </Button>
        <Button
          color="primary"
          onClick={() => handleReaction("curious")}
          className={classnames({
            [classes.curious]: curious,
          })}
          classes={{
            label: classes.buttonLabel,
          }}
        >
          <VisibilityOutlinedIcon />
          Curious
        </Button>
      </Box>
      <TextField
        className={classes.notesBox}
        value={notes}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            // Do code here
            event.preventDefault()
          }
        }}
        disabled={result.length === 0}
        onChange={setterNotes}
        onBlur={() => submitNotes()}
        placeholder={placeholderText}
        InputProps={{
          classes: {
            notchedOutline: classes.notesBoxOutline,
          },
        }}
        variant="outlined"
        multiline
        rows={3}
        rowsMax={3}
      />
    </Box>
  )
}

export default ShortContent
