import React, { useEffect } from "react"
import { Box, CircularProgress } from "@material-ui/core"
import { magicLink } from "actions/authentication"
import { useParams, useHistory } from "react-router-dom"
import useStyles from "./styles"
import { useDispatch } from "react-redux"

const MagicLink = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const history = useHistory()

  const { shortCode } = useParams()
  useEffect(() => {
    dispatch(magicLink(shortCode)).then(() => {
      history.push("/library-card")
    })
  }, [])
  return (
    <Box className={classes.loadingScreen}>
      <CircularProgress />
    </Box>
  )
}

export default MagicLink
