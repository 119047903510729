const styles = (theme) => ({
  root: {
    height: "100%",
    width: "100%",
    background: "cover",
    "@media (max-width: 500px)": {
      height: "86%",
    },
  },
  footer: {
    bottom: "0",
  },
  centerImage: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",
  },
  column: {
    float: "left",
    width: "33.33%",
    padding: "10px",
  },
  row: {
    content: "",
    clear: "both",
    display: "table",
    maxWidth: "100%",
    marginTop: "25%",
    marginLeft: "auto",
    marginRight: "auto",
    height: "auto",
    alignContent: "center",
    "@media (max-width: 500px)": {
      marginTop: "40%",
    },
  },
  gridImg: {
    height: "auto",
    maxWidth: "100%",
    width: "100%",
  },
  centerGridImg: {
    height: "auto",
    maxWidth: "100%",
    width: "100%",
  },
  errorText: {
    fontFamily: "futura-pt, Roboto, 'sans-serif'",
    textAlign: "center",
    marginTop: "5%",
    color: "rgba(178, 145, 111, 1)",
    "@media (max-width: 500px)": {
      flexDirection: "row",
      height: "auto",
      marginTop: "10%",
      width: "auto",
    },
  },
})

export default styles
