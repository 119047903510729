import { makeStyles } from "@material-ui/core/styles"
import { fade } from "@material-ui/core/styles/colorManipulator"

const styles = makeStyles((theme) => ({
  contentBox: {
    backgroundColor: "#FCFAFA",
    padding: theme.spacing(2),
    alignItems: "center",
    maxWidth: 950,
    margin: "auto",
    "@media (max-width:735px)": {
      flexDirection: "column",
    },
  },
  candleNumber: {
    paddingLeft: theme.spacing(1),
    minWidth: 43,
    letterSpacing: "0.246rem",
    marginRight: theme.spacing(7),
    "& > sup": {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      letterSpacing: 0,
    },
    "@media (max-width:735px)": {
      paddingLeft: 0,
      marginRight: 0,
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(2),
      "& > br": {
        display: "none",
      },
      "& > sup": {
        marginRight: theme.spacing(),
      },
    },
  },
  buttons: {
    "@media (min-width:736px)": {
      paddingRight: 68,
    },
  },
  buttonLabel: {
    display: "flex",
    flexDirection: "column",
    "& > svg": {
      marginBottom: theme.spacing(),
    },
  },
  notesBox: {
    backgroundColor: "white",
    flexGrow: 1,
    "@media (max-width:735px)": {
      width: "100%",
    },
  },
  notesBoxOutline: {
    border: "none",
    borderRadius: 0,
    color: theme.palette.text.primary,
  },
  liked: {
    color: theme.palette.secondary.main,
    backgroundColor: fade(theme.palette.secondary.main, 0.5),
    "&:hover": {
      backgroundColor: fade(theme.palette.secondary.main, 0.5),
    },
  },
  fav: {
    color: theme.palette.secondary.main,
  },
  disliked: {
    color: "#D5D5D5",
    backgroundColor: fade("#D5D5D5", 0.5),
    "&:hover": {
      backgroundColor: fade("#D5D5D5", 0.5),
    },
  },
  nope: {
    color: "#D5D5D5",
  },
  curious: {
    backgroundColor: theme.palette.primary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))

export default styles
