import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  list: {
    position: "absolute",
    top: "140px",
    bottom: "135px",
  },
  tableContainer: {
    width: "95%",
  },
  table: {
    minWidth: "500px",
  },
  headCell: {
    color: theme.palette.secondary.main,
  },
  button: {
    color: "white",
    backgroundColor: "black",
    padding: "19px 36px",
    margin: "10px 0",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

export default styles
