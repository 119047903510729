import React from "react"
import useStyles from "./styles"
import { Typography } from "@material-ui/core"

const libraryLegend = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.legendContainer}>
      <div className={classes.sectionContainer}>
        <span className={classes.nopeBubble} />
        <Typography variant="h3" className={classes.description}>
          nope
        </Typography>
      </div>
      <div className={classes.sectionContainer}>
        <span className={classes.curiousBubble} />
        <Typography variant="h3" className={classes.description}>
          curious
        </Typography>
      </div>
      <div className={classes.sectionContainer}>
        <span className={classes.loveBubble} />
        <Typography variant="h3" className={classes.description}>
          love
        </Typography>
      </div>
    </div>
  )
}

export default libraryLegend
