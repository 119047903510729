import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  notes: {
    "@media (max-width: 735px)": {
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "auto",
      padding: "40px 23px",
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      marginTop: -theme.spacing(3),
      marginBottom: -theme.spacing(3),
    },
  },
  note: {
    marginBottom: theme.spacing(1),
    "&:last-of-type": {
      marginBottom: 0,
    },
    "& .MuiTypography-h3": {
      marginBottom: 0,
    },
    "@media (min-width: 736px)": {
      padding: "30px 0",
      borderBottom: `1px solid ${theme.palette.divider}`,
      "&$noteFirst": {
        paddingTop: 0,
      },
      "&$noteLast": {
        paddingBottom: 0,
        borderBottom: "none",
      },
    },
    "@media (max-width: 735px)": {
      flexDirection: "column",
      position: "relative",
      borderRadius: 3,
      boxShadow: "0px 11px 20px #00000012",
      padding: theme.spacing(2),
      flexShrink: 0,
      width: "80vw",
      marginRight: theme.spacing(2),
      marginBottom: 0,
      height: "100%",
      minHeight: "320px",
      "& img": {
        marginRight: 0,
      },
      "& .MuiTypography-h3": {
        fontSize: "1.0666rem",
        textTransform: "capitalize",
      },
    },
  },
  noteFirst: {},
  noteLast: {},
  tooltip: {
    textAlign: "left",
    padding: theme.spacing(3),
    maxWidth: 350,
  },
  noteTooltipTitle: {
    textDecoration: "underline",
    textTransform: "uppercase",
    fontSize: "0.7rem",
    fontFamily: "futura-pt-bold, Roboto, 'sans-serif'",
    marginBottom: theme.spacing(),
  },
  noteTooltipDescription: {
    marginBottom: theme.spacing(),
  },
  noteButton: {
    "@media (min-width: 736px)": {
      display: "none",
    },
  },
  noteLabel: {
    cursor: "pointer",
    margin: theme.spacing(1, 0),
    fontSize: "0.74rem",
    fontFamily: "futura-pt-bold, Roboto, 'sans-serif'",
    textTransform: "uppercase",
    textDecoration: "underline",
    color: theme.palette.secondary.main,
    "@media (max-width: 735px)": {
      display: "none",
    },
  },
  noteBack: {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    height: "100%",
    justifyContent: "center",
  },
  noteBackTitle: {
    marginBottom: [theme.spacing(1), "!important"],
    marginTop: theme.spacing(3),
    width: "100%",
  },
  noteCharacteristics: {
    width: "100%",
    marginTop: theme.spacing(),
  },
  spacer: {
    width: 0,
    height: 0,
  },
}))

export default styles
