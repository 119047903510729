import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setAdminData } from "actions/admins"
import { resetAdminLoggedIn } from "actions/authentication"
import { useHistory } from "react-router-dom"
// **** M-UI IMPORTS ****
import useStyles from "./styles"
import Grid from "@material-ui/core/Grid"
import SyncAltIcon from "@material-ui/icons/SyncAlt"
import Typography from "@material-ui/core/Typography"
import Modal from "@material-ui/core/Modal"
// **** M-UI IMPORTS ****
import { connect } from "react-redux"
import PinForm from "../../components/PinForm"
import SearchBar from "../../components/SearchBar"
import CandlefishLibraryLogo from "../../assets/CandlefishLibraryLogo"

const AdminHeader = ({ handleChangeSearchBar, isAdmin }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const open = useSelector(
    (state) => !state.authentication.authenticatedAdmin?.token,
  )

  let adminName =
    isAdmin && isAdmin.admin && isAdmin.admin.name ? isAdmin.admin.name : "USER"

  const currentStore = localStorage.getItem("store")
  // Redirect to kiosk if store isn't set
  if (currentStore === "") {
    history.push("/kiosk")
  }

  let storeName = "Atlanta, GA"
  let storePhone = "404-977-4247"

  if (currentStore !== "1111") {
    storeName = "Charleston, SC"
    storePhone = "843-773-4599"
  }

  const setChandlerData = (open) => {
    dispatch(resetAdminLoggedIn())
    dispatch(setAdminData("chandler", open))
    // localStorage.removeItem("store")
    localStorage.removeItem("employeePin")
    history.push("/kiosk/queue")
  }
  return (
    <Grid className={classes.header} container justify="center">
      <Grid
        item
        xs={4}
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <SyncAltIcon
          className={classes.icon}
          color="primary"
          fontSize="large"
          onClick={() => setChandlerData(true)}
        />
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          className={classes.typography}
          color="primary"
          variant="caption"
        >
          {adminName} | {storeName} | {storePhone}
        </Typography>
        <CandlefishLibraryLogo height="80px" width="160px" />
      </Grid>
      <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
        <SearchBar handleChangeSearchBar={handleChangeSearchBar} />
      </Grid>
      <Grid>
        <Modal
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onClose={() => setChandlerData(false)}
        >
          <Grid className={classes.paper} container direction="column">
            <PinForm page="/kiosk/queue" endpoint="employee" />
          </Grid>
        </Modal>
      </Grid>
    </Grid>
  )
}
const mapStateToProps = (state) => {
  return {
    isAdmin: state.authentication.authenticatedAdmin,
  }
}
export default connect(mapStateToProps)(AdminHeader)
