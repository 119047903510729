import { CANDLES_SET_DATA } from "types/actions/candles"

export const candlesInitialState = {
  fragrances: [1, 2, 3],
  allFragrances: [
    { candle_id: 1 },
    { candle_id: 2 },
    { candle_id: 3 },
    { candle_id: 4 },
    { candle_id: 5 },
    { candle_id: 6 },
    { candle_id: 7 },
    { candle_id: 8 },
    { candle_id: 9 },
    { candle_id: 10 },
  ],
  candles: [],
  families: [],
  featuredCandles: [],
  selectedCandleVariants: [],
  selectedCandle: -1,
  selectedFamily: "",
  cartCount: 0,
}

export default (state = candlesInitialState, action) => {
  switch (action.type) {
    case CANDLES_SET_DATA:
      return {
        ...state,
        [action.dataName]: action.data,
      }
    default:
      return state
  }
}

/*
const newState = Object.assign({}, state)
      newState[action.dataName] = action.data
      console.log({ state, newState, action })
      return newState
      */
