import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  header: {
    position: "relative",
    top: 0,
    height: "75px",
  },
  typography: {
    margin: "31px",
    whiteSpace: "nowrap",
  },
  icon: {
    margin: "31px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  paper: {
    position: "fixed",
    top: "50%",
    left: "50%",
    width: "100%",
    transform: "translate(-50%, -50%)", // helps with centering
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    "&:focus": {
      outline: "none",
    },
  },
}))

export default styles
