import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  drawer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "40px",
  },
  drawerPaper: {
    backgroundImage: `url(${process.env.REACT_APP_S3_ASSETS}/assets/side-nav-background.png)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "300px",
    justifyContent: "space-between",
    border: "none !important",
  },
  closeButton: {
    position: "absolute",
    top: 25,
    left: 25,
    color: "white",
  },
  options: {
    margin: "80px 0px 80px 30px",
    color: "white",
  },
  shopOptions: {
    marginTop: "10px",
    marginLeft: "20px",
    "& a": {
      cursor: "pointer",
    },
  },
  shopOption2: {
    fontSize: "1.6rem",
    display: "block",
    color: "#fff",
    "& a:hover": {
      textDecoration: "underline",
    },
    cursor: "pointer",
  },
  shopOption: {
    fontSize: "1.6rem",
    display: "block",
    color: "#fff",
    "& a:hover": {
      textDecoration: "underline",
    },
  },
  discoverOption: {
    margin: "20px 0px 20px 0px",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  loginButton: {
    width: "70%",
    background: "black",
    color: "white",
    whiteSpace: "nowrap",
    margin: "0px auto 40px auto",
    "&:hover": {
      background: "black",
    },
  },
  bottomNav: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  scheduleWorkshop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    marginBottom: "40px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  workshopIcon: {
    marginRight: "15px",
    width: "50px",
    height: "50px",
    "@media (max-width: 500px)": {
      marginRight: "10px",
      width: "30px",
      height: "30px",
    },
  },
  linkContainer: {
    margin: "20px 0",
    "& a": {
      cursor: "pointer",
    },
  },
}))

export default styles
