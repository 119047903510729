import React, { useState } from "react"
import useStyles from "./styles"
import { useSelector, useDispatch } from "react-redux"
import { Box, Typography, Button, Snackbar, Grow } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"
import { formatMoney } from "accounting"
import QuantityInput from "components/QuantityInput"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import { addToCart } from "actions/candles"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ShopItem = ({ item, closeDialog }) => {
  const classes = useStyles()

  if (item.status !== "ACTIVE" || item.onlineStoreUrl === null) {
    return null
  }

  const product = useSelector((state) => state.candles.selectedCandleVariants)

  const QuantitySchema = Yup.object().shape({
    quantity: Yup.number()
      .required("Quantity required")
      .min(1, "Quantity should be greater than 1"),
  })

  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)

  const handleSubmit = (values, index) => {
    const itemId = item.variants?.edges[index]?.node?.id
      .replace("gid://shopify/Product/", "")
      .replace("gid://shopify/ProductVariant/", "")

    dispatch(
      addToCart({
        items: {
          0: {
            quantity: values.quantity,
            id: itemId,
          },
        },
      })
    )
    setOpen(true)
    closeDialog()
  }

  const handleClose = () => {
    setOpen(false)
  }

  let image = item?.featuredImage?.src ? (
    <img
      src={resizedImage(item?.featuredImage?.src)}
      className={classes.shopItemImage}
      alt="Candle Image"
    />
  ) : (
    <img
      src={`${process.env.REACT_APP_S3_ASSETS}/assets/assets/9oz.png`}
      className={classes.shopItemImage}
      alt="Candle Image"
    />
  )

  function resizedImage(image) {
    const regex = new RegExp(/.jpg.*/gm)
    const imageExtention = image.match(regex)
    const imagePath = image.replace(regex, "")
    const newImagePath = imagePath + "_400x"
    return newImagePath + imageExtention
  }

  return (
    <>
      {item.variants?.edges?.map((variant, index) => (
        <Box key={variant.node.id} className={classes.shopItem}>
          <Box className={classes.imageBox}>{image}</Box>
          <Box className={classes.content}>
            <Typography color="secondary" className={classes.price}>
              {formatMoney(variant.node.price, "$")}
            </Typography>
            <Typography color="primary" className={classes.title}>
              {item.title}{" "}
              {variant.node.title !== "Default Title" && variant.node.title}
            </Typography>
            <Box className={classes.buttons}>
              <Formik
                initialValues={{ quantity: 1 }}
                validationSchema={QuantitySchema}
                onSubmit={(values) => handleSubmit(values, index)}
              >
                {(props) => (
                  <Form>
                    <QuantityInput min={1} name="quantity" />
                    <Button type="submit" className={classes.addButton}>
                      ADD
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            TransitionComponent={Grow}
          >
            <Alert onClose={handleClose} severity="success">
              Added to cart!
            </Alert>
          </Snackbar>
        </Box>
      ))}
    </>
  )
}

export default ShopItem
