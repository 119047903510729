export const AUTHENTICATION_SET_LOADING = "AUTHENTICATION::SET::LOADING"
export const AUTHENTICATION_SET_ERROR = "AUTHENTICATION::SET::ERROR"
export const AUTHENTICATION_SET_USER = "AUTHENTICATION::SET::USER"
export const AUTHENTICATION_SET_ONBOARDED = "AUTHENTICATION::SET::ONBOARDED"
export const AUTHENTICATION_UPDATE_USER = "AUTHENTICATION::UPDATE::USER"
export const LOGOUT = "AUTHENTICATION::LOGOUT"
export const RESET_USER = "RESET_USER::AUTH"
export const SET_ADMIN_LOGGED_IN = "SET::ADMIN_LOGGED::DATA"
export const RESET_ADMIN_LOGGED_IN = "RESET::ADMIN_LOGGED::DATA"
export const SET_ADMIN_AUTH = "SET::ADMIN::AUTH"
export const RESET_ADMIN = "RESET::ADMIN::AUTH"
