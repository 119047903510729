import React from "react"
import useStyles from "./styles"
import { useSelector } from "react-redux"
import { Box, Typography, useMediaQuery } from "@material-ui/core"
import CandleCard from "./CandleCard"
import StyledScrollbar from "components/StyledScrollbar"

const FeaturedCandles = ({ setCurrentFamily, children }) => {
  const classes = useStyles()

  const featuredCandles = useSelector((state) => state.candles.featuredCandles)
  console.log("featuredCandles", featuredCandles)
  const ids = featuredCandles.ids || []

  const ipadDown = useMediaQuery("(max-width:768px)")

  return (
    <Box className={classes.container}>
      <Box className={classes.leftContent}>
        <Typography
          variant="h1"
          color="primary"
          className={classes.featuredHeading}
        >
          Featured candles THIS month
        </Typography>
        <Typography color="secondary">
          9 unique fragrances curated by our master Chandlers in Charleston &
          Atlanta
        </Typography>
      </Box>
      <Box className={classes.rightContent}>
        <StyledScrollbar
          // helps with scroll area height
          style={{ height: ipadDown ? 254 : 382 }}
        >
          <Box className={classes.scroll}>
            {ids.map((candle) => {
              return (
                <CandleCard
                  key={candle}
                  number={candle}
                  setCurrentFamily={setCurrentFamily}
                />
              )
            })}
            <div className={classes.candleCardSpacer} />
          </Box>
        </StyledScrollbar>
      </Box>
    </Box>
  )
}

export default FeaturedCandles
