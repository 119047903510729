import React from "react"
import useStyles from "./styles"
import { useSelector } from "react-redux"
import { Box } from "@material-ui/core"

const PageWrapper = ({ children, ...props }) => {
  const classes = useStyles()
  const bannerHeight = useSelector((state) => state.settings.banner.height)

  return (
    <Box marginTop={`${bannerHeight}px`} height={"100%"} {...props}>
      {children}
    </Box>
  )
}

export default PageWrapper
