import React from "react"
import useStyles from "./styles"
import DiscoverForm from "components/DiscoverForm"

const Index = ({ routes = [], ...rest }) => {
  const classes = useStyles()

  const expandedIcon = `${process.env.REACT_APP_S3_ASSETS}/assets/panel-expanded-icon.png`

  return (
    <div className={classes.discoverContainer}>
      <div
        className={classes.candlerBackground}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_S3_ASSETS}/assets/discover-home.jpg)`,
        }}
      >
        <img
          src={expandedIcon}
          alt="Candlefish Icon"
          className={classes.candlefishIcon}
        />
      </div>
      <div className={classes.discoverForm}>
        <DiscoverForm />
      </div>
    </div>
  )
}

export default Index
