import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  drawer: {
    height: "100%",
    width: "100%",
  },
  drawerContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
    "@media (max-width: 568px)": {
      flexDirection: "column",
    },
  },
  drawerPaper: {
    height: "100%",
    maxHeight: "600px",
    overflow: "visible",
  },
  selections: {
    justifySelf: "flex-start",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
    height: "100%",
    maxHeight: "90%",
    maxWidth: "125px",
    margin: "20px 0px 20px 0px",
    "@media (max-width: 568px)": {
      flexDirection: "row",
      order: 1,
      maxWidth: "100%",
      height: "auto",
      flexShrink: 0,
    },
  },
  bubble: {
    width: "88px",
    height: "88px",
    fontSize: 13,
  },
  formsContainer: {
    justifySelf: "center",
    height: "100%",
    width: "100%",
    margin: "0px 20px 0px 0px",
    "@media (max-width: 568px)": {
      margin: "0px 0px 0px 0px",
      order: 2,
      height: "auto",
      overflow: "scroll",
    },
  },
  buttonsContainer: {
    justifySelf: "flex-end",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    height: "100%",
    width: "100%",
    maxWidth: "75px",
    borderLeft: "1px solid",
    borderLeftColor: theme.palette.secondary.main,
    "@media (max-width: 568px)": {
      order: 3,
      flexDirection: "row",
      maxWidth: "100%",
      justifyContent: "space-between",
      borderLeft: "none",
      alignItems: "center",
      height: "auto",
      flexShrink: 0,
    },
  },
  description: {
    color: theme.palette.secondary.main,
    opacity: ".3",
    textAlign: "left",
    marginLeft: "10px",
    fontSize: "1.2rem",
  },
}))

export default styles
