import { makeStyles } from "@material-ui/core/styles"
const background = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/saveProfileBackground2.png`

const styles = makeStyles((theme) => ({
  saveProfile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    maxHeight: "500px",
    width: "100%",
    maxWidth: "600px !important",
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "70%",
  },
  description: {
    textAlign: "center",
    marginTop: "40px",
    marginBottom: "20px",
    color: theme.palette.secondary.main,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  textField: {
    background: "white",
    width: "60%",
    border: "1px solid black",
    "@media (max-width: 500px)": {
      width: "75%",
    },
  },
  textRoot: {
    border: "none",
    "& > label": {
      paddingLeft: "5px",
    },
    "& $label + .MuiInput-formControl": {
      paddingLeft: "5px",
    },
  },
  submitButton: {
    width: "60%",
    paddingTop: "8px",
    paddingBottom: "8px",
    background: "black",
    color: "white",
    margin: "20px 0px 40px 0px",
    whiteSpace: "nowrap",
    "&:hover": {
      color: "black",
    },
    "@media (max-width: 500px)": {
      width: "75%",
    },
  },
  close: {
    position: "absolute",
    top: 0,
    right: 0,
    color: theme.palette.secondary.main,
  },
  candlefishLogo: {
    width: "200px",
  },
  closingText: {
    textAlign: "center",
  },
}))

export default styles
