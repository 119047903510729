import { USERS_SET_CUSTOMER_LIST } from "types/actions/users"

const usersState = {
  customerList: [],
}

export default (state = usersState, action) => {
  switch (action.type) {
    case USERS_SET_CUSTOMER_LIST:
      return {
        ...state,
        customerList: action.data,
      }
    default:
      return state
  }
}
