import React, { Fragment, useMemo, useState } from "react"
import useStyles from "./styles"
import Router from "router"
import PageHeader from "components/PageHeader"
import PageLogo from "components/PageLogo"
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Grow,
} from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"
import MaskedMobileInput from "components/MaskedMobileInput"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useParams, useHistory } from "react-router-dom"
import { addUser } from "actions/users"
import { useDispatch } from "react-redux"
import { userCandles } from "actions/users_candles"
import { login } from "actions/authentication"

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  phone: Yup.string()
    .max(14, "Please enter a valid cell phone number")
    .required("Required"),
})

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Register = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const { location } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")

  const locationName = useMemo(() => {
    let name = ""
    if (location === "charleston") {
      name = "Charleston, SC"
    } else if (location === "atlanta") {
      name = "Atlanta, GA"
    }
    return name
  }, [location])

  const handleClose = () => {
    setOpen(false)
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      location: locationName,
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true)
      dispatch(addUser(values))
        .then((res) => {
          history.push("/library-card")
        })
        .catch((error) => {
          console.log(error)
          if (error.body.error.includes("Duplicate entry")) {
            dispatch(login(values)).then((res) => {
              // login action is a promise sets auth and retrieves user data from api
              // res returns an id
              if (res) {
                setSnackbarMessage(
                  `Sending you to an existing account for ${values.phone}`
                )
                setOpen(true)
                let userId = res
                let query = {}
                query.phone = values.phone
                dispatch(userCandles(userId))
                setTimeout(() => {
                  setOpen(false)
                  setLoading(false)
                  history.push("/library-card")
                }, 1500)
              }
            })
          }
        })
    },
  })

  return (
    <Fragment>
      <PageHeader />
      <PageLogo />
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <Typography variant="h4" className={classes.title}>
          Please fill out the form below
        </Typography>
        <TextField
          name="name"
          type="text"
          value={formik.values.name}
          onChange={formik.handleChange}
          id="name"
          label="First Name"
          className={classes.textField}
          classes={{
            root: classes.textRoot,
          }}
        />
        <TextField
          name="phone"
          type="tel"
          value={formik.values.phone}
          onChange={formik.handleChange}
          id="phone"
          label="Mobile phone"
          className={classes.textField}
          classes={{
            root: classes.textRoot,
          }}
          InputProps={{
            inputComponent: MaskedMobileInput,
          }}
        />
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            // classoverrides={{ circularProgress: { root: classes.spinner } }}
            type="submit"
            // working={loading}
          >
            Submit
          </Button>
        </Box>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          TransitionComponent={Grow}
        >
          <Alert
            severity="information"
            classes={{ root: classes.snackbarRoot }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Typography className={classes.title} style={{ marginTop: 15 }}>
          We will never call, text or solicit this phone number.
        </Typography>
      </form>
      <Router routes={routes} {...rest} />
    </Fragment>
  )
}

export default Register
