import React from "react"
import useStyles from "./styles"
import Header from "components/Header"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { Box, Typography } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { resetUserLoggedIn } from "actions/authentication"
import { connect } from "react-redux"
const PageHeader = ({ overText, hideSpacing, kiosk, isAdmin }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const headerHeight = useSelector((state) => state.settings.banner.height)

  const history = useHistory()

  let header = <Header />
  if (hideSpacing) {
    header = <Header hideSpacing white />
  }

  const returnToKiosk = () => {
    dispatch(resetUserLoggedIn())
    history.push("/kiosk/queue")
  }

  // Header for admin on /library-card
  if (kiosk) {
    header = (
      <div className={classes.adminHeader}>
        <ArrowBackIcon
          className={classes.backArrow}
          onClick={() => returnToKiosk()}
        />
      </div>
    )
  }

  return (
    <>
      {header}
      {overText && (
        <Typography
          variant="h1"
          className={classes.overText}
          style={headerHeight && { top: `${96 + headerHeight}px` }}
        >
          {[...overText].map((letter, i) => (
            <span key={i} className={classes.letter}>
              {letter}
            </span>
          ))}
        </Typography>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.authentication.authenticatedAdmin,
  }
}
export default connect(mapStateToProps)(PageHeader)
