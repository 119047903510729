import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  note: {
    display: "flex",
    height: "100%",
    marginRight: theme.spacing(4.5),
    "&:last-of-type": {
      marginRight: 0,
    },
  },
  noteDescription: {
    display: "flex",
    flexDirection: "column",
    "& > img": {
      width: 90,
      height: 90,
      verticalAlign: "bottom",
    },
    textAlign: "center",
    whiteSpace: "nowrap",
  },
}))

export default styles
