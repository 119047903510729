import { QUICKEDIT_OPEN, QUICKEDIT_CLOSE } from "types/actions/quick-edit"

const quickEditInitialState = {
  open: false,
}

export default (state = quickEditInitialState, action) => {
  switch (action.type) {
    case QUICKEDIT_OPEN:
      return {
        ...state,
        open: action.open,
      }
    case QUICKEDIT_CLOSE:
      return {
        ...state,
        open: action.open,
      }
    default:
      return state
  }
}
