import store from "store"
import {
  getYourFragrances,
  saveCandleData,
  addUserSession,
} from "actions/candles"
// import { postSession } from "actions/sessions"

const getFragrances = async () => {
  const forms = store.getState().forms

  const user = store.getState().authentication.authenticatedUser.id

  let data = {
    rooms: [],
    multiple_values: {
      uniqueness: 0,
      strength: 0,
      complexity: 0,
      sweetness: 0,
    },
    single_values: [],
  }

  if (forms.form4.not_sure !== true) {
    let rooms = Object.keys(forms.form4).filter((x) => forms.form4[x] === true)
    data.rooms = rooms
  }

  data.multiple_values.uniqueness = forms.form2.uniqueness
  data.multiple_values.strength = forms.form3.strength
  data.multiple_values.complexity = forms.form5.simple
  data.multiple_values.sweetness = forms.form5.dry
  let deleteValues = Object.keys(data.multiple_values).filter(
    (x) => data.multiple_values[x] === 0,
  )
  deleteValues.forEach((x) => {
    delete data.multiple_values[x]
  })

  let singleValues = Object.keys(forms.form6).filter(
    (x) => forms.form6[x] === true,
  )
  data.single_values = singleValues

  if (forms.form5.feminine < 0) {
    data.single_values.push("feminine")
  }
  if (forms.form5.feminine > 0) {
    data.single_values.push("masculine")
  }

  await addUserSession(JSON.stringify(data), user)

  const givenFragrances = await getYourFragrances(data)

  // Post the session data
  // const sessionsResponse = await postSession(data)
  // console.log(sessionResponse)

  const sortedFragrances = [
    ...givenFragrances.map((x, i) => ({ ...x, i })),
  ].sort((a, b) => (Math.random() > 0.8 ? b.i - a.i : a.i - b.i))
  let topThree = []
  for (let i = 0; i < 3; i++) {
    topThree.push(sortedFragrances[i].candle_id)
  }
  store.dispatch(saveCandleData("allFragrances", sortedFragrances))
  store.dispatch(saveCandleData("fragrances", topThree))
}

export default getFragrances
