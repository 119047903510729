import React, { useState } from "react"
import useStyles from "./styles"
import IconButton from "@material-ui/core/IconButton"
import RemoveIcon from "@material-ui/icons/Remove"
import AddIcon from "@material-ui/icons/Add"
import { useFormikContext } from "formik"
import { FormHelperText } from "@material-ui/core"
import classnames from "clsx"
import { Fragment } from "react"

const QuantityInput = ({
  quantityDidChange,
  name,
  min,
  max,
  showMinus,
  onMinusClick,
  onPlusClick,
  className,
  classes: c,
}) => {
  const classes = {
    ...useStyles(),
    ...c,
  }

  const { values, errors, setFieldValue, handleChange } = useFormikContext()

  const beforeQuantityDidChange = (value) => {
    if (quantityDidChange !== undefined) {
      quantityDidChange(value)
    }
  }
  const handleQuantityInputChanged = (e) => {
    const value = e.target.value
    beforeQuantityDidChange(value)
  }
  const validateInput = (e) => {
    const re = /[0-9]+/g
    if (e.key && !re.test(e.key)) {
      e.preventDefault()
    }
  }

  const handleMinusClick = () => {
    setFieldValue(name, values[name] - 1)
    if (onMinusClick) {
      onMinusClick()
    }
  }

  const handlePlusClick = () => {
    setFieldValue(name, values[name] + 1)
    if (onPlusClick) {
      onPlusClick()
    }
  }

  return (
    <Fragment>
      <div className={classnames(classes.root, className)}>
        {(showMinus === undefined || showMinus === true) && (
          <IconButton
            type="button"
            className={classes.button}
            color="secondary"
            aria-label="Remove"
            onClick={handleMinusClick}
            disabled={values[name] <= min}
          >
            <RemoveIcon />
          </IconButton>
        )}
        <span className={classes.quantityInputWrapper}>
          <input
            name={name}
            type="number"
            value={values[name]}
            onChange={handleChange}
            className={classes.quantityInput}
          />
        </span>
        <IconButton
          type="button"
          className={classes.button}
          color="secondary"
          aria-label="Add"
          onClick={handlePlusClick}
          disabled={values[name] >= max}
        >
          <AddIcon />
        </IconButton>
      </div>
      {errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
    </Fragment>
  )
}

export default QuantityInput
