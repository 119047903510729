import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  discoverContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    "@media (max-width: 500px)": {
      flexDirection: "column",
      height: "auto",
    },
  },
  candlerBackground: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "75%",
    margin: "0px 30px 0px 30px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    "@media (max-width: 500px)": {
      order: 2,
      margin: "0px 0px 0px 0px",
      width: "100%",
      minHeight: "300px",
    },
  },
  candlefishIcon: {
    width: "100px",
    height: "100px",
  },
  discoverForm: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    margin: "0px 30px 0px 0px",
    "@media (max-width: 500px)": {
      minHeight: "525px",
      margin: "0px 0px 0px 0px",
    },
    overflow: "scroll",
  },
}))

export default styles
