import React from "react"
import useStyles from "./styles"
import classnames from "clsx"
import { Typography, Radio, FormControlLabel, Button } from "@material-ui/core"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { saveFormData } from "actions/forms"

const Form4 = ({ children, handleNewActiveForm }) => {
  const classes = useStyles()

  const initialValues = useSelector((state) => state.forms["form4"])

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      dispatch(saveFormData("completedFour", true))
      dispatch(saveFormData("form4", values))
      window.scrollTo(0, 0)
      handleNewActiveForm(5)
    },
  })

  const onPreviousClicked = () => {
    dispatch(saveFormData("completedFour", true))
    dispatch(saveFormData("form4", formik.values))
    window.scrollTo(0, 0)
    handleNewActiveForm(3)
  }

  let radio = (
    <div className={classes.noneSelected}>
      <FormControlLabel
        value="best"
        control={
          <div
            onClick={() => {
              formik.setFieldValue("not_sure", true)
              formik.setFieldValue("office", false)
              formik.setFieldValue("bathroom", false)
              formik.setFieldValue("living_room", false)
              formik.setFieldValue("bedroom", false)
              formik.setFieldValue("porch", false)
              formik.setFieldValue("kitchen", false)
            }}
          >
            <Radio color="primary" checked={false} />
          </div>
        }
        label="Not sure"
      />
    </div>
  )

  if (formik.values.not_sure) {
    radio = (
      <div className={classes.noneSelected}>
        <FormControlLabel
          value="best"
          control={
            <div onClick={() => formik.setFieldValue("not_sure", false)}>
              <Radio color="primary" checked />
            </div>
          }
          label="Not sure"
        />
      </div>
    )
  }

  return (
    <div className={classes.formContainer}>
      <Typography variant="h1" className={classes.discoverHeading}>
        WHAT ROOM WILL THIS BE USED IN?
      </Typography>
      <Typography variant="body1" className={classes.discoverDescription}>
        select all that apply
      </Typography>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <div className={classes.checkBtnContainer}>
          <div className={classes.checkBtns}>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.office === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("office", !formik.values.office)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Office</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.bathroom === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("bathroom", !formik.values.bathroom)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Bathroom</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.living_room === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("living_room", !formik.values.living_room)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Living Room</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.bedroom === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("bedroom", !formik.values.bedroom)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Bedroom</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.porch === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("porch", !formik.values.porch)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Porch</Typography>
            </Button>
            <Button
              variant="contained"
              className={classnames(classes.checkBtn, {
                [classes.checked]: formik.values.kitchen === true,
              })}
              classes={{
                contained: classes.contained,
              }}
              onClick={() => {
                formik.setFieldValue("kitchen", !formik.values.kitchen)
                if (formik.values.not_sure) {
                  formik.setFieldValue("not_sure", false)
                }
              }}
            >
              <Typography variant="h4">Kitchen</Typography>
            </Button>
          </div>
        </div>
        {radio}
        <div className={classes.btnContainer}>
          <Button
            variant="outlined"
            onClick={onPreviousClicked}
            className={classes.formButton}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            type="submit"
            className={classes.formButton}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Form4
