import React, { Fragment, useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom"
import { login } from "actions/authentication"
import { userCandles } from "actions/users_candles"
import { getCustomerList, sendTwilio, getUser } from "actions/users"
import { connect } from "react-redux"
import { setKioskData } from "actions/admins"
import { setAdminLoggedIn } from "actions/authentication"
import API from "store/api"
import useStyles from "./styles"
import Grid from "@material-ui/core/Grid"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Router from "router"
import KioskPubnub from "components/KioskPubnub/index"
import AdminHeader from "../../components/AdminHeader"
import AdminFooter from "../../components/AdminFooter"
import { useDispatch, useSelector } from "react-redux"
import MaskedMobileInput from "components/MaskedMobileInput"
import InfiniteScroll from "react-infinite-scroll-component"

/* eslint-disable */
const Queue = ({
  routes = [],
  storeLocation,
  customerList,
  isAdmin,
  ...rest
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()
  const [queue, setQueue] = React.useState([])
  const [inputNumber, setinputNumber] = React.useState([])
  // *** SEARCHBAR HANDLER & STATE ***
  const [query, setQuery] = React.useState("")

  const charlestonCustomers = useSelector((state) => state.admins.charleston)
  const atlantaCustomers = useSelector((state) => state.admins.atlanta)

  const handleChangeSearchBar = (event) => {
    let searchString = event.target.value
    setQuery((query) => searchString)
  }

  useEffect(() => {
    if (isAdmin) {
      API.set("Authorization", isAdmin.token)
    }
  }, [])

  useEffect(() => {}, [isAdmin])

  useEffect(() => {
    dispatch(getCustomerList(storeLocation))
  }, [charlestonCustomers, atlantaCustomers])

  useEffect(() => {
    let tempArr = []
    if (localStorage.getItem("store") === "0000") {
      charlestonCustomers.map((activeUser) => {
        customerList.forEach((x, i) => {
          if (x.id.toString() === activeUser.uuid) {
            x.active = 1
            let removed = customerList.splice(i, 1)
            tempArr.push(removed)
          }
        })
      })
    } else {
      atlantaCustomers.map((activeUser) => {
        customerList.forEach((x, i) => {
          if (x.id.toString() === activeUser.uuid) {
            x.active = 1
            let removed = customerList.splice(i, 1)
            tempArr.push(removed)
          }
        })
      })
    }
    tempArr.forEach((x) => {
      customerList.unshift(x[0])
    })
    setQueue(customerList)
  }, [customerList, charlestonCustomers, atlantaCustomers])

  const dynamicSearch = useMemo(() => {
    if (query) {
      if (isNaN(query)) {
        return queue.filter((customer) => {
          if (customer.name !== null) {
            return customer.name.toLowerCase().indexOf(query.toLowerCase()) > -1
          }
        })
      } else {
        return queue.filter((customer) => customer.phone.indexOf(query) > -1)
      }
    } else {
      return queue
    }
  }, [query, queue])

  const handleClick = (event) => {
    let data = {}
    data.phone = inputNumber
    dispatch(login(data)).then((res) => {
      dispatch(setKioskData(true))
      dispatch(userCandles(res))
      dispatch(getUser(res)).then((user) => {
        const storePhone =
          localStorage.getItem("store") === "0000"
            ? "+18437734599"
            : "+14049774247"
        const body = {
          From: user.phone,
          Body: "candle",
          To: storePhone,
        }
        dispatch(sendTwilio(body))
      })
      window.scrollTo(0, 0)
      history.push("/library-card")
    })
  }

  const libraryCard = (phone) => {
    let values = {
      phone: "",
    }
    values.phone = phone.slice(2, 12)
    dispatch(login(values)).then((res) => {
      dispatch(userCandles(res))
      dispatch(setKioskData(true))
      history.push("/library-card")
    })
    // dispatch(setAdminLoggedIn(authUser))
    // localStorage.setItem("adminLogged", JSON.stringify(authUser))
    // .then(res => {
    //   dispatch(userCandles(res))
    // })
    // login as user
  }
  const handleChange = (event) => {
    setinputNumber(event.target.value)
  }
  // map current list based on previously filtered, or base list.

  const QueueList = ({ queue }) => {
    const [moreCustomers, setMoreCustomers] = React.useState(true)
    const [displayedQueue, setDisplayedQueue] = React.useState([])
    const [currentPage, setCurrentPage] = React.useState(0)
    const [maxPages, setMaxPages] = React.useState(0)
    const [modifiedQueue, setModifiedQueue] = React.useState([])

    // for pagination
    const dataPerPage = 15

    // set displayed queue - mapped over to display
    // comes in as customerList or filtered in dynamicSearch fn
    // also max pages calc and setting inital current page
    useEffect(() => {
      if (queue) {
        setMaxPages(Math.floor(queue?.length / dataPerPage + 1))
        setDisplayedQueue(queue.slice(0, dataPerPage))
        setCurrentPage(1)
      }
    }, [queue])

    // handles load next "page" of data for infinite scrolling
    const showMoreCustomers = () => {
      if (queue && moreCustomers) {
        const newUsers = queue?.slice(
          currentPage * dataPerPage,
          dataPerPage * (currentPage + 1),
        )
        setDisplayedQueue((curr) => [...curr, ...newUsers])
        setCurrentPage((currentPage) => currentPage + 1)
      }
      if (currentPage === maxPages) {
        // on last page, so toggle to handle end el vs loading el
        setMoreCustomers(false)
      }
    }

    // if a search returns no results
    useEffect(() => {
      if (query && !queue.length) {
        setMoreCustomers(false)
      }
    }, [query, queue])

    return (
      <Grid
        className={classes.list}
        container
        justify="center"
        id="scrollable-container"
      >
        <InfiniteScroll
          next={() => showMoreCustomers()}
          hasMore={moreCustomers}
          dataLength={displayedQueue.length}
          scrollableTarget="scrollable-container"
          loader={<h4 align="center">Loading...</h4>}
          endMessage={<h4 align="center">End of results</h4>}
        >
          {displayedQueue.map((customer) => {
            return (
              <ListItem
                key={customer.phone}
                className={classes.listItem}
                button
                onClick={() => libraryCard(customer.phone)}
              >
                {customer.active === 1 ? (
                  <div className={classes.activeIndicator} />
                ) : null}
                <Typography variant="h3">{customer.name}</Typography>
                <ListItemText
                  className={classes.listItemTextTwo}
                  primary={`${
                    customer.phone.slice(2, 5) +
                    "-" +
                    customer.phone.slice(5, 8) +
                    "-" +
                    customer.phone.slice(8)
                  }`}
                />
              </ListItem>
            )
          })}
        </InfiniteScroll>
      </Grid>
    )
  }

  return (
    <Fragment>
      <AdminHeader handleChangeSearchBar={handleChangeSearchBar} />
      <Grid
        className={classes.queue}
        container
        direction="column"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid
          className={classes.contentContainer}
          container
          direction="column"
          alignItems="center"
          wrap="nowrap"
        >
          <QueueList queue={dynamicSearch} />
          <form className={classes.form}>
            <Box display="flex">
              <Box flexGrow={1} paddingRight={4} className={classes.input}>
                <TextField
                  fullWidth
                  className={classes.textField}
                  label="MOBILE NUMBER"
                  InputProps={{
                    inputComponent: MaskedMobileInput,
                  }}
                  onChange={handleChange}
                />
              </Box>
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  className={classes.button}
                  onClick={(event) => {
                    handleClick(event)
                  }}
                >
                  START
                </Button>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
      <KioskPubnub admin />
      <AdminFooter manageUserProp={isAdmin} />
      <Router routes={routes} {...rest} />
    </Fragment>
  )
}
const mapStateToProps = (state) => {
  return {
    storeLocation: state.admins.storeLocation,
    customerList: state.users.customerList,
    isAdmin: state.authentication.authenticatedAdmin,
  }
}
export default connect(mapStateToProps)(Queue)
