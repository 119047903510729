import React from "react"
import useStyles from "./styles"

const PageLogo = ({ children }) => {
  const Logo = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/cf-library-logo.svg`
  const classes = useStyles()

  return <img src={Logo} className={classes.logo} />
}

export default PageLogo
