import React, { useState, useEffect, useMemo, useRef } from "react"
import { Box, Grid } from "@material-ui/core"
import useStyles from "./styles"
import Router from "router"
import SimpleHeader from "components/SimpleHeader"
import MaterialTable from "material-table"
import API from "store/api"
import formatPhoneNumber from "utils/formatPhone"
import moment from "moment-timezone"

moment().tz("America/New_York").format()

// Single value types
const singleValuesTypes = [
  "spicy",
  "fresh",
  "cozy",
  "earthy",
  "relaxing",
  "vibrant",
  "juicy",
  "smoky",
  "herbal",
  "floral",
  "citrusy",
  "yummy",
  "festive",
  "masculine",
  "feminine",
]

const Analytics = ({ routes = [], ...rest }) => {
  const classes = useStyles()
  const [sessions, setSessions] = useState([])
  const [tableData, setTableData] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const tableRef = useRef(null)

  useEffect(() => {
    async function init() {
      setTableLoading(true)
      try {
        const sessions = await (await API.get("/sessions")).body
        setSessions(sessions.data)
        setTableLoading(false)
      } catch (error) {
        console.log(error)
        setTableLoading(false)
      }
    }
    init()
  }, [])

  // Create columns for table
  const singleValueColumns = useMemo(() => {
    return singleValuesTypes.map((value) => ({
      title: value.charAt(0).toUpperCase() + value.slice(1),
      field: value,
    }))
  })

  useEffect(() => {
    let data = []
    sessions.forEach((session) => {
      const {
        name = "",
        location = "",
        phone = "",
        created = "",
        reaction_updated: reactionUpdated = "",
        love = "",
        curious = "",
        nope = "",
      } = session
      const mobile = formatPhoneNumber(phone)
      const sessionData = JSON.parse(session.data)
      const {
        rooms,
        multiple_values: multipleValues,
        single_values: singleValues,
      } = sessionData

      // Format single value data for table
      const singleValuesData = {}
      singleValuesTypes.forEach((value) => {
        singleValuesData[value] = singleValues.includes(value) ? "True" : "-"
      })

      // Format candle reactions
      const loveCandles = love
        ? love
            .split(",")
            .map((num) => `No. ${num}`)
            .join(", ")
        : "-"
      const curiousCandles = curious
        ? curious
            .split(",")
            .map((num) => `No. ${num}`)
            .join(", ")
        : "-"
      const nopeCandles = nope
        ? nope
            .split(",")
            .map((num) => `No. ${num}`)
            .join(", ")
        : "-"

      data.push({
        mobile,
        created: moment(created).isValid()
          ? moment(created).format("MM-DD-YYYY")
          : "-",
        reactionUpdated: moment(reactionUpdated).isValid()
          ? moment(reactionUpdated).format("MM-DD-YYYY")
          : "-",
        name,
        location: location,
        uniqueness: multipleValues.uniqueness || 0,
        strength: multipleValues.strength || 0,
        complexity: multipleValues.complexity || 0,
        bathroom: rooms.includes("bathroom") ? "True" : "-",
        bedroom: rooms.includes("bedroom") ? "True" : "-",
        kitchen: rooms.includes("kitchen") ? "True" : "-",
        livingroom: rooms.includes("living_room") ? "True" : "-",
        office: rooms.includes("office") ? "True" : "-",
        porch: rooms.includes("porch") ? "True" : "-",
        ...singleValuesData,
        love: loveCandles,
        curious: curiousCandles,
        nope: nopeCandles,
      })
    })
    setTableData(data)
  }, [sessions])

  const getCurrentDate = () => {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, "0")
    const mm = String(today.getMonth() + 1).padStart(2, "0")
    const yyyy = today.getFullYear()

    return `${mm}-${dd}-${yyyy}`
  }

  const handlePageChange = () => {
    const table = tableRef.current.tableContainerDiv.current
    table.scrollTo(0, 0)
  }

  return (
    <>
      <SimpleHeader backUrl="/kiosk/queue" title="Analytics" />
      <Grid>
        <Box padding={3}>
          <MaterialTable
            tableRef={tableRef}
            title=""
            onChangePage={handlePageChange}
            columns={[
              {
                title: "Mobile",
                field: "mobile",
                cellStyle: { whiteSpace: "nowrap" },
              },
              {
                title: "Session Created",
                field: "created",
                cellStyle: { whiteSpace: "nowrap" },
              },
              {
                title: "Reaction Updated",
                field: "reactionUpdated",
                cellStyle: { whiteSpace: "nowrap" },
              },
              { title: "Name", field: "name" },
              {
                title: "Location",
                field: "location",
                cellStyle: { whiteSpace: "nowrap" },
              },
              { title: "Uniqueness", field: "uniqueness" },
              { title: "Strength", field: "strength" },
              { title: "Complexity", field: "complexity" },
              { title: "Bathroom", field: "bathroom" },
              { title: "Bedroom", field: "bedroom" },
              { title: "Kitchen", field: "kitchen" },
              { title: "Living room", field: "livingroom" },
              { title: "Office", field: "office" },
              { title: "Porch", field: "porch" },
              ...singleValueColumns,
              {
                title: "Love",
                field: "love",
                cellStyle: { whiteSpace: "nowrap" },
              },
              {
                title: "Curious",
                field: "curious",
                cellStyle: { whiteSpace: "nowrap" },
              },
              {
                title: "Nope",
                field: "nope",
                cellStyle: { whiteSpace: "nowrap" },
              },
            ]}
            data={tableData}
            isLoading={tableLoading}
            options={{
              headerStyle: {
                color: "#B2916F",
                borderBottom: "2px solid #B2916F",
              },
              tableLayout: "auto",
              grouping: true,
              pageSize: 20,
              pageSizeOptions: [10, 20, 30],
              maxBodyHeight: "66vh",
              exportFileName: `Candlefish_Analytics_${getCurrentDate()}`,
              exportAllData: true,
              exportButton: true,
            }}
          />
        </Box>
      </Grid>
      <Router routes={routes} {...rest} />
    </>
  )
}

export default Analytics
