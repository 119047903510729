import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  optionsContainer: {
    "@media (max-width: 568px)": {
      paddingTop: "25px",
      display: "flex",
      flexDirection: "row-reverse",
    },
  },
  updateContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    "&:hover": {
      cursor: "pointer",
    },
    "@media (max-width: 735px)": {
      borderRadius: "5px 5px 0px 0px",
      justifyContent: "center",
      alignItems: "center",
      padding: "0px 5px",
      margin: "0px 5px",
    },
  },
  updateButton: {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    borderRadius: 0,
    padding: "6px 12px 0px 12px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
    },
    "@media (max-width: 568px)": {
      order: 2,
      borderRadius: "5px",
      padding: "0px",
    },
  },
  updateIcon: {
    height: "40px",
    width: "40px",
    marginBottom: "-5px",
    "@media (max-width: 568px)": {
      marginBottom: "0px",
    },
  },
  updateText: {
    fontWeight: "900",
    fontSize: ".9rem",
    letterSpacing: "2.2px",
    textTransform: "uppercase",
    paddingBottom: "6px",
    "@media (max-width: 568px)": {
      marginBottom: "-10px",
      marginTop: "6px",
    },
  },
  iconButtonContainer: {
    display: "flex",
    flexDirection: "column",
    color: theme.palette.secondary.main,
    height: "100%",
    maxHeight: "50px",
    margin: "10px 5px",
    "&:hover": {
      cursor: "pointer",
    },
    "@media (max-width: 568px)": {
      margin: "0px 10px",
      height: "80%",
    },
  },
  iconButton: {
    color: theme.palette.secondary.main,
    margin: "5px 0px",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  icon: {
    height: "20px",
    width: "20px",
    marginBottom: "-5px",
    "@media (max-width: 568px)": {
      marginBottom: "0px",
    },
  },
  optionText: {
    fontWeight: "900",
    fontSize: ".9rem",
    letterSpacing: "2.2px",
    textTransform: "uppercase",
  },
}))

export default styles
