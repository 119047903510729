import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    padding: "15px 30px",
    "@media (max-width: 735px)": {
      padding: "0px 30px 15px 30px",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    color: theme.palette.primary.main,
    "@media (max-width: 610px)": {
      margin: "0px 0px 16px 0px",
    },
  },
  textField: {
    width: "60%",
  },
  checkBtnContainer: {
    width: "100%",
    "@media (max-width: 610px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  checkBtns: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  checkBtn: {
    minWidth: "100px",
    width: "33%",
    whiteSpace: "nowrap",
    "@media (max-width: 610px)": {
      width: "45%",
      border: "none",
      margin: "6px 6px 6px 6px",
      "&:hover": {
        backgroundColor: "white",
      },
    },
  },
  buttonOverride: {
    "&:hover": {
      boxShadow: "none",
    },
  },
  checked: {
    background: theme.palette.primary.main,
    color: "white",
    whiteSpace: "nowrap",
    "&:hover": {
      background: theme.palette.primary.main,
      color: "white",
    },
  },
  noneSelected: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
}))

export default styles
