import React from "react"
import useStyles from "./styles"
import { Typography } from "@material-ui/core"
import classnames from "clsx"

const HomePanel = ({
  children,
  label = "",
  description = "",
  id = "",
  backgroundPath = "",
  open,
  handleTouch,
  setOpen,
  setClose,
}) => {
  const classes = useStyles()

  const expandedIcon = `${process.env.REACT_APP_S3_ASSETS}/assets/panel-expanded-icon.png`

  let icon = (
    <img
      src={expandedIcon}
      alt="Panel Expanded Icon"
      className={classes.panelIcon}
    />
  )
  if (label === "SHOP") {
    icon = (
      <img
        src={expandedIcon}
        alt="Panel Expanded Icon"
        className={classes.shopPanelIcon}
      />
    )
    if (window.innerWidth < 500) {
      icon = null
    }
  }
  let shownLabel = (
    <Typography
      variant="h1"
      className={classes.panelLabel}
      dangerouslySetInnerHTML={{ __html: label }}
    />
  )

  return (
    <div
      className={classnames(classes.panel, {
        [classes.mobileOpen]: open && window.innerWidth < 500,
      })}
      id={id}
      onClick={handleTouch}
      onMouseOver={setOpen}
      onMouseLeave={setClose}
      style={{
        backgroundImage: `linear-gradient(
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.35)),
        url(${backgroundPath})`,
      }}
    >
      <div className={classes.panelContent} style={{ pointerEvents: "none" }}>
        {open && icon}
        {shownLabel}
        {open && (
          <Typography variant="h4" className={classes.description}>
            {description}
          </Typography>
        )}
        {open && children}
      </div>
    </div>
  )
}

export default HomePanel
