import React, { Component, Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import styles from "./styles"
import Typography from "@material-ui/core/Typography"
import NumFour from "assets/4-copy.png"
import { Container, Grid } from "@material-ui/core"
import Header from "components/Header"
import SearchBar from "components/SearchBar"
import Footer from "components/Footer"

class NotFound extends Component {
  render() {
    const CFLogo = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/Candlefish+logo_GOLD.png`
    const Background = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/notFoundBackground.png`

    const { classes } = this.props
    return (
      <Fragment>
        <div
          className={classes.root}
          style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Header />
          <SearchBar />
          <div>
            <div className={classes.row}>
              <div className={classes.column}>
                <img src={NumFour} className={classes.gridImg} alt="leftFour" />
              </div>
              <div className={classes.column}>
                <img
                  src={CFLogo}
                  className={classes.centerGridImg}
                  alt="centerLogo"
                />
              </div>
              <div className={classes.column}>
                <img
                  src={NumFour}
                  className={classes.gridImg}
                  alt="rightFour"
                />
              </div>
            </div>
            <Typography className={classes.errorText} variant="h2">
              Uh ohh. This page does not exist
            </Typography>
          </div>
        </div>
        <Footer className={classes.footer} />
      </Fragment>
    )
  }
}

export default withStyles(styles)(NotFound)
