import { SET_EMPLOYEE_DATA } from "types/actions/admins"
import { SET_ADMIN_DATA } from "types/actions/admins"
import { SET_STORE_LOCATION } from "types/actions/admins"
import { SET_SUPER_ADMIN } from "types/actions/admins"
import { SET_KIOSK_DATA } from "types/actions/admins"
import { SET_CHARLESTON_DATA } from "types/actions/admins"
import { SET_ATLANTA_DATA } from "types/actions/admins"
import { SET_ADMIN_AUTH } from "types/actions/admins"

export const adminsState = {
  employees: [],
  chandler: true,
  storeLocation: null,
  superAdmin: null,
  kiosk: false,
  charleston: [],
  atlanta: [],
}

export default (state = adminsState, action) => {
  switch (action.type) {
    case SET_EMPLOYEE_DATA:
      return {
        ...state,
        employees: action.data,
      }
    case SET_ADMIN_DATA:
      return {
        ...state,
        chandler: action.data,
      }
    case SET_STORE_LOCATION:
      return {
        ...state,
        storeLocation: action.data,
      }
    case SET_SUPER_ADMIN:
      return {
        ...state,
        superAdmin: action.data,
      }
    case SET_KIOSK_DATA:
      return {
        ...state,
        kiosk: action.data,
      }
    case SET_CHARLESTON_DATA:
      return {
        ...state,
        charleston: action.data,
      }
    case SET_ATLANTA_DATA:
      return {
        ...state,
        atlanta: action.data,
      }
    default:
      return state
  }
}
