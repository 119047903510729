import store from "store"

const preloadImages = () => {
  const { candles } = store.getState().candles
  var images = []
  candles.map((x) => {
    if (x.top_note) images.push(x.top_note.image)
    if (x.middle_note) images.push(x.middle_note.image)
    if (x.dry_note) images.push(x.dry_note.image)
  })
}

export default preloadImages
