import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  page: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  container: {
    position: "relative",
    zIndex: 1,
    display: "flex",
    margin: "75px auto 0",
    flexDirection: "column",
    "@media (max-width: 735px)": {
      flexDirection: "column",
      margin: "0 auto",
    },
  },
  textArea: {
    display: "flex",
    flexDirection: "column",
    flexBasis: 344,
    "@media (max-width: 735px)": {
      flexBasis: "initial",
    },
  },
  heading: {
    textTransform: "uppercase",
  },
  fragrancesArea: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  bubbles: {
    display: "flex",
    justifyContent: "space-around",
    "@media (max-width: 735px)": {
      marginTop: theme.spacing(4),
    },
  },
  indicatorArea: {
    display: "flex",
    margin: "43px 0",
    "@media (max-width: 735px)": {
      margin: theme.spacing(2, 0),
    },
  },
  spacer: {
    flexGrow: 1,
    transition: theme.transitions.create(["flex-grow"], {
      duration: theme.transitions.duration.short,
    }),
  },
  indicatorBox: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  boxRoot: {
    "@media (max-width: 735px)": {
      flexDirection: "column",
    },
  },
  workshopBtn: {
    display: "flex",
    position: "fixed",
    maxHeight: "75px",
    maxWidth: "300px",
    bottom: 0,
    right: "10%",
    margin: 0,
    textAlign: "left",
    zIndex: 100,
    "@media (max-width: 735px)": {
      top: 0,
      right: 0,
      borderRadius: "0px 0px 0px 5px",
    },
  },
  buttonOverride: {
    "@media (max-width: 735px)": {
      "&:before": {
        border: "none",
      },
    },
  },
  editButton: {
    position: "fixed",
    bottom: 0,
    right: "10%",
  },
  editButtonRoot: {
    background: theme.palette.secondary.main,
    color: "white",
    padding: "5px 20px 5px 20px",
    borderRadius: "5px 5px 0px 0px",
  },
}))

export default styles
