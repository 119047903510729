import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import useStyles from "./styles"
import { useFormik } from "formik"
import { saveFormData } from "actions/forms"
import { login } from "actions/authentication"
import { userCandles } from "actions/users_candles"
import { addUser } from "actions/users"
import {
  Dialog,
  Typography,
  DialogActions,
  Button,
  TextField,
  IconButton,
} from "@material-ui/core"
import { closeDialog } from "actions/dialogs"
import { closeSideNav } from "actions/side-nav"
import MaskedMobileInput from "components/MaskedMobileInput"
import ClearIcon from "@material-ui/icons/Clear"

const SaveProfileDialog = () => {
  const classes = useStyles()

  const saveProfileCandlefish = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/saveProfileCandlefish2.png`

  const open = useSelector((state) => state.dialogs.saveProfile?.open || false)

  const dispatch = useDispatch()

  const initialValues = useSelector((state) => state.forms["form1"])

  const history = useHistory()

  const handleClose = () => {
    dispatch(closeDialog("saveProfile"))
    dispatch(closeSideNav())
  }

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      dispatch(login(values))
        .then((res) => {
          if (res) {
            let userId = res
            let query = {}
            query.phone = values.phone
            dispatch(userCandles(userId))
            // query.userId = userId
            // dispatch(addUser(query))
            dispatch(closeDialog("saveProfile"))
            dispatch(closeSideNav())
            if (
              history.location.pathname !== "/library" ||
              history.location.pathname !== "/discover/your-fragrance"
            ) {
              window.scrollTo(0, 0)
              history.push("/library")
            }
          }
        })
        .catch((err) => {
          console.error(err)
        })
      dispatch(saveFormData("form1", values))
      handleClose()
    },
  })

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      classes={{
        paper: classes.saveProfile,
      }}
    >
      <div className={classes.contentContainer}>
        <img
          src={saveProfileCandlefish}
          alt="Candlefish Logo"
          className={classes.candlefishLogo}
        />
        <Typography variant="h4" className={classes.description}>
          Please enter your phone number so we can save your fragrance profile.
          No password needed!
        </Typography>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <TextField
            type="tel"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            id="standard-basic"
            label="Mobile phone"
            InputProps={{
              inputComponent: MaskedMobileInput,
            }}
            classes={{
              root: classes.textRoot,
            }}
            className={classes.textField}
          />
          <Button
            variant="outlined"
            type="submit"
            className={classes.submitButton}
          >
            Save Profile
          </Button>
        </form>
        <DialogActions>
          <IconButton onClick={handleClose} className={classes.close}>
            <ClearIcon />
          </IconButton>
        </DialogActions>
        <Typography variant="body1" className={classes.closingText}>
          We will never call, text or solicit this phone number
        </Typography>
      </div>
    </Dialog>
  )
}

export default SaveProfileDialog
