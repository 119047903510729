import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  loadingScreen: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
}))

export default styles
