import React from "react"
import useStyles from "./styles"
import Scrollbar from "react-scrollbars-custom"

const StyledScrollbar = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <Scrollbar
      className={classes.scrollBarElement}
      trackXProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props
          return (
            <span {...restProps} ref={elementRef} className={classes.track} />
          )
        },
      }}
      thumbXProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props
          return (
            <div {...restProps} ref={elementRef} className={classes.thumb} />
          )
        },
      }}
      {...props}
    >
      {children}
    </Scrollbar>
  )
}

export default StyledScrollbar
