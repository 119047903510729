import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getEmployeeList } from "../../actions/admins"
import { connect } from "react-redux"
// **** M-UI IMPORTS ****
import useStyles from "./styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import { Box } from "@material-ui/core"
// **** M-UI IMPORTS ****

const EmployeeList = ({ handleOpenModal, handleEmployee, list }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEmployeeList())
  }, [])
  useEffect(() => {}, [list])
  const handleClick = (id) => {
    let data = list.filter((ele) => {
      return ele.id === id
    })
    let action = "edit"
    handleOpenModal(data, action)
  }
  if (list && list.length > 0) {
    return (
      <Grid
        className={classes.list}
        container
        direction="column"
        alignItems="center"
        justify="space-between"
        wrap="nowrap"
      >
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.headCell}>Name</TableCell>
                <TableCell className={classes.headCell} align="right">
                  Admin
                </TableCell>
                <TableCell className={classes.headCell} align="right">
                  Location
                </TableCell>
                <TableCell className={classes.headCell} align="right">
                  Pin
                </TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((employee, key) => {
                return (
                  <TableRow className={classes.row} key={employee.pin}>
                    <TableCell component="th" scope="row">
                      {employee.name}
                    </TableCell>
                    <TableCell align="right">
                      {employee.super_admin ? "Yes" : "No"}
                    </TableCell>
                    <TableCell align="right">{employee.location}</TableCell>
                    {/* <TableCell align="right">
                      {employee.locations ? "ATL" : null}
                      {employee.locations[0].atlanta &&
                      employee.locations[0].charleston === true ? " , " : null}
                      {employee.locations[0].charleston ? "CHS" : null}
                    </TableCell> */}
                    <TableCell align="right">{employee.pin}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="outlined"
                        id={employee.id}
                        color="secondary"
                        onClick={() => handleClick(employee.id)}
                      >
                        {" "}
                        {/* depends on redux, or setState to work. */}
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Button className={classes.button} onClick={() => handleOpenModal()}>
          + ADD CHANDLER
        </Button>
      </Grid>
    )
  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100%"
    >
      <CircularProgress size="6rem" />
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    list: state.admins.employees,
  }
}
export default connect(mapStateToProps)(EmployeeList)
