import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  formContainer: {
    marginTop: "40px",
    width: "100%",
    position: "relative",
  },
  discoverHeading: {
    margin: "0px 0px 20px 20px",
    color: theme.palette.primary.main,
    "@media (max-width: 500px)": {
      margin: "0px 0px 0px 20px",
    },
  },
  discoverDescription: {
    margin: "0px 0px 0px 20px",
    color: theme.palette.primary.main,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    color: theme.palette.primary.main,
    margin: "20px 0px 16px 0px",
    "@media (max-width: 500px)": {
      margin: "0px 0px 16px 0px",
    },
  },
  textField: {
    width: "60%",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingTop: "20px",
  },
  formButton: {
    width: "40%",
    maxWidth: "150px",
    background: "black",
    color: "white",
    margin: "14px 0px 0px 10px",
    "&:hover": {
      color: "black",
    },
    "@media (max-width: 500px)": {
      "&:hover": {
        background: "black",
        color: "white",
      },
    },
  },
  checkBtnContainer: {
    width: "100%",
    "@media (max-width: 500px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  checkBtns: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    "@media (max-width: 768px)": {
      justifyContent: "center",
    },
  },
  checkBtn: {
    boxSizing: "border-box",
    width: "23%",
    whiteSpace: "nowrap",
    margin: "1%",
    "@media (max-width: 500px)": {
      width: "30%",
      border: "none",
      margin: "1%",
      "&:hover": {
        backgroundColor: "white",
      },
    },
  },
  checked: {
    background: theme.palette.primary.main,
    color: "white",
    whiteSpace: "nowrap",
    "&:hover": {
      background: theme.palette.primary.main,
      color: "white",
    },
  },
  noneSelected: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  contained: {
    "&:hover": {
      boxShadow: "none",
    },
  },
}))

export default styles
