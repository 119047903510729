import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  category: {
    maxWidth: 1044,
    margin: "0 auto",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    padding: theme.spacing(0, 3),
  },
  modalCategory: {
    marginBottom: "10px",
  },
  h2: {
    letterSpacing: "1.9px",
    textTransform: "uppercase",
    marginBottom: theme.spacing(1.2),
    fontSize: "1.333rem",
  },
  paragraph: {
    marginBottom: theme.spacing(3.5),
  },
  categoryTypography: {
    color: "white",
    letterSpacing: "3.57px",
  },
  card: {
    boxShadow: "0px 20px 36px #0000001A",
    position: "relative",
    "& > img": {
      verticalAlign: "middle",
      "@media (max-width: 1024px)": {
        maxWidth: 300,
      },
    },
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  cardText: {
    textTransform: "uppercase",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: "44%",
    left: 0,
    padding: theme.spacing(5.8),
    "@media (max-width: 1024px)": {
      padding: theme.spacing(3.25),
    },
  },
  cardTitle: {
    marginBottom: theme.spacing(1),
  },
  contentArea: {
    paddingLeft: theme.spacing(9.25),
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (max-width: 1024px)": {
      paddingLeft: theme.spacing(4.625),
    },
    "@media (max-width: 768px)": {
      paddingLeft: 0,
      maxWidth: 620,
      margin: "0 auto",
    },
  },
  modalContentArea: {
    paddingLeft: "24px",
    "@media (max-width: 768px)": {
      paddingLeft: "0px",
    },
  },
}))

export default styles
