import React from "react"
import useStyles from "./styles"
import { Typography, TextField, Button } from "@material-ui/core"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import { saveFormData } from "actions/forms"
import MaskedMobileInput from "components/MaskedMobileInput"
import { login } from "actions/authentication"
import { userCandles } from "actions/users_candles"

const Form1 = ({ children, handleNewActiveForm }) => {
  const classes = useStyles()

  const initialValues = useSelector((state) => state.forms["form1"])

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (values.phone !== "") {
        dispatch(login(values)).then((res) => {
          if (res) {
            dispatch(userCandles(res))
          }
        })
        dispatch(saveFormData("completedOne", true))
        dispatch(saveFormData("form1", values))
      }
      window.scrollTo(0, 0)
      handleNewActiveForm(2)
    },
  })

  return (
    <div className={classes.formContainer}>
      <Typography variant="h1" className={classes.discoverHeading}>
        DISCOVER YOUR UNIQUE CANDLE FRAGRANCE
      </Typography>
      <Typography variant="h4" className={classes.discoverDescription}>
        Fragrance is personal and changes over time. See what unique candle you
        are feeling today.
      </Typography>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <TextField
          name="phone"
          type="tel"
          value={formik.values.phone}
          onChange={formik.handleChange}
          id="standard-basic"
          label="PLEASE ENTER YOUR MOBILE"
          InputProps={{
            inputComponent: MaskedMobileInput,
          }}
          className={classes.textField}
          classes={{
            root: classes.textRoot,
          }}
        />
        <Button
          variant="outlined"
          type="submit"
          className={classes.formOneButton}
        >
          Lets get started!
        </Button>
      </form>
      <Typography variant="body1" className={classes.closingText}>
        We will never call, text or solicit this phone number
      </Typography>
    </div>
  )
}

export default Form1
