import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(),
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 320,
    width: 212,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginRight: theme.spacing(3),
    boxShadow: "none",
    color: "white",
    textAlign: "center",
    "&:hover": {
      boxShadow: "0px 20px 13px rgba(0,0,0,0.15)",
      cursor: "pointer",
    },
    "@media (max-width: 768px)": {
      height: 192,
      width: 128,
    },
  },
  noText: {
    fontSize: "1.9rem",
    "& > sup": {
      borderBottom: "1px solid white",
      letterSpacing: 0,
      height: "1.03em",
      display: "inline-block",
    },
    "@media (max-width: 768px)": {
      fontSize: "1rem",
      "& > sup": {
        height: "1.13em",
      },
    },
  },
  candleNumber: {
    minWidth: 43,
    letterSpacing: "0.546rem",
    fontSize: "6.3rem",
    paddingLeft: theme.spacing(),
    "@media (max-width: 768px)": {
      fontSize: "3.7rem",
      letterSpacing: "0.246rem",
    },
  },
}))

export default styles
