import React, { useEffect } from "react"
import { connect } from "react-redux"
// **** M-UI IMPORTS ****
import Grid from "@material-ui/core/Grid"
import Fab from "@material-ui/core/Fab"
import { makeStyles } from "@material-ui/core/styles"
import LibraryLegend from "components/LibraryLegend"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
// **** M-UI IMPORTS ****

const useStyles = makeStyles((theme) => ({
  titleBar: {
    padding: "20px 0",
  },
  n: {
    letterSpacing: "3px",
  },
  o: {
    textDecoration: "underline",
    lineHeight: ".6",
  },
  notesContainer: {
    overflowY: "scroll",
    maxHeight: "300px",
  },
  notesTitle: {
    letterSpacing: "3px",
  },
  noteText: {
    textAlign: "left",
    overflow: "hidden",
    wordWrap: "break-word",
    padding: "20px 0",
  },
  noNoteText: {
    textAlign: "left",
    padding: "20px 0",
    opacity: "0.5",
  },
  fabContainer: {
    padding: "20px 8px 20px 0px",
  },
  likedFab: {
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    color: "white",
  },
  dislikedFab: {
    backgroundColor: "#D5D5D5",
    borderColor: "#D5D5D5",
    color: "white",
  },
  curiousFab: {
    color: theme.palette.primary.main,
    borderColor: "#FAE4DF",
    backgroundColor: "#FAE4DF",
  },
  button: {
    marginTop: "50px",
    color: "white",
    minHeight: "50px",
    width: "60%",
    backgroundColor: "black",
    padding: "8px 8px",
    margin: "5px 0",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

const Notes = ({ handleCloseModal, usersCandles }) => {
  const classes = useStyles()
  const [notes, setNotes] = React.useState([])
  // initialize list, eventually from server data. timeout to mimic delay
  useEffect(() => {
    if (!usersCandles) {
      // setTimeout(() => setNotes(notes => fakeData.libraryCard), 1000)
    }
    // setTimeout(() => {
    //   setNotes(useSelector(state => state.usersCandles.notes))
    // }, 1000)
    // console.log("notes", notes)
  }, [])

  useEffect(() => {
    setNotes(usersCandles.notes)
  }, [usersCandles])

  // build list of notes, that should be attached to a user.

  const NoteList = () => {
    if (notes && notes.length > 0) {
      return (
        <Grid className={classes.notesContainer} container justify="center">
          {notes.map((note) => {
            // component to handle each status of candle
            const BuiltFab = () => {
              switch (note.reaction) {
                case "love":
                  return (
                    <Fab className={classes.likedFab}>{note.candle_id}</Fab>
                  )
                case "nope":
                  return (
                    <Fab className={classes.dislikedFab}>{note.candle_id}</Fab>
                  )
                case "curious":
                  return (
                    <Fab className={classes.curiousFab} color="primary">
                      {note.candle_id}
                    </Fab>
                  )
                default:
                  return null
              }
            }

            // main return block that builds each note
            return (
              <Grid container justify="center">
                <Grid item xs={3}>
                  <Grid
                    className={classes.fabContainer}
                    container
                    justify="center"
                  >
                    <BuiltFab />
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <div
                    style={{
                      width: "1px",
                      height: "100%",
                      backgroundColor: "black",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {note.comment ? (
                    <Typography className={classes.noteText} align="center">
                      {note.comment}
                    </Typography>
                  ) : (
                    <Typography className={classes.noNoteText} align="center">
                      Left no note
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      )
    }
    // until an actual list of candle notes exist. return loading wheel
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    )
  }
  // main render
  return (
    <Grid container justify="center" style={{ overflow: "hidden" }}>
      <LibraryLegend />
      <Grid className={classes.titleBar} container justify="center">
        <Grid item xs={3}>
          <Grid container justify="center">
            <Typography className={classes.n} color="primary" variant="h2">
              N
            </Typography>
            <Typography className={classes.o} color="primary" variant="h2">
              o
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={8}>
          <Typography
            className={classes.notesTitle}
            color="primary"
            variant="h2"
            align="center"
          >
            NOTES
          </Typography>
        </Grid>
      </Grid>
      <NoteList />
      <Grid container justify="center">
        <Button
          className={classes.button}
          onClick={() => {
            // portal back modal status on click
            handleCloseModal()
          }}
        >
          BACK TO LIBRARY
        </Button>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    usersCandles: state.userCandles,
  }
}
export default connect(mapStateToProps)(Notes)
