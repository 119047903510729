import React, { Fragment } from "react"
import useStyles from "./styles"
import Router from "router"
import YourFragrances from "components/YourFragrances"

const YourFragrance = ({ routes = [], ...rest }) => {
  // const classes = useStyles()

  return (
    <Fragment>
      <YourFragrances />
      <Router routes={routes} {...rest} />
    </Fragment>
  )
}

export default YourFragrance
