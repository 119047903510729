import React, { Fragment } from "react"
import bcrypt from "bcryptjs"

// **** M-UI IMPORTS ****
import useStyles from "./styles"
import Grid from "@material-ui/core/Grid"
// **** M-UI IMPORTS ****

import Router from "router"

import PinForm from "components/PinForm"

const Kiosk = ({ routes = [], ...rest }) => {
  // M-UI styles hook
  const classes = useStyles()

  const FooterLogo = `${process.env.REACT_APP_S3_ASSETS}/assets/assets/cf-logo-footer.svg`

  return (
    <Fragment>
      <Grid
        className={classes.kiosk}
        container
        direction="column"
        alignItems="center"
        justify="space-between"
      >
        <img
          className={classes.logo2}
          src={`${process.env.REACT_APP_S3_ASSETS}/assets/assets/candlefish-logo-gold.png`}
          alt="footer logo"
        />
        <div className={classes.pinFormContainer}>
          <PinForm page="/kiosk/queue" endpoint="store" />
        </div>
        <img className={classes.logo} src={FooterLogo} alt="footer logo" />
      </Grid>
      <Router routes={routes} {...rest} />
    </Fragment>
  )
}

export default Kiosk
