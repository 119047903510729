import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  button: {
    color: "white",
    backgroundColor: "black",
    padding: "19px 60px",
    margin: "5px 0",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  paper: {
    position: "fixed",
    top: "50%",
    left: "50%",
    width: "380px",
    height: "465px",
    transform: "translate(-50%, -50%)", // helps with centering
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: "30px 32px",
    "&:focus": {
      outline: "none",
    },
  },
  manageButtons: {
    display: "flex",
    flexDirection: "column",
  },
  deleteButton: {
    color: theme.palette.primary,
  },
  deleteContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.main,
  },
}))

export default styles
