import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import PubNub from "pubnub"
import { useSelector, useDispatch } from "react-redux"
import { setCharlestonData } from "actions/admins"
import { setAtlantaData } from "actions/admins"
import { getCustomerList } from "actions/users"

const KioskPubnub = ({ admin }) => {
  const user = useSelector((state) => state.authentication.authenticatedUser)
  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    if (admin) {
      let channel =
        localStorage.getItem("store") === "0000" ? "kiosk0" : "kiosk1"
      const pubnub = new PubNub({
        subscribeKey: process.env.REACT_APP_PUBNUB_SUB_KEY,
        uuid: channel,
      })
      pubnub.subscribe({
        channels: [channel],
        withPresence: true,
      })
      pubnub.hereNow(
        {
          channels: [channel],
        },
        function (status, response) {
          const { channels } = response
          if (channels) {
            if (channels.kiosk0) {
              dispatch(setCharlestonData(channels.kiosk0.occupants))
            }
            if (channels.kiosk1) {
              dispatch(setAtlantaData(channels.kiosk1.occupants))
            }
          }
        },
      )
      pubnub.addListener({
        presence: function () {
          pubnub.hereNow(
            {
              channels: [channel],
            },
            function (status, response) {
              const { channels } = response
              if (channels) {
                if (channels.kiosk0) {
                  dispatch(setCharlestonData(channels.kiosk0.occupants))
                }
                if (channels.kiosk1) {
                  dispatch(setAtlantaData(channels.kiosk1.occupants))
                }
              }
            },
          )
        },
      })
      pubnub.addListener({
        message: (msg) => {
          try {
            const { message } = msg
            const { action } = message
            if (action === "update") {
              dispatch(getCustomerList())
            }
          } catch (err) {
            console.log(err)
          }
        },
      })
    } else {
      if (!user.id) {
        history.push("/")
      } else {
        const channel = user.location === "Charleston, SC" ? "kiosk0" : "kiosk1"
        const pubnub = new PubNub({
          subscribeKey: process.env.REACT_APP_PUBNUB_SUB_KEY,
          uuid: user.id.toString(),
        })
        pubnub.unsubscribeAll()
        pubnub.subscribe({
          channels: [channel],
          withPresence: true,
        })
      }
    }
  }, [])

  return null
}

export default KioskPubnub
