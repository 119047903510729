import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  formContainer: {
    marginTop: "40px",
    width: "100%",
    position: "relative",
  },
  discoverHeading: {
    margin: "0px 0px 20px 20px",
    color: theme.palette.primary.main,
  },
  labelDescriptionLeft: {
    margin: "0px 0px 0px 0px",
    color: theme.palette.primary.main,
  },
  labelDescriptionRight: {
    margin: "0px 0px 0px 0px",
    color: theme.palette.primary.main,
    textAlign: "right",
  },
  discoverDescription: {
    margin: "0px 0px 0px 20px",
    color: theme.palette.secondary.main,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    color: theme.palette.primary.main,
    margin: "40px 0px 16px 0px",
    justifyContent: "center",
  },
  textField: {
    width: "60%",
  },
  labelDescriptions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "30px",
    width: "67%",
    "@media (max-width: 500px)": {
      width: "86%",
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingTop: "20px",
  },
  formButton: {
    width: "40%",
    maxWidth: "150px",
    background: "black",
    color: "white",
    margin: "14px 0px 0px 10px",
    "&:hover": {
      color: "black",
    },
    "@media (max-width: 500px)": {
      "&:hover": {
        background: "black",
        color: "white",
      },
    },
  },
  slider: {
    marginTop: "40px",
    width: "60%",
    "@media (max-width: 500px)": {
      marginTop: "0px",
      width: "80%",
    },
  },
}))

export default styles
