import { makeStyles } from "@material-ui/core/styles"

const styles = makeStyles((theme) => ({
  footer: {
    minHeight: 100,
    backgroundColor: "#FCFAFA",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

export default styles
